import { Fragment, useEffect, useState } from "react";
import cn from "classnames";
import { Menu, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import Avatar from "../../common/avatar";
import { siteSettings } from "../../../settings/site.settings";
import { Link } from "react-router-dom";
import { Routes } from "../../../config/routes";
import SelectInput from "../../ui/select-input";
import { Control, FieldErrors, useForm } from "react-hook-form";
import { useBusinessDetailsList } from "../../../service/hook/business";
import { userStore } from "../../../store";
import { BusinessItem, BusinessListResponse } from "../../../types";

type FormValues = {
    businessId: number;
};

const defaultValues = {
    businessId: 0,
};

function BusinessList({ control, errors }: { control: Control<FormValues>; errors: FieldErrors }) {
    const { t } = useTranslation();

    const {
        user,
        businessItem,
        latestBusinessIdSelected,
        setLatestBusinessIdSelected,
        setBusinessItem,
    } = userStore((state) => state);

    const [businessDetailList, setBusinessDetailList] = useState<BusinessItem[]>([]);

    const {
        mutate: businessDetailListMutate,
        isLoading,
        serverError,
        setServerError,
    } = useBusinessDetailsList();

    useEffect(() => {
        if (businessDetailList.length === 0)
            businessDetailListMutate(
                { email: user?.email ?? "" },
                {
                    onSuccess: (successData: BusinessListResponse) => {
                        if (successData.final_result_code === "000") {
                            setBusinessDetailList(successData.captured_data);
                            if (successData.captured_data.length > 0) {
                                if (latestBusinessIdSelected !== null) {
                                    const findItem = successData.captured_data.find(
                                        (item) => item.business_id === latestBusinessIdSelected
                                    );

                                    if (findItem) {
                                        setBusinessItem(findItem);
                                    }
                                } else {
                                    setBusinessItem(successData.captured_data[0]);
                                }
                            }
                        }
                    },
                }
            );
    }, []);

    return (
        <div className="">
            <SelectInput
                name="businessType"
                placeholder={t("form:placeholder-select-business")}
                control={control}
                value={businessItem}
                onChange={(value: BusinessItem) => {
                    setBusinessItem(value);
                    setLatestBusinessIdSelected(value.business_id);
                }}
                getOptionLabel={(option: any) => option.business_name}
                getOptionValue={(option: any) => option.business_id}
                options={businessDetailList!}
                isLoading={isLoading}
            />
        </div>
    );
}

export default function AuthorizedMenu() {
    const { t } = useTranslation("common");

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        // resolver: yupResolver(businessSetupValidationSchema),
    });

    const logout = () => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
    };

    // Again, we're using framer-motion for the transition effect
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div
                style={{
                    display: "flex",
                    direction: "rtl",
                }}
            >
                <Menu.Button className="flex items-center focus:outline-none">
                    <Avatar
                        // src={data?.profile?.avatar?.thumbnail ?? siteSettings?.avatar?.placeholder}
                        src={siteSettings?.avatar?.placeholder}
                        alt="avatar"
                    />
                </Menu.Button>

                <div
                    style={{
                        width: "200px",
                    }}
                >
                    <BusinessList control={control} errors={errors} />
                </div>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    as="ul"
                    className="end-0 origin-top-end absolute mt-1 w-48 rounded bg-white shadow-md focus:outline-none"
                >
                    {/* <Menu.Item key={data?.email}>
                        <li
                            className="flex w-full flex-col space-y-1 rounded-t
                                        bg-[#00b791] px-4 py-3 text-sm text-white"
                        >
                            <span className="font-semibold capitalize">{data?.name}</span>
                            <span className="text-xs">{data?.email}</span>
                        </li>
                    </Menu.Item> */}

                    {siteSettings.authorizedLinks.map(({ href, labelTransKey }) => (
                        <Menu.Item key={`${href}${labelTransKey}`}>
                            {({ active }) => (
                                <li className="cursor-pointer border-b border-gray-100 last:border-0">
                                    <Link
                                        to={href === Routes.logout ? "#" : href}
                                        className={cn(
                                            "block px-4 py-3 text-sm font-semibold capitalize transition duration-200 hover:text-accent",
                                            active ? "text-accent" : "text-heading"
                                        )}
                                        onClick={() => {
                                            if (href === Routes.logout) {
                                                logout();
                                            }
                                            // Routes.logout
                                        }}
                                    >
                                        {t(labelTransKey)}
                                    </Link>
                                </li>
                            )}
                        </Menu.Item>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
