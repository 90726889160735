import { Routes, Route } from "react-router-dom";
import { Routes as RoutesPath } from "../config/routes";
import AuthorizedRoute from "../components/AuthorizedRoute";
import DescriptionGenerator from "../views/aiAnalytics/descriptionGenerator";

const AiAnalyticsRoutes = () => {
    return (
        <Routes>
            <Route
                path={RoutesPath.AIAnalyticsDescriptionGenerator}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <DescriptionGenerator />
                    </AuthorizedRoute>
                }
            />
        </Routes>
    );
};

export default AiAnalyticsRoutes;
