import { useCallback, useEffect, useState } from "react";
import { Control, FieldErrors, FieldValues, useForm } from "react-hook-form";
import Label from "../ui/label";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import ValidationError from "../ui/form-validation-error";
import { useTranslation } from "react-i18next";
import SelectInput from "../ui/select-input";
import {
    AvailabilityTypeModel,
    BranchLocationItem,
    BranchLocationListResponse,
    BusinessDetailByIdItem,
    PackageDeliveryTypeItem,
    PickUpAtLocationListItemModel,
    ProductManagementBrandListReq,
    ProductManagementImage,
    ProductManagementInventoryAvailability,
    ProductManagementInventoryBrandItem,
    ProductManagementInventoryBranchListResponse,
    ProductManagementInventoryCreateOrUpdateReq,
    ProductManagementInventoryCreateOrUpdateResponse,
    ProductManagementInventoryDetails,
    ProductManagementInventoryDetailsReq,
    ProductManagementInventoryDetailsResponse,
    ProductManagementInventoryItem,
    ProductManagementInventoryPackageDeliveryTypeResponse,
    ProductManagementInventoryPackageWeightAndSize,
    ProductManagementInventoryProductConditionItem,
    ProductManagementInventoryProductConditionListResponse,
    ProductManagementPickupAtLocationAvailability,
    ProductManagementPolicyTypeItem,
    ProductManagementShipToLocationAvailability,
    ProductManagementVideoItem,
    SortOrder,
} from "../../types";
import Input from "../ui/forms/input";
import Button from "../ui/button";
import {
    useBusinessBranchLocationList,
    useBusinessDetailCreate,
    useBusinessDetailUpdate,
    useGetBusinessDetailRowItem,
    useProductManagementDeliveryTypeList,
    useProductManagementPolicyCrete,
    useProductManagementPolicyTypeList,
    useProductManagementProductConditionList,
} from "../../service/hook/business";
import { userStore } from "../../store";
import { useModalAction } from "../../provider/modal.context";
import {
    useProductManagementBrandList,
    useProductManagementInventoryCreateOrUpdate,
    useProductManagementInventoryDetails,
    useProductManagementList,
} from "../../service/hook/sector_1";
import { inventoryAddValidationSchema } from "./inventory-add-validation-schema";
import TextArea from "../ui/text-area";
import AddInventoryImageList from "./inventory-add-form-image-list";
import AddInventoryVideoList from "./inventory-add-form-video-list";
import AddInventoryAspectsList from "./inventory-add-form-aspects-list";
import Checkbox from "../ui/checkbox/checkbox";
import AddInventoryPickupAtLocationList from "./inventory-add-form-pickup-at-location-list";
import { inventoryPickUpAtLocationValidationSchema } from "./inventory-pickup-at-location-validation-schema";
import {
    AvailabilityPickupAtLocationUnitTypeItems,
    AvailabilityTypeItems,
    PackageDimensionsUnitItems,
    PackageWrightUnitItems,
} from "../../utils/constants";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

function BrandList({
    control,
    errors,
    items,
    isLoading,
    defaultValue,
    onChangeValue,
}: {
    control: Control<FormValues>;
    errors: FieldErrors<FormValues>;
    items: ProductManagementInventoryBrandItem[];
    isLoading: boolean;
    defaultValue?: ProductManagementInventoryBrandItem;
    onChangeValue: (item: ProductManagementInventoryBrandItem) => void;
}) {
    const { t } = useTranslation();
    const errorMessage: string = errors.brand?.message as string;

    return (
        <div className="mb-5">
            <Label>{t("form:select-label-brand")}</Label>
            <SelectInput
                name="brand"
                control={control}
                value={defaultValue}
                onChange={(value: ProductManagementInventoryBrandItem) => {
                    onChangeValue(value);
                }}
                getOptionLabel={(option: any) => option.brand_name}
                getOptionValue={(option: any) => option.brand_name}
                options={items!}
                isLoading={false}
            />
            <ValidationError message={t(errorMessage)} />
        </div>
    );
}

function ProductCondition({
    control,
    errors,
    items,
    defaultValue,
    onChangeValue,
}: {
    control: Control<FormValues>;
    errors: FieldErrors<FormValues>;
    items: ProductManagementInventoryProductConditionItem[];
    defaultValue?: ProductManagementInventoryProductConditionItem;
    onChangeValue: (item: ProductManagementInventoryProductConditionItem) => void;
}) {
    const { t } = useTranslation();
    const errorMessage: string = errors.productCondition?.message as string;

    return (
        <div className="mb-5 w-full">
            <Label>{t("form:select-label-product-condition")}</Label>
            <SelectInput
                name="productCondition"
                control={control}
                value={defaultValue}
                onChange={(value: ProductManagementInventoryProductConditionItem) => {
                    onChangeValue(value);
                }}
                getOptionLabel={(option: any) => option.condition_title}
                getOptionValue={(option: any) => option.condition_id}
                options={items!}
                isLoading={false}
            />
            <ValidationError message={t(errorMessage)} />
        </div>
    );
}

function BranchList<T extends FieldValues>({
    control,
    errors,
    items,
    defaultValue,
    onChangeValue,
}: {
    control: Control<T>;
    errors: FieldErrors<T>;
    items: BranchLocationItem[];
    defaultValue?: BranchLocationItem;
    onChangeValue: (item: BranchLocationItem) => void;
}) {
    const { t } = useTranslation();
    const errorMessage: string = errors.branch?.message as string;

    return (
        <div className="mb-5 w-1/2">
            <Label>{"Branch"}</Label>
            <SelectInput
                name="branch"
                control={control}
                value={defaultValue}
                onChange={(value: BranchLocationItem) => {
                    onChangeValue(value);
                }}
                getOptionLabel={(option: any) => option.location_name}
                getOptionValue={(option: any) => option.location_id}
                options={items!}
                isLoading={false}
            />
            <ValidationError message={t(errorMessage)} />
        </div>
    );
}

function AvailabilityType<T extends FieldValues>({
    control,
    errors,
    items,
    defaultValue,
    onChangeValue,
    name,
    title,
}: {
    control: Control<T>;
    errors: FieldErrors<T>;
    items: AvailabilityTypeModel[];
    defaultValue?: AvailabilityTypeModel;
    onChangeValue: (item: AvailabilityTypeModel) => void;
    name?: string;
    title?: string;
}) {
    const { t } = useTranslation();
    const errorMessage: string = errors[name ?? ""]?.message as string;

    return (
        <div className="mb-5 w-1/2">
            <Label>{title ?? ""}</Label>
            <SelectInput
                name={name ?? ""}
                control={control}
                value={defaultValue}
                onChange={(value: AvailabilityTypeModel) => {
                    onChangeValue(value);
                }}
                getOptionLabel={(option: any) => option.title}
                getOptionValue={(option: any) => option.value}
                options={items!}
                isLoading={false}
            />
            <ValidationError message={t(errorMessage)} />
        </div>
    );
}

function PackageDeliveryTypeList({
    control,
    errors,
    items,
    defaultValue,
    onChangeValue,
}: {
    control: Control<FormValues>;
    errors: FieldErrors<FormValues>;
    items: PackageDeliveryTypeItem[];
    defaultValue?: PackageDeliveryTypeItem;
    onChangeValue: (item: PackageDeliveryTypeItem) => void;
}) {
    const { t } = useTranslation();
    const errorMessage: string = errors.deliveryType?.message as string;

    return (
        <div
            className="mb-5 w-full"
            style={{
                minHeight: "200px",
            }}
        >
            <Label>{"Package delivery type"}</Label>
            <SelectInput
                name="deliveryType"
                control={control}
                value={defaultValue}
                onChange={(value: PackageDeliveryTypeItem) => {
                    onChangeValue(value);
                }}
                getOptionLabel={(option: any) => option.Package_Type_name}
                getOptionValue={(option: any) => option.Package_Type_id}
                options={items!}
                isLoading={false}
            />
            <ValidationError message={t(errorMessage)} />
        </div>
    );
}

type FormValuesPickUpAtLocation = {
    branch: any;
    availability: any;
    quantity: string;
    fulfillmentValue: string;
    unit: string;
};

const defaultValuesPickUpAtLocation = {
    quantity: "",
    fulfillmentValue: "",
    unit: "",
};

type FormValues = {
    sku: string;
    brand: any;
    productCondition: any;
    productTitle: string;
    productSubtitle: string;
    description: string;
    ean: string;
    isbn: string;
    upc: string;
    mpn: string;
    totalQuantity: string;
    isPickUpAtLocation: boolean;
    quantity: number;
    fulfillment: string;
    isShipToLocation: boolean;
    branch: any;
    availabilityType: any;
    height: string;
    width: string;
    length: string;
    lengthUnit: any;
    weight: string;
    weightUnit: any;
    deliveryType: any;
};

const defaultValues = {
    sku: "",
    productTitle: "",
    productSubtitle: "",
    description: "",
    ean: "",
    isbn: "",
    upc: "",
    mpn: "",
    isPickUpAtLocation: false,
    isShipToLocation: false,
    totalQuantity: "",
    // height: 0,
    // width: 0,
    // length: 0,
    // weight: 0,
};

const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
        async (data: any) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors: any) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors: any, currentError: any) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

export default function CreateOrUpdateProductInventoryDetailsForm({
    isEditMode,
}: {
    isEditMode?: boolean;
}) {
    const navigate = useNavigate();
    const params = useParams();

    const { t } = useTranslation();
    const { user, businessItem, setRegisteredBusinessId } = userStore((state) => state);

    const { openModal } = useModalAction();

    const [brandList, setBrandList] = useState<ProductManagementInventoryBrandItem[]>([]);
    const [selectedBrand, setSelectedBrand] = useState<
        ProductManagementInventoryBrandItem | undefined
    >(undefined);

    const [productConditionList, setProductConditionList] = useState<
        ProductManagementInventoryProductConditionItem[]
    >([]);
    const [selectedProductCondition, setSelectedProductCondition] = useState<
        ProductManagementInventoryProductConditionItem | undefined
    >(undefined);

    const [inventoryList, setInventoryList] = useState<ProductManagementInventoryItem[]>([]);
    const [policyTypeItemSelected, setPolicyTypeItemSelected] = useState<
        ProductManagementPolicyTypeItem | undefined
    >(undefined);

    const [pickUpAtLocationList, setPickUpAtLocationList] = useState<
        PickUpAtLocationListItemModel[]
    >([]);

    const [shipToLocationList, setShipToLocationList] = useState<PickUpAtLocationListItemModel[]>(
        []
    );

    const [packageDeliveryTypeList, setPackageDeliveryTypeList] = useState<
        PackageDeliveryTypeItem[]
    >([]);
    const [selectedPackageDeliveryTypeItem, setSelectedPackageDeliveryTypeItem] = useState<
        PackageDeliveryTypeItem | undefined
    >();

    const [branchLocationList, setBranchLocationList] = useState<BranchLocationItem[]>([]);
    const [selectedBranchLocationItem1, setSelectedBranchLocationItem1] = useState<
        BranchLocationItem | undefined
    >();
    const [selectedBranchLocationItem2, setSelectedBranchLocationItem2] = useState<
        BranchLocationItem | undefined
    >();

    const [
        selectedAvailabilityTypePickupAtLocationItem,
        setSelectedAvailabilityTypePickupAtLocationItem,
    ] = useState<AvailabilityTypeModel | undefined>();

    const [
        selectedAvailabilityTypePickupAtLocationItem2,
        setSelectedAvailabilityTypePickupAtLocationItem2,
    ] = useState<AvailabilityTypeModel | undefined>();

    const [
        selectedAvailabilityTypePickupAtLocationUnitTypeItem,
        setSelectedAvailabilityTypePickupAtLocationUnitTypeItem,
    ] = useState<AvailabilityTypeModel | undefined>();
    const [
        selectedAvailabilityTypePickupAtLocationUnitTypeItem2,
        setSelectedAvailabilityTypePickupAtLocationUnitTypeItem2,
    ] = useState<AvailabilityTypeModel | undefined>();

    const [selectedLengthUnitItem, setSelectedLengthUnitItem] = useState<
        AvailabilityTypeModel | undefined
    >();

    const [selectedWeightUnitItem, setSelectedWeightUnitItem] = useState<
        AvailabilityTypeModel | undefined
    >();

    const [selectedProductImageList, setSelectedProductImageList] = useState<
        ProductManagementImage[]
    >([]);
    const [selectedProductVideoList, setSelectedProductVideoList] = useState<
        ProductManagementVideoItem[]
    >([]);
    const [selectedProductAspectList, setSelectedProductAspectList] = useState<
        AvailabilityTypeModel[]
    >([]);

    const [inventoryDetails, setInventoryDetails] = useState<
        ProductManagementInventoryDetails | undefined
    >();

    const [page, setPage] = useState(1);
    const [orderBy, setOrder] = useState("created_at");
    const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);

    const [isShowFormPickupAtLocation, setIsShowFormPickupAtLocation] = useState<boolean>(false);
    const [isShowFormShipToLocation, setIsShowFormShipToLocation] = useState<boolean>(false);

    const resolver = useYupValidationResolver(inventoryAddValidationSchema);

    const resolverPickUpAtLocation = useYupValidationResolver(
        inventoryPickUpAtLocationValidationSchema
    );

    const {
        mutate: mutateProductManagementInventoryCreateOrUpdate,
        isLoading: isLoadingProductMangementInventoryCreateOrUpdate,
        serverError: serverErrorProductMangementInventoryCreateOrUpdate,
        setServerError: setServerErrorProductMangementInventoryCreateOrUpdate,
    } = useProductManagementInventoryCreateOrUpdate();

    const {
        mutate: mutateProductManagementInventoryDetials,
        isLoading: isLoadingProductMangementInventoryDetials,
        serverError: serverErrorProductMangementInventoryDetials,
        setServerError: setServerErrorProductMangementInventoryDetials,
    } = useProductManagementInventoryDetails();

    const {
        mutate: mutateProductManagementInventory,
        isLoading: isLoadingProductMangementInventory,
        serverError: serverErrorProductMangementInventory,
        setServerError: setServerErrorProductMangementInventory,
    } = useProductManagementList();

    const {
        mutate: mutateProductManagementInventoryBrand,
        isLoading: isLoadingProductMangementInventoryBrand,
        serverError: serverErrorProductMangementInventoryBrand,
        setServerError: setServerErrorProductMangementInventoryBrand,
    } = useProductManagementBrandList();

    const {
        mutate: mutateProductManagementProductCondition,
        isLoading: isLoadingProductMangementProductCondition,
        serverError: serverErrorProductMangementProductCondition,
        setServerError: setServerErrorProductMangementProductCondition,
    } = useProductManagementProductConditionList();

    const {
        mutate: mutateBranchLocationList,
        isLoading: isLoadinBranchLocationList,
        serverError: serverErroBranchLocationList,
        setServerError: setServerErroBranchLocationList,
    } = useBusinessBranchLocationList();

    const {
        mutate: mutatePackageDeliveryTypeList,
        isLoading: isLoadinPackageDeliveryTypeList,
        serverError: serverErroPackageDeliveryTypeList,
        setServerError: setServerErroPackageDeliveryTypeList,
    } = useProductManagementDeliveryTypeList();

    function handlePagination(current: any) {
        setPage(current);
    }

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        reset,
        setError,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        resolver,
    });

    const {
        register: registerFormPickupAtLocation,
        handleSubmit: handleSubmitFormPickupAtLocation,
        control: controlFormPickupAtLocation,
        getValues: getValueFormPickupAtLocation,
        setValue: setValueFormPickupAtLocation,
        watch: watchFormPickupAtLocation,
        reset: resetFormPickupAtLocation,
        setError: setErrorPickupAtLocation,
        clearErrors: clearErrorsPickupAtLocation,
        formState: { errors: errorsFormPickupAtLocation },
    } = useForm<FormValuesPickUpAtLocation>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValuesPickUpAtLocation,
        resolver: resolverPickUpAtLocation,
    });

    const {
        register: registerFormShipToLocation,
        handleSubmit: handleSubmitFormShipToLocation,
        control: controlFormShipToLocation,
        getValues: getValueFormShipToLocation,
        setValue: setValueFormShipToLocation,
        watch: watchFormShipToLocation,
        reset: resetFormShipToLocation,
        setError: setErrorShipToLocation,
        clearErrors: clearErrorsShipToLocation,
        formState: { errors: errorsFormShipToLocation },
    } = useForm<FormValuesPickUpAtLocation>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValuesPickUpAtLocation,
        resolver: resolverPickUpAtLocation,
    });

    useEffect(() => {
        getData();
    }, [businessItem]);

    useEffect(() => {
        const { skuCode } = params;
        if (skuCode) {
            if (brandList.length > 0) {
                setSelectedBrand(
                    brandList.find((findItem) => findItem.brand_id === inventoryDetails?.brand_id)
                );
            }
            if (productConditionList.length > 0) {
                setSelectedProductCondition(
                    productConditionList.find(
                        (findItem) =>
                            findItem.condition_title ===
                            inventoryDetails?.product_condition_description
                    )
                );
            }

            if (
                brandList.length > 0 &&
                productConditionList.length > 0 &&
                branchLocationList.length > 0 &&
                packageDeliveryTypeList.length > 0
            ) {
                const { skuCode } = params;
                if (skuCode) {
                    getProductInventoryItem(skuCode);
                }
            }
        }
    }, [brandList, productConditionList, branchLocationList, packageDeliveryTypeList]);

    const getData = async () => {
        await getProductInventoryBrandList();
        await getProductInventoryProductConditionList();
        await getBranchLocationList();
        await getPackageDeliveryTypeList();
    };

    const getProductInventoryItem = async (skuCode: string) => {
        const input: ProductManagementInventoryDetailsReq = {
            businessdetail_id: `${businessItem?.business_id ?? 0}`,
        };

        if (skuCode) {
            input.sku_code = skuCode;
        }

        await mutateProductManagementInventoryDetials(input, {
            onSuccess: (successData: ProductManagementInventoryDetailsResponse) => {
                if (successData.final_result_code === "000") {
                    setInventoryDetails(successData.captured_data);
                    formReset(successData.captured_data);
                }
            },
        });
    };

    const getProductInventoryBrandList = async (brand?: string, clear?: boolean) => {
        const brandReq: ProductManagementBrandListReq = {
            brand_name: clear ? "" : brand ? brand : "",
        };
        await mutateProductManagementInventoryBrand(brandReq, {
            onSuccess: (successData: ProductManagementInventoryBranchListResponse) => {
                if (successData.final_result_code === "000") {
                    setBrandList(successData.captured_data);
                }
            },
        });
    };

    const getProductInventoryProductConditionList = async (brand?: string, clear?: boolean) => {
        await mutateProductManagementProductCondition(undefined, {
            onSuccess: (successData: ProductManagementInventoryProductConditionListResponse) => {
                if (successData.final_result_code === "000") {
                    setProductConditionList(successData.captured_data);
                }
            },
        });
    };

    const getBranchLocationList = async (brand?: string, clear?: boolean) => {
        await mutateBranchLocationList(
            { businessdetail_id: businessItem?.business_id ?? 0 },
            {
                onSuccess: (successData: BranchLocationListResponse) => {
                    if (successData.final_result_code === "000") {
                        setBranchLocationList(successData.captured_data ?? []);
                    }
                },
            }
        );
    };

    const getPackageDeliveryTypeList = async (brand?: string, clear?: boolean) => {
        await mutatePackageDeliveryTypeList(undefined, {
            onSuccess: (successData: ProductManagementInventoryPackageDeliveryTypeResponse) => {
                if (successData.final_result_code === "000") {
                    setPackageDeliveryTypeList(successData.captured_data ?? []);
                }
            },
        });
    };

    const formReset = (item?: ProductManagementInventoryDetails) => {
        if (item) {
            reset({
                sku: item.sku_code,
                productTitle: item.product_title,
                productSubtitle: item.product_subtitle,
                description: item.product_description,
                ean: item.ean,
                isbn: item.isbn,
                upc: item.upc,
                mpn: item.mpn,
                totalQuantity: `${item.availability?.totalQuantity}`,
                isPickUpAtLocation:
                    (item.availability?.pickupAtLocationAvailability?.length ?? 0) > 0
                        ? true
                        : false,
                isShipToLocation: item.availability?.shipToLocationAvailability ? true : false,
                weight: `${item.packageweightandsize?.weight?.value}`,
                height: `${item.packageweightandsize?.dimensions?.height}`,
                width: `${item.packageweightandsize?.dimensions?.width}`,
                length: `${item.packageweightandsize?.dimensions?.length}`,
            });

            if (item.brand && item.brand_id) {
                setSelectedBrand(brandList.find((findItem) => findItem.brand_id === item.brand_id));
            }

            if (item.product_condition_description) {
                setSelectedProductCondition(
                    productConditionList.find(
                        (findItem) =>
                            findItem.condition_title === item.product_condition_description
                    )
                );
            }

            if ((item?.product_image_urls?.length ?? 0) > 0) {
                setSelectedProductImageList(
                    item?.product_image_urls?.map((mapItem, index) => {
                        const item: ProductManagementImage = {
                            _url: mapItem,
                            file_id: index,
                            file_name: mapItem.substring(mapItem.lastIndexOf("/") + 1),
                            file_urls: [],
                            url_type: "",
                        };
                        return item;
                    }) ?? []
                );
            }

            if ((item?.product_video_urls?.length ?? 0) > 0) {
                setSelectedProductVideoList(
                    item?.product_video_urls?.map((mapItem: string, index) => {
                        const item: ProductManagementVideoItem = {
                            _url: mapItem,
                            file_id: index,
                            file_name: mapItem.substring(mapItem.lastIndexOf("/") + 1),
                            file_urls: [],
                            url_type: "",
                        };
                        return item;
                    }) ?? []
                );
            }

            if (item?.product_aspects) {
                const aspectKeys = Object.keys(item.product_aspects) ?? [];
                const aspectValues = Object.values(item.product_aspects) ?? [];

                if (aspectKeys.length > 0 && aspectValues.length > 0)
                    setSelectedProductAspectList(
                        aspectKeys?.map((mapItem: string, index) => {
                            const item: AvailabilityTypeModel = {
                                id: index + 1,
                                title: aspectKeys[index],
                                value: aspectValues[index] as string,
                            };
                            return item;
                        }) ?? []
                    );
            }

            if ((item.availability?.pickupAtLocationAvailability.length ?? 0) > 0) {
                // PickUpAtLocationListItemModel
                setPickUpAtLocationList(
                    item.availability?.pickupAtLocationAvailability?.map((mapItem: any, index) => {
                        const item: PickUpAtLocationListItemModel = {
                            id: "",
                            branch: branchLocationList.find(
                                (findItem) => findItem.location_id === mapItem.branchLocationid
                            ),
                            availabilityType: AvailabilityTypeItems.find(
                                (findItem) => findItem.value === mapItem.availabilityType
                            ),
                            quantity: `${mapItem.quantity}`,
                            fulfillmentValue: `${mapItem?.fulfillmentTime?.value}`,
                            unit: AvailabilityPickupAtLocationUnitTypeItems.find(
                                (findItem) => findItem.value === mapItem?.fulfillmentTime?.unit
                            ),
                        };
                        return item;
                    }) ?? []
                );
            }

            if (
                (item.availability?.shipToLocationAvailability?.availabilityDistributions?.length ??
                    0) > 0
            ) {
                setShipToLocationList(
                    item.availability?.shipToLocationAvailability?.availabilityDistributions?.map(
                        (mapItem: any, index) => {
                            const item: PickUpAtLocationListItemModel = {
                                id: "",
                                branch: branchLocationList.find(
                                    (findItem) => findItem.location_id === mapItem.branchLocationid
                                ),
                                availabilityType: AvailabilityTypeItems.find(
                                    (findItem) => findItem.value === mapItem.availabilityType
                                ),
                                quantity: `${mapItem.quantity}`,
                                fulfillmentValue: `${mapItem?.fulfillmentTime?.value}`,
                                unit: AvailabilityPickupAtLocationUnitTypeItems.find(
                                    (findItem) => findItem.value === mapItem?.fulfillmentTime?.unit
                                ),
                            };
                            return item;
                        }
                    ) ?? []
                );
            }

            if (item.packageweightandsize) {
                if (item.packageweightandsize?.weight) {
                    setSelectedWeightUnitItem(
                        PackageWrightUnitItems.find(
                            (findItem) => findItem.value === item.packageweightandsize?.weight?.unit
                        ) ?? undefined
                    );
                }
            }

            if (item.packageweightandsize) {
                if (item.packageweightandsize?.dimensions) {
                    setSelectedLengthUnitItem(
                        PackageDimensionsUnitItems.find(
                            (findItem) =>
                                findItem.value === item.packageweightandsize?.dimensions?.unit
                        ) ?? undefined
                    );
                }
            }

            if (item.packageweightandsize) {
                if (item.packageweightandsize?.packageType) {
                    setSelectedPackageDeliveryTypeItem(
                        packageDeliveryTypeList.find(
                            (findItem) =>
                                findItem.Package_Type_name ===
                                item.packageweightandsize?.packageType
                        ) ?? undefined
                    );
                }
            }
        } else {
            // reset(defaultValues);
            // setPolicyTypeItemSelected(undefined);
        }
    };

    const onSubmit = async (values: FormValues) => {
        try {
            const productAspect: any = {};
            for (let i = 0; i < selectedProductAspectList.length; i++) {
                const aspectItem = selectedProductAspectList[i];
                productAspect[aspectItem.title] = aspectItem.value;
            }

            const pickupAtLocationAvailabilityArray: ProductManagementPickupAtLocationAvailability[] =
                pickUpAtLocationList.map((mapItem) => {
                    return {
                        branchLocationid: mapItem?.branch?.location_id ?? 0,
                        availabilityType: mapItem?.availabilityType?.value ?? "",
                        quantity: parseInt(mapItem?.quantity ?? 0),
                        fulfillmentTime: {
                            unit: mapItem?.unit?.value ?? "",
                            value: parseInt(mapItem?.fulfillmentValue ?? 0),
                        },
                    };
                });

            const shipToLocationAvailabilityObject: ProductManagementShipToLocationAvailability = {
                availabilityDistributions: shipToLocationList.map((mapItem) => {
                    return {
                        branchLocationid: mapItem?.branch?.location_id ?? 0,
                        availabilityType: mapItem?.availabilityType?.value ?? "",
                        quantity: parseInt(mapItem?.quantity ?? 0),
                        fulfillmentTime: {
                            unit: mapItem?.unit?.value ?? "",
                            value: parseInt(mapItem?.fulfillmentValue ?? 0),
                        },
                    };
                }),
            };

            const inventoryAvailabilityItem: ProductManagementInventoryAvailability = {
                totalQuantity: parseInt(
                    values.totalQuantity.length > 0 ? values.totalQuantity : "0"
                ),
                pickupAtLocationAvailability: pickupAtLocationAvailabilityArray,
                shipToLocationAvailability: shipToLocationAvailabilityObject,
            };

            const inventoryPackageWeightAndSize: ProductManagementInventoryPackageWeightAndSize = {
                dimensions: {
                    height: parseInt(values.height.length > 0 ? values.height : "0"),
                    width: parseInt(values.width.length > 0 ? values.width : "0"),
                    length: parseInt(values.length.length > 0 ? values.length : "0"),
                    unit: selectedLengthUnitItem?.value ?? "",
                },
                weight: {
                    value: parseInt(values.weight.length > 0 ? values.weight : "0"),
                    unit: selectedWeightUnitItem?.value ?? "",
                },
                packageType: selectedPackageDeliveryTypeItem?.Package_Type_name ?? "",
            };

            const input: ProductManagementInventoryCreateOrUpdateReq = {
                email: user?.email ?? "",
                businessdetail_id: `${businessItem?.business_id ?? 0}`,
                sku_code: values.sku,
                product_condition_id: selectedProductCondition?.condition_id ?? 0,
                product_condition_description: selectedProductCondition?.condition_title ?? "",
                brand_id: selectedBrand?.brand_id ?? 0,
                brand: selectedBrand?.brand_name ?? "",
                product_description: values.description,
                product_title: values.productTitle,
                product_subtitle: values.productSubtitle,
                ean: values.ean,
                isbn: values.isbn,
                mpn: values.mpn,
                upc: values.upc,
                product_image_urls: selectedProductImageList.map((mapItem) => mapItem._url),
                product_video_urls: selectedProductVideoList.map((mapItem) => mapItem._url),
                product_aspects: productAspect,
                availability: inventoryAvailabilityItem,
                packageweightandsize: inventoryPackageWeightAndSize,
            };

            if (params.skuCode) {
                input.product_inventory_id = inventoryDetails?.product_inventory_id;
            }

            // console.log(input);

            mutateProductManagementInventoryCreateOrUpdate(input, {
                onSuccess: (successData: ProductManagementInventoryCreateOrUpdateResponse) => {
                    if (successData.final_result_code === "000") {
                        toast.success(successData.general_result);
                        navigate(-1);
                    } else {
                        toast.error(successData.general_result);
                    }
                },
                onError: (error: Error) => {
                    const {
                        response: { data },
                    }: any = error ?? {};

                    toast.error(data?.general_result);
                },
            });
        } catch (error) {
            console.log("error => ", error);
        }
    };

    const onSubmitFormPickupAtLocation = () => {
        clearErrorsPickupAtLocation();
        if (!selectedBranchLocationItem1) {
            setErrorPickupAtLocation("branch", {
                message: "Please select blanch.",
            });
            return false;
        }
        if (!selectedAvailabilityTypePickupAtLocationItem) {
            setErrorPickupAtLocation("availability", {
                message: "Please select availability type.",
            });
            return false;
        }

        if (!selectedAvailabilityTypePickupAtLocationUnitTypeItem) {
            setErrorPickupAtLocation("unit", {
                message: "Please select fulfillment value type.",
            });
            return false;
        }

        if (getValueFormPickupAtLocation("quantity") === "") {
            setErrorPickupAtLocation("quantity", {
                message: "Please enter quantity.",
            });
            return false;
        }

        if (getValueFormPickupAtLocation("fulfillmentValue") === "") {
            setErrorPickupAtLocation("fulfillmentValue", {
                message: "Please enter fulfillment value.",
            });
            return false;
        }

        const item = {
            id: `${new Date().getTime()}`,
            branch: selectedBranchLocationItem1,
            availabilityType: selectedAvailabilityTypePickupAtLocationItem,
            quantity: getValueFormPickupAtLocation("quantity"),
            fulfillmentValue: getValueFormPickupAtLocation("fulfillmentValue"),
            unit: selectedAvailabilityTypePickupAtLocationUnitTypeItem,
        };

        setPickUpAtLocationList([...pickUpAtLocationList, item]);

        resetFormPickupAtLocation({
            quantity: "",
            fulfillmentValue: "",
        });
    };

    const onSubmitFormShipToLocation = () => {
        clearErrorsShipToLocation();
        if (!selectedBranchLocationItem2) {
            setErrorShipToLocation("branch", {
                message: "Please select blanch.",
            });
            return false;
        }
        if (!selectedAvailabilityTypePickupAtLocationItem2) {
            setErrorShipToLocation("availability", {
                message: "Please select availability type.",
            });
            return false;
        }

        if (!selectedAvailabilityTypePickupAtLocationUnitTypeItem2) {
            setErrorShipToLocation("unit", {
                message: "Please select fulfillment value type.",
            });
            return false;
        }

        if (getValueFormShipToLocation("quantity") === "") {
            setErrorShipToLocation("quantity", {
                message: "Please enter quantity.",
            });
            return false;
        }

        if (getValueFormShipToLocation("fulfillmentValue") === "") {
            setErrorShipToLocation("fulfillmentValue", {
                message: "Please enter fulfillment value.",
            });
            return false;
        }

        const item = {
            id: `${new Date().getTime()}`,
            branch: selectedBranchLocationItem2,
            availabilityType: selectedAvailabilityTypePickupAtLocationItem2,
            quantity: getValueFormShipToLocation("quantity"),
            fulfillmentValue: getValueFormShipToLocation("fulfillmentValue"),
            unit: selectedAvailabilityTypePickupAtLocationUnitTypeItem2,
        };

        setShipToLocationList([...shipToLocationList, item]);

        resetFormShipToLocation({
            quantity: "",
            fulfillmentValue: "",
        });
    };

    const isPickUpAtLocation = watch("isPickUpAtLocation");
    const isShipToLocation = watch("isShipToLocation");

    return (
        <>
            <form id={"inventory-add-form"} onSubmit={handleSubmit(onSubmit)} className="">
                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        title={"Enter product details information "}
                        details={[t("form:form-description-product-management-add-inventory-01")]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />

                    <Card className="w-full sm:w-8/12 md:w-2/3 flex-col space-y-4">
                        <Input
                            label={"Product code (SKU)"}
                            {...register("sku")}
                            placeholder="SKU"
                            error={t(errors.sku?.message!)}
                            variant="outline"
                            maxLength={70}
                        />

                        <BrandList
                            control={control}
                            errors={errors}
                            items={brandList}
                            isLoading={isLoadingProductMangementInventoryBrand}
                            defaultValue={selectedBrand}
                            onChangeValue={(item) => {
                                setSelectedBrand(item);
                            }}
                        />

                        <ProductCondition
                            control={control}
                            errors={errors}
                            items={productConditionList}
                            defaultValue={selectedProductCondition}
                            onChangeValue={(item) => {
                                setSelectedProductCondition(item);
                            }}
                        />

                        <Input
                            label={"Product title"}
                            {...register("productTitle")}
                            placeholder="Product title"
                            error={t(errors.productTitle?.message!)}
                            variant="outline"
                            maxLength={70}
                        />

                        <Input
                            label={"Product subtitle"}
                            {...register("productSubtitle")}
                            placeholder="Product subtitle"
                            error={t(errors.productSubtitle?.message!)}
                            variant="outline"
                            maxLength={65}
                        />

                        <TextArea
                            label={t("form:input-label-description")}
                            {...register("description")}
                            error={t(errors.description?.message!)}
                            variant="outline"
                            placeholder="Description"
                            className="mb-5"
                            maxLength={4000}
                        />

                        <Input
                            label={"EAN"}
                            {...register("ean")}
                            placeholder="EAN"
                            error={t(errors.ean?.message!)}
                            variant="outline"
                            maxLength={20}
                        />

                        <Input
                            label={"ISBN"}
                            {...register("isbn")}
                            placeholder="ISBN"
                            error={t(errors.isbn?.message!)}
                            variant="outline"
                            maxLength={20}
                        />

                        <Input
                            label={"UPC"}
                            {...register("upc")}
                            placeholder="UPC"
                            error={t(errors.upc?.message!)}
                            variant="outline"
                            maxLength={20}
                        />

                        <Input
                            label={"MPN"}
                            {...register("mpn")}
                            placeholder="MPN"
                            error={t(errors.mpn?.message!)}
                            variant="outline"
                            maxLength={65}
                        />
                    </Card>
                </div>

                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        title={"Select product image or video "}
                        details={[t("form:form-description-product-management-add-inventory-02")]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />
                    <Card className="w-full sm:w-8/12 md:w-2/3 flex-col ">
                        <Label>{"Product image urls"}</Label>

                        <AddInventoryImageList
                            imageList={selectedProductImageList}
                            paginatorInfo={null}
                            onPagination={handlePagination}
                            onOrder={setOrder}
                            onSort={setColumn}
                            onDeleteItem={(item: ProductManagementImage) => {
                                setSelectedProductImageList(
                                    selectedProductImageList.filter((filterItem) => {
                                        return filterItem.file_id !== item.file_id;
                                    })
                                );
                            }}
                        />

                        <div className="mb-4 text-right space-x-4 mt-4">
                            <Button
                                variant={"secondary"}
                                size={"small"}
                                form={"addImageDialog"}
                                loading={false}
                                onClick={() => {
                                    openModal(
                                        "ProductManagement_Inventory_Add_Image_From_ImageList",
                                        {
                                            item: { selectedItems: selectedProductImageList },
                                            isMultiSelect: true,
                                            callback: (
                                                selectedImageList: ProductManagementImage[]
                                            ) => {
                                                setSelectedProductImageList(selectedImageList);
                                            },
                                        }
                                    );
                                }}
                            >
                                {"Add new image"}
                            </Button>
                        </div>

                        <Label>{"Product video urls"}</Label>

                        <AddInventoryVideoList
                            imageList={selectedProductVideoList}
                            paginatorInfo={null}
                            onPagination={handlePagination}
                            onOrder={setOrder}
                            onSort={setColumn}
                            onDeleteItem={(item: ProductManagementImage) => {
                                setSelectedProductVideoList(
                                    selectedProductVideoList.filter((filterItem) => {
                                        return filterItem.file_id !== item.file_id;
                                    })
                                );
                            }}
                        />

                        <div className="mb-4 text-right space-x-4 mt-4">
                            <Button
                                variant={"secondary"}
                                size={"small"}
                                loading={false}
                                form={"addVideoDialog"}
                                onClick={() => {
                                    openModal(
                                        "ProductManagement_Inventory_Add_Video_From_VideoList",
                                        {
                                            item: { selectedItems: selectedProductVideoList },
                                            callback: (
                                                selectedVideoList: ProductManagementVideoItem[]
                                            ) => {
                                                setSelectedProductVideoList(selectedVideoList);
                                            },
                                        }
                                    );
                                }}
                            >
                                {"Add new video"}
                            </Button>
                        </div>

                        <Label>{"Product aspects"}</Label>

                        <AddInventoryAspectsList
                            imageList={selectedProductAspectList}
                            paginatorInfo={null}
                            onPagination={handlePagination}
                            onOrder={setOrder}
                            onSort={setColumn}
                            onDeleteItem={(item: AvailabilityTypeModel) => {
                                setSelectedProductAspectList(
                                    selectedProductAspectList.filter((filterItem) => {
                                        return filterItem.id !== item.id;
                                    })
                                );
                            }}
                        />

                        <div className="mb-4 text-right space-x-4 mt-4">
                            <Button
                                variant={"secondary"}
                                size={"small"}
                                form={"addAspectDialog"}
                                loading={false}
                                onClick={() => {
                                    openModal(
                                        "ProductManagement_Inventory_Add_Aspect_From_AspectList",
                                        {
                                            item: {},
                                            callback: (aspectItem: AvailabilityTypeModel) => {
                                                setSelectedProductAspectList([
                                                    ...selectedProductAspectList,
                                                    {
                                                        ...aspectItem,
                                                        id: new Date().getTime(),
                                                    },
                                                ]);
                                            },
                                        }
                                    );
                                }}
                            >
                                {"Add new aspect"}
                            </Button>
                        </div>
                    </Card>
                </div>

                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        details={[]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />
                    <Card className="w-full sm:w-8/12 md:w-2/3 flex-col space-y-4">
                        <div>
                            <Label>{"Availability"}</Label>
                            <Input
                                label={"Total quantity number"}
                                {...register("totalQuantity")}
                                placeholder="Total quantity"
                                error={t(errors.totalQuantity?.message!)}
                                variant="outline"
                            />
                        </div>

                        <Checkbox
                            {...register("isPickUpAtLocation")}
                            label={`Pick up at location availability`}
                            className="mb-5"
                        />
                        {isPickUpAtLocation && (
                            <div>
                                <AddInventoryPickupAtLocationList
                                    items={pickUpAtLocationList ?? []}
                                    paginatorInfo={null}
                                    onPagination={handlePagination}
                                    onOrder={setOrder}
                                    onSort={setColumn}
                                    onDeleteItem={(item: PickUpAtLocationListItemModel) => {
                                        if (pickUpAtLocationList.length > 0)
                                            setPickUpAtLocationList(
                                                pickUpAtLocationList.filter((filterItem) => {
                                                    return filterItem.id !== item.id;
                                                })
                                            );
                                    }}
                                    emptyNode={
                                        <div>
                                            {!isShowFormPickupAtLocation ? (
                                                <Button
                                                    variant={"secondary"}
                                                    size={"small"}
                                                    form={"inventory-add-form-pickup-at-location"}
                                                    loading={false}
                                                    onClick={() => {
                                                        setIsShowFormPickupAtLocation(true);
                                                    }}
                                                >
                                                    {"Add new row"}
                                                </Button>
                                            ) : (
                                                <Label>{"Please fill in the fields below."}</Label>
                                            )}
                                        </div>
                                    }
                                />

                                {pickUpAtLocationList.length > 0 && !isShowFormPickupAtLocation && (
                                    <div className="mb-2 text-right space-x-4 mt-2">
                                        <Button
                                            variant={"secondary"}
                                            size={"small"}
                                            form={"inventory-add-form-pickup-at-location"}
                                            loading={false}
                                            onClick={() => {
                                                setIsShowFormPickupAtLocation(true);
                                            }}
                                        >
                                            {"Add new row"}
                                        </Button>
                                    </div>
                                )}

                                {isShowFormPickupAtLocation && (
                                    <div>
                                        <div className="flex space-x-2">
                                            <BranchList<FormValuesPickUpAtLocation>
                                                control={controlFormPickupAtLocation}
                                                errors={errorsFormPickupAtLocation}
                                                items={branchLocationList}
                                                defaultValue={selectedBranchLocationItem1}
                                                onChangeValue={(item) => {
                                                    setSelectedBranchLocationItem1(item);
                                                    setErrorPickupAtLocation("branch", {
                                                        message: "",
                                                    });
                                                }}
                                            />
                                            <AvailabilityType
                                                control={controlFormPickupAtLocation}
                                                name={"availability"}
                                                title={"Availability type"}
                                                errors={errorsFormPickupAtLocation}
                                                items={AvailabilityTypeItems}
                                                defaultValue={
                                                    selectedAvailabilityTypePickupAtLocationItem
                                                }
                                                onChangeValue={(item) => {
                                                    setSelectedAvailabilityTypePickupAtLocationItem(
                                                        item
                                                    );
                                                    setErrorPickupAtLocation("availability", {
                                                        message: "",
                                                    });
                                                }}
                                            />
                                        </div>

                                        <div className="flex space-x-2">
                                            <Input
                                                label={"Quantity"}
                                                {...registerFormPickupAtLocation("quantity")}
                                                placeholder="Quantity"
                                                error={t(
                                                    errorsFormPickupAtLocation.quantity?.message!
                                                )}
                                                variant="outline"
                                                className="w-1/2"
                                            />

                                            <div className="flex w-1/2 space-x-2 justify-items-center justify-between">
                                                <Input
                                                    label={"Fulfillment value"}
                                                    {...registerFormPickupAtLocation(
                                                        "fulfillmentValue"
                                                    )}
                                                    placeholder="Fulfillment value"
                                                    error={t(
                                                        errorsFormPickupAtLocation.fulfillmentValue
                                                            ?.message!
                                                    )}
                                                    variant="outline"
                                                    className="w-1/2"
                                                />

                                                <AvailabilityType
                                                    control={controlFormPickupAtLocation}
                                                    name={"unit"}
                                                    title={"Unit"}
                                                    errors={errorsFormPickupAtLocation}
                                                    items={
                                                        AvailabilityPickupAtLocationUnitTypeItems
                                                    }
                                                    defaultValue={
                                                        selectedAvailabilityTypePickupAtLocationUnitTypeItem
                                                    }
                                                    onChangeValue={(item) => {
                                                        setSelectedAvailabilityTypePickupAtLocationUnitTypeItem(
                                                            item
                                                        );
                                                        setErrorPickupAtLocation("unit", {
                                                            message: "",
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-2 text-right space-x-4 mt-2">
                                            <Button
                                                variant={"cancel"}
                                                size={"small"}
                                                form={"inventory-add-form-pickup-at-location"}
                                                loading={false}
                                                onClick={() => {
                                                    setIsShowFormPickupAtLocation(false);
                                                }}
                                            >
                                                {"Cancel"}
                                            </Button>

                                            <Button
                                                variant={"secondary"}
                                                size={"small"}
                                                form={"inventory-add-form-pickup-at-location"}
                                                loading={false}
                                                onClick={onSubmitFormPickupAtLocation}
                                            >
                                                {"save"}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        <Checkbox
                            {...register("isShipToLocation")}
                            label={`Ship to location availability`}
                            className="mb-5"
                        />

                        {isShipToLocation && (
                            <div>
                                <AddInventoryPickupAtLocationList
                                    items={shipToLocationList ?? []}
                                    paginatorInfo={null}
                                    onPagination={handlePagination}
                                    onOrder={setOrder}
                                    onSort={setColumn}
                                    onDeleteItem={(item: PickUpAtLocationListItemModel) => {
                                        if (shipToLocationList.length > 0)
                                            setShipToLocationList(
                                                shipToLocationList.filter((filterItem) => {
                                                    return filterItem.id !== item.id;
                                                })
                                            );
                                    }}
                                    emptyNode={
                                        <div>
                                            {!isShowFormShipToLocation ? (
                                                <Button
                                                    variant={"secondary"}
                                                    size={"small"}
                                                    form={"inventory-add-form-pickup-at-location"}
                                                    loading={false}
                                                    onClick={() => {
                                                        setIsShowFormShipToLocation(true);
                                                    }}
                                                >
                                                    {"Add new row"}
                                                </Button>
                                            ) : (
                                                <Label>{"Please fill in the fields below."}</Label>
                                            )}
                                        </div>
                                    }
                                />

                                {shipToLocationList.length > 0 && !isShowFormShipToLocation && (
                                    <div className="mb-2 text-right space-x-4 mt-2">
                                        <Button
                                            variant={"secondary"}
                                            size={"small"}
                                            form={"inventory-add-form-pickup-at-location"}
                                            loading={false}
                                            onClick={() => {
                                                setIsShowFormShipToLocation(true);
                                            }}
                                        >
                                            {"Add new row"}
                                        </Button>
                                    </div>
                                )}

                                {isShowFormShipToLocation && (
                                    <div>
                                        <div className="flex space-x-2">
                                            <BranchList<FormValuesPickUpAtLocation>
                                                control={controlFormShipToLocation}
                                                errors={errorsFormShipToLocation}
                                                items={branchLocationList}
                                                defaultValue={selectedBranchLocationItem2}
                                                onChangeValue={(item) => {
                                                    setSelectedBranchLocationItem2(item);
                                                    setErrorShipToLocation("branch", {
                                                        message: "",
                                                    });
                                                }}
                                            />
                                            <AvailabilityType
                                                control={controlFormShipToLocation}
                                                name={"availability"}
                                                title={"Availability type"}
                                                errors={errorsFormShipToLocation}
                                                items={AvailabilityTypeItems}
                                                defaultValue={
                                                    selectedAvailabilityTypePickupAtLocationItem2
                                                }
                                                onChangeValue={(item) => {
                                                    setSelectedAvailabilityTypePickupAtLocationItem2(
                                                        item
                                                    );
                                                    setErrorShipToLocation("availability", {
                                                        message: "",
                                                    });
                                                }}
                                            />
                                        </div>

                                        <div className="flex space-x-2">
                                            <Input
                                                label={"Quantity"}
                                                {...registerFormShipToLocation("quantity")}
                                                placeholder="Quantity"
                                                error={t(
                                                    errorsFormShipToLocation.quantity?.message!
                                                )}
                                                variant="outline"
                                                className="w-1/2"
                                            />

                                            <div className="flex w-1/2 space-x-2 justify-items-center justify-between">
                                                <Input
                                                    label={"Fulfillment value"}
                                                    {...registerFormShipToLocation(
                                                        "fulfillmentValue"
                                                    )}
                                                    placeholder="Fulfillment value"
                                                    error={t(
                                                        errorsFormShipToLocation.fulfillmentValue
                                                            ?.message!
                                                    )}
                                                    variant="outline"
                                                    className="w-1/2"
                                                />

                                                <AvailabilityType
                                                    control={controlFormShipToLocation}
                                                    name={"unit"}
                                                    title={"Unit"}
                                                    errors={errorsFormShipToLocation}
                                                    items={
                                                        AvailabilityPickupAtLocationUnitTypeItems
                                                    }
                                                    defaultValue={
                                                        selectedAvailabilityTypePickupAtLocationUnitTypeItem2
                                                    }
                                                    onChangeValue={(item) => {
                                                        setSelectedAvailabilityTypePickupAtLocationUnitTypeItem2(
                                                            item
                                                        );
                                                        setErrorShipToLocation("unit", {
                                                            message: "",
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-2 text-right space-x-4 mt-2">
                                            <Button
                                                variant={"cancel"}
                                                size={"small"}
                                                form={"inventory-add-form-pickup-at-location"}
                                                loading={false}
                                                onClick={() => {
                                                    setIsShowFormShipToLocation(false);
                                                }}
                                            >
                                                {"Cancel"}
                                            </Button>

                                            <Button
                                                variant={"secondary"}
                                                size={"small"}
                                                form={"inventory-add-form-ship-to-location"}
                                                loading={false}
                                                onClick={onSubmitFormShipToLocation}
                                            >
                                                {t("form:button-label-add")}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </Card>
                </div>

                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        details={[]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />
                    <Card className="w-full sm:w-8/12 md:w-2/3 flex-col ">
                        <Label>{"Package dimensions"}</Label>
                        <div className="space-y-4">
                            <div className="flex w-full space-x-2">
                                <Input
                                    label={"Height"}
                                    {...register("height")}
                                    placeholder="Height"
                                    error={t(errors.height?.message!)}
                                    variant="outline"
                                    className="w-1/2"
                                    maxLength={4}
                                />
                                <Input
                                    label={"Width"}
                                    {...register("width")}
                                    placeholder="Width"
                                    error={t(errors.width?.message!)}
                                    variant="outline"
                                    className="w-1/2"
                                    maxLength={4}
                                />
                            </div>
                            <div className="flex w-full space-x-2 justify-items-center justify-between">
                                <Input
                                    label={"Length"}
                                    {...register("length")}
                                    placeholder="Length"
                                    error={t(errors.length?.message!)}
                                    variant="outline"
                                    className="w-1/2"
                                    maxLength={6}
                                />

                                <AvailabilityType
                                    control={control}
                                    name={"lengthUnit"}
                                    title={"Unit"}
                                    errors={errors}
                                    items={PackageDimensionsUnitItems}
                                    defaultValue={selectedLengthUnitItem}
                                    onChangeValue={(item) => {
                                        setSelectedLengthUnitItem(item);
                                        setError("lengthUnit", {
                                            message: "",
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <Label>{"Package"}</Label>
                        <div className="flex w-full space-x-2 justify-items-center justify-between">
                            <Input
                                label={"Weight"}
                                {...register("weight")}
                                placeholder="Weight"
                                error={t(errors.weight?.message!)}
                                variant="outline"
                                className="w-1/2"
                                maxLength={4}
                            />

                            <AvailabilityType
                                control={control}
                                name={"weightUnit"}
                                title={"Unit"}
                                errors={errors}
                                items={PackageWrightUnitItems}
                                defaultValue={selectedWeightUnitItem}
                                onChangeValue={(item) => {
                                    setSelectedWeightUnitItem(item);
                                    setError("weightUnit", {
                                        message: "",
                                    });
                                }}
                            />
                        </div>
                        <Label>{"Package"}</Label>
                        <div className="flex w-full space-x-2 justify-items-center justify-between">
                            <PackageDeliveryTypeList
                                control={control}
                                errors={errors}
                                items={packageDeliveryTypeList}
                                defaultValue={selectedPackageDeliveryTypeItem}
                                onChangeValue={(item: PackageDeliveryTypeItem) => {
                                    setSelectedPackageDeliveryTypeItem(item);
                                }}
                            />
                        </div>

                        <div className="mb-4 text-center space-x-4 mt-4">
                            <Button
                                variant={"cancelForm"}
                                form={"backToList"}
                                loading={false}
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                {t("form:button-label-cancel")}
                            </Button>

                            {(!params.skuCode || isEditMode) && (
                                <Button
                                    form={"inventory-add-form"}
                                    loading={isLoadingProductMangementInventoryCreateOrUpdate}
                                    disabled={isLoadingProductMangementInventoryCreateOrUpdate}
                                >
                                    {params.skuCode ? "Update" : t("form:button-label-submit")}
                                </Button>
                            )}
                        </div>
                    </Card>
                </div>
            </form>
        </>
    );
}
