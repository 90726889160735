import { Routes, Route } from "react-router-dom";
import { Routes as RoutesPath } from "../config/routes";
import Landing from "../views/landing";
import BusinessSetupSubscriptionView from "../views/businessSetup/subscription";
import BaseLayout from "../components/layouts/base";

const LandingRoutes = () => {
    return (
        <BaseLayout>
            <Routes>
                <Route path={RoutesPath.landing} element={<Landing />} />
                <Route
                    path={RoutesPath.PubSubscription}
                    element={<BusinessSetupSubscriptionView />}
                />
            </Routes>
        </BaseLayout>
    );
};

export default LandingRoutes;
