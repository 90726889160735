import { Link } from "react-router-dom";
import IconAdvanced from "../../assets/icon/advanced.png";
import IconDiamond from "../../assets/icon/diamond.png";
import IconPaySetBiometric from "../../assets/icon/pay-set-biometric.png";
import IconPorofits from "../../assets/icon/profits.png";
import IconQualified from "../../assets/icon/qualified.png";
import IconSwapFarming from "../../assets/icon/swap-farming.png";
import { Routes } from "../../config/routes";

export default function Footer() {
    const item = (title: string, subtitle: string[]) => {
        return (
            <div className="rounded-lg p-2 space-y-4 w-full h-44 min-w-max">
                <h1 className="text-lg text-gray-900 font-bold">{title}</h1>

                <div className="grid">
                    {subtitle.map((mapItem) => {
                        return (
                            <>
                                <Link to={getLink(mapItem)} className="cursor-pointer">
                                    <span className="text-base leading-6 text-gray-800">
                                        {mapItem}
                                    </span>
                                </Link>
                            </>
                        );
                    })}
                </div>
            </div>
        );
    };

    const getLink = (item: string) => {
        switch (item) {
            case "Terms":
                return Routes.terms;
            case "Privacy Notice":
                return Routes.privacy;
            case "GDPR":
                return Routes.gdpr;
            default:
                return "#";
        }
    };
    return (
        <div
            style={{
                marginTop: "150px",
                paddingLeft: "200px",
                paddingRight: "200px",
            }}
            className="w-full"
        >
            <div
                style={{
                    // left: 200,
                    top: 200,
                }}
            >
                <div className="flex justify-between w-full mt-4 pt-4">
                    <div className="w-full flex">
                        {item("Community", ["Meta", "X"])}
                        {item("Company", ["About Us", "Inquiries", "Careers", "Press"])}
                        {item("Support", ["Support center", "24/7 Chat Support", "Fees", "Rules"])}
                        <div>
                            {item("Legal", ["Terms", "Privacy Notice", "GDPR"])}
                            {item("Compliance", [
                                "Risk Warning",
                                "Law Enforcement Request",
                                "Licenses & Registrations",
                            ])}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
