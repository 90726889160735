import React, { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import client from "../client";

export function useBusinessProfile() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.getBusinessProfileByWebUrl,
    {
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessDetailsList() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.getDetailList, {
    onSuccess: (data) => {
      // if (!data.token) {
      //     setServerError('error-credential-wrong');
      //     return;
      // }
      // setToken(data.token);
      // closeModal();
    },
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useUserBusinessDetailList() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.getUserDetailList, {
    onSuccess: (data) => {
      // if (!data.token) {
      //     setServerError('error-credential-wrong');
      //     return;
      // }
      // setToken(data.token);
      // closeModal();
    },
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useGetBusinessDetailRowItem() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.getUserDetailByIdShow,
    {
      onSuccess: (data) => {
        // console.log('useGetBusinessDetailRowItem data => ', data);
        // if (!data.token) {
        //     setServerError('error-credential-wrong');
        //     return;
        // }
        // setToken(data.token);
        // closeModal();
      },
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessDetailCreate() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.detailCreate, {
    onSuccess: (data) => {
      // console.log('useBusinessDetailCreate data => ', data);
      // if (!data.token) {
      //     setServerError('error-credential-wrong');
      //     return;
      // }
      // setToken(data.token);
      // closeModal();
    },
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessDetailUpdate() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.detailUpdate, {
    onSuccess: (data) => {
      // console.log('useBusinessDetailCreate data => ', data);
      // if (!data.token) {
      //     setServerError('error-credential-wrong');
      //     return;
      // }
      // setToken(data.token);
      // closeModal();
    },
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessUserShowList() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.userShow, {
    onSuccess: (data) => {
      // if (!data.token) {
      //     setServerError('error-credential-wrong');
      //     return;
      // }
      // setToken(data.token);
      // closeModal();
    },
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessUserCreate() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.userCreate, {
    onSuccess: (data) => {
      // console.log('useBusinessUserCreate data => ', data);
      // if (!data.token) {
      //     setServerError('error-credential-wrong');
      //     return;
      // }
      // setToken(data.token);
      // closeModal();
    },
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessUserDelete() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.userDelete, {
    onSuccess: (data) => {
      // console.log('useBusinessUserCreate data => ', data);
      // if (!data.token) {
      //     setServerError('error-credential-wrong');
      //     return;
      // }
      // setToken(data.token);
      // closeModal();
    },
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

//business_branch_location_find_address_show
export function useBusinessBranchLocationFindAddress() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.branchLocationFindAddress,
    {
      onSuccess: (data) => {
        // console.log('useBusinessUserCreate data => ', data);
        // if (!data.token) {
        //     setServerError('error-credential-wrong');
        //     return;
        // }
        // setToken(data.token);
        // closeModal();
      },
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessBranchLocationCreate() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.branchLocationCreate,
    {
      onSuccess: (data) => {
        // console.log('useBusinessUserCreate data => ', data);
        // if (!data.token) {
        //     setServerError('error-credential-wrong');
        //     return;
        // }
        // setToken(data.token);
        // closeModal();
      },
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessBranchLocationList() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.branchLocationList,
    {
      onSuccess: (data) => {
        // console.log('useBusinessUserCreate data => ', data);
        // if (!data.token) {
        //     setServerError('error-credential-wrong');
        //     return;
        // }
        // setToken(data.token);
        // closeModal();
      },
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessBranchLocationDeleteItem() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.branchLocationDeleteItem,
    {
      onSuccess: (data) => {
        // console.log('useBusinessUserCreate data => ', data);
        // if (!data.token) {
        //     setServerError('error-credential-wrong');
        //     return;
        // }
        // setToken(data.token);
        // closeModal();
      },
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessDocumentsCreate() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.documentCreate, {
    onSuccess: (data) => {
      // console.log('useBusinessUserCreate data => ', data);
      // if (!data.token) {
      //     setServerError('error-credential-wrong');
      //     return;
      // }
      // setToken(data.token);
      // closeModal();
    },
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessDocumentsList() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.documentList, {
    onSuccess: (data) => {
      // console.log('useBusinessUserCreate data => ', data);
      // if (!data.token) {
      //     setServerError('error-credential-wrong');
      //     return;
      // }
      // setToken(data.token);
      // closeModal();
    },
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessDocumentDelete() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.documentDelete, {
    onSuccess: (data) => {
      // console.log('useBusinessUserCreate data => ', data);
      // if (!data.token) {
      //     setServerError('error-credential-wrong');
      //     return;
      // }
      // setToken(data.token);
      // closeModal();
    },
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessLogoCreate() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.logoCreate, {
    onSuccess: (data) => {
      // console.log('useBusinessUserCreate data => ', data);
      // if (!data.token) {
      //     setServerError('error-credential-wrong');
      //     return;
      // }
      // setToken(data.token);
      // closeModal();
    },
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessLogoList() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.logoList, {
    onSuccess: (data) => {
      // console.log('useBusinessUserCreate data => ', data);
      // if (!data.token) {
      //     setServerError('error-credential-wrong');
      //     return;
      // }
      // setToken(data.token);
      // closeModal();
    },
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessLogoDelete() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.logoDelete, {
    onSuccess: (data) => {
      // console.log('useBusinessUserCreate data => ', data);
      // if (!data.token) {
      //     setServerError('error-credential-wrong');
      //     return;
      // }
      // setToken(data.token);
      // closeModal();
    },
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessServiceTypeForBusiness() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.BusinessTypeForBusiness,
    {
      onSuccess: (data) => {},
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessSectorShow() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.sectorShow, {
    onSuccess: (data) => {},
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessJobCategories() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.jobCategories, {
    onSuccess: (data) => {},
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessJobTitle() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.jobTitle, {
    onSuccess: (data) => {},
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessBusinessSectorShow() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.businessSectorShow,
    {
      onSuccess: (data) => {},
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessBusinessSectorCreate() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.businessSectorCreate,
    {
      onSuccess: (data) => {},
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementPolicyList() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.getProductManagementPolicyList,
    {
      onSuccess: (data) => {
        // if (!data.token) {
        //     setServerError('error-credential-wrong');
        //     return;
        // }
        // setToken(data.token);
        // closeModal();
      },
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementPolicyTypeList() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.getProductManagementPolicyTypeList,
    {
      onSuccess: (data) => {
        // if (!data.token) {
        //     setServerError('error-credential-wrong');
        //     return;
        // }
        // setToken(data.token);
        // closeModal();
      },
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementPolicyTextCrete() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.createProductManagementPolicyText,
    {
      onSuccess: (data) => {
        // if (!data.token) {
        //     setServerError('error-credential-wrong');
        //     return;
        // }
        // setToken(data.token);
        // closeModal();
      },
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementPolicyTextGetById() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.getProductManagementPolicyTextById,
    {
      onSuccess: (data) => {
        // if (!data.token) {
        //     setServerError('error-credential-wrong');
        //     return;
        // }
        // setToken(data.token);
        // closeModal();
      },
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementPolicyCrete() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.createProductManagementPolicy,
    {
      onSuccess: (data) => {
        // if (!data.token) {
        //     setServerError('error-credential-wrong');
        //     return;
        // }
        // setToken(data.token);
        // closeModal();
      },
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementPolicyDelete() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.deleteProductManagementPolicy,
    {
      onSuccess: (data) => {
        // if (!data.token) {
        //     setServerError('error-credential-wrong');
        //     return;
        // }
        // setToken(data.token);
        // closeModal();
      },
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementProductConditionList() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.productCondition, {
    onSuccess: (data) => {
      // console.log('useBusinessUserCreate data => ', data);
      // if (!data.token) {
      //     setServerError('error-credential-wrong');
      //     return;
      // }
      // setToken(data.token);
      // closeModal();
    },
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementDeliveryTypeList() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.deliveryTypeList, {
    onSuccess: (data) => {
      // console.log('useBusinessUserCreate data => ', data);
      // if (!data.token) {
      //     setServerError('error-credential-wrong');
      //     return;
      // }
      // setToken(data.token);
      // closeModal();
    },
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementMarketPlaceList() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.marketplaceShow, {
    onSuccess: (data) => {},
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementCategoryList() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.business.category, {
    onSuccess: (data) => {},
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessProfileByIdShow() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.businessProfileByIdShow,
    {
      onSuccess: (data) => {},
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessProfileCreateOrUpdate() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.businessProfileCreateOrUpdate,
    {
      onSuccess: (data) => {},
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useProductManagementProfileUrlAvailability() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.profileUrlAvailability,
    {
      onSuccess: (data) => {},
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessInPersonServicesCreate() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.businessInPersonServiceCreate,
    {
      onSuccess: (data) => {},
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessInPersonServicesGridShow() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.businessInPersonServiceGridShow,
    {
      onSuccess: (data) => {},
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessInPersonServicesDelete() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.businessInPersonServiceDeleteItem,
    {
      onSuccess: (data) => {},
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessInPersonServicesByIdShow() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.business.businessInPersonServiceByIdShow,
    {
      onSuccess: (data) => {},
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}
