import BackgroundImageV3 from "../../assets/lang-bg-03.png";

export default function Slider() {
    const getTitle = (value: string) => {
        return (
            <h1
                className="font-serif text-5xl text-black font-extrabold tracking-tight mb-4 stroke-1"
                style={{
                    WebkitTextStrokeWidth: "2px",
                    WebkitTextStrokeColor: "#C09B19",
                }}
            >
                {value}
            </h1>
        );
    };

    return (
        <div
            style={{
                minHeight: "600px",
                // backgroundColor: "red",
            }}
            className="w-full"
        >
            <img
                src={BackgroundImageV3}
                alt="landing"
                // className="object-fill"
                style={{
                    width: "100%",
                    height: "auto",
                }}
            />

            <div
                style={{
                    position: "absolute",
                    left: 0,
                    top: 100,
                    width: "100%",
                }}
                className="px-10 py-40"
            >
                {getTitle(`Welcome to Milborak:`)}
                {getTitle(`Your Global Business Network`)}

                <div className="py-1 backdrop-filter backdrop-blur-sm w-max">
                    <span className="text-2xl text-yellow-300 font-semibold text-justify  whitespace-pre leading-loose">{`
Transform and expand your business with our comprehensive platform 
Showcase your online products and In-person services in a personalized
online space that reflects your unique identify Enjoy the advantages Credit card.
competitive pricing, advanced analytics and exclusive discounts.
`}</span>
                    <br />
                </div>

                <h1
                    className="font-serif text-4xl text-black font-extrabold tracking-tight mb-4 mt-8 stroke-1"
                    style={{
                        WebkitTextStrokeWidth: "1px",
                        WebkitTextStrokeColor: "#C09B19",
                    }}
                >{`Join us - Your business prosperity is our priority!`}</h1>
            </div>
            {/* <div
                className="pt-8"
                style={{
                    position: "absolute",
                    left: 200,
                    // top: 200,
                    // backgroundColor: "red",
                    width: "80%",
                    // height: "400px",
                }}
            >
                <Label>
                    {"⚠️ Important Notice: Limited Availability of Services for UK Retail Clients"}
                </Label>
                <Label>
                    {
                        "Please be informed that certain services offered on this platform are not accessible to Retail Clients based in the UK. Our commitment to regulatory compliance meansthat certain offerings are reserved exclusively for UK Professional Clients."
                    }
                </Label>
            </div> */}
        </div>
    );
}
