import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AuthResponse, Permission, RegisterUserInput } from "../../types";
import { useRegister } from "../../service/hook/user";
import Input from "../ui/forms/input";
import PasswordInput from "../ui/forms/password-input";
import Button from "../ui/button";
import Alert from "../ui/alert";
import { Link, useNavigate } from "react-router-dom";
import { Routes } from "../../config/routes";
import Form from "../ui/forms/form";

const registrationFormSchema = yup.object().shape({
    first_name: yup.string().required("form:error-name-required"),
    last_name: yup.string().required("form:error-name-required"),
    email: yup.string().email("form:error-email-format").required("form:error-email-required"),
    password: yup.string().required("form:error-password-required"),
});

const RegistrationForm = () => {
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { mutate, isLoading, formError } = useRegister();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<RegisterUserInput>({
        resolver: yupResolver(registrationFormSchema),
    });
    // const router = useRouter();
    const { t } = useTranslation();

    function onSubmit({ first_name, last_name, email, password }: RegisterUserInput) {
        mutate(
            {
                first_name,
                last_name,
                email,
                password,
            },
            {
                onSuccess: async (successData: AuthResponse) => {
                    if (
                        successData.final_result_code === "000" &&
                        successData.general_result === "user created"
                    ) {
                        if (!successData.captured_data.email_confirmed) {
                            navigate(Routes.login);
                        }
                    }
                },
            }
        );
    }

    return (
        <>
            <Form<RegisterUserInput> onSubmit={onSubmit} validationSchema={registrationFormSchema}>
                {({ register, formState: { errors } }) => (
                    <>
                        <Input
                            label={t("form:input-label-first-name")}
                            {...register("first_name")}
                            variant="outline"
                            className="mb-5"
                            error={t(errors.first_name?.message!)}
                        />
                        <Input
                            label={t("form:input-label-last-name")}
                            {...register("last_name")}
                            variant="outline"
                            className="mb-5"
                            error={t(errors.last_name?.message!)}
                        />
                        <Input
                            label={t("form:input-label-email")}
                            {...register("email")}
                            type="email"
                            variant="outline"
                            className="mb-5"
                            error={t(errors.email?.message!)}
                        />
                        <PasswordInput
                            label={t("form:input-label-password")}
                            {...register("password")}
                            error={t(errors.password?.message!)}
                            variant="outline"
                            className="mb-5"
                        />
                        <div className="mt-8">
                            <Button
                                className="h-12 w-full"
                                loading={isLoading}
                                disabled={isLoading}
                            >
                                {t("form:text-register")}
                            </Button>
                        </div>
                    </>
                )}
            </Form>

            <div className="relative mt-8 mb-6 flex flex-col items-center justify-center text-sm text-heading sm:mt-11 sm:mb-8">
                <hr className="w-full" />
                <span className="start-2/4 -ms-4 absolute -top-2.5 bg-light px-2">
                    {t("common:text-or")}
                </span>
            </div>
            <div className="text-center text-sm text-body sm:text-base">
                {t("form:text-already-account")}{" "}
                <Link
                    to={Routes.login}
                    className="ms-1 font-semibold text-accent underline transition-colors duration-200 hover:text-accent-hover hover:no-underline focus:text-accent-700 focus:no-underline focus:outline-none"
                >
                    {t("form:button-label-login")}
                </Link>
            </div>
        </>
    );
};

export default RegistrationForm;
