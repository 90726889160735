import { Table } from "../ui/table";
import { useTranslation } from "react-i18next";
import { MappedPaginatorInfo, SortOrder, ProductManagementImage } from "../../types";
import { useState } from "react";
import Pagination from "../ui/pagination";
import { EditIcon } from "../icons/edit copy";
import { TrashIcon } from "../icons/trash";
import { useModalAction } from "../../provider/modal.context";

export interface IImageSelected extends ProductManagementImage {}

export type IProps = {
    imageList: IImageSelected[] | undefined;
    paginatorInfo: MappedPaginatorInfo | null;
    onPagination: (current: number) => void;
    onSort: (current: any) => void;
    onOrder: (current: string) => void;
    onDeleteItem: (item: IImageSelected) => void;
};

type SortingObjType = {
    sort: SortOrder;
    column: string | null;
};

const AddInventoryVideoList = ({
    imageList,
    paginatorInfo,
    onPagination,
    onSort,
    onOrder,
    onDeleteItem,
}: IProps) => {
    const { t } = useTranslation();

    const { openModal } = useModalAction();

    const alignLeft = "left";
    const alignRight = "right";

    const [sortingObj, setSortingObj] = useState<SortingObjType>({
        sort: SortOrder.Desc,
        column: null,
    });

    let columns = [
        {
            title: t("table:table-item-file-name"),
            dataIndex: "file_name",
            key: "file_name",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (name: string) => {
                return <span className="truncate whitespace-nowrap">{name ?? "-"}</span>;
            },
        },
        {
            title: "Preview",
            className: "cursor-pointer",
            dataIndex: "_url",
            key: "_url",
            align: alignRight,
            width: 50,
            render: (url: string) => {
                return (
                    <span
                        onClick={() => {
                            openModal("ProductManagement_Image_Or_Video_Preview", {
                                item: { url },
                                callback: () => {},
                            });
                        }}
                        className="truncate whitespace-nowrap"
                        style={{
                            cursor: "pointer",
                        }}
                    >
                        {"Preview"}
                    </span>
                );
            },
        },
        {
            title: t("table:table-item-action"),
            className: "cursor-pointer",
            dataIndex: "action",
            key: "action",
            align: alignRight,
            width: 50,
            render: function Render(value: number, record: IImageSelected) {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "end",
                        }}
                    >
                        <button
                            form="deleteItemForm"
                            onClick={() => {
                                onDeleteItem(record);
                            }}
                            className="text-red-500 transition duration-200 hover:text-red-600 focus:outline-none"
                            title={t("common:text-delete")}
                        >
                            <TrashIcon width={16} />
                        </button>
                        {/* <div
                            style={{
                                width: "20px",
                            }}
                        />
                        <button
                            onClick={() => {
                                onDeleteItem(record);
                            }}
                            className="text-body transition duration-200 hover:text-heading focus:outline-none"
                            title={t("common:text-edit")}
                        >
                            <EditIcon width={16} />
                        </button> */}
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
                <Table
                    /* @ts-ignore */
                    columns={columns}
                    emptyText={t("table:empty-table-data")}
                    data={imageList}
                    rowKey="id"
                    // scroll={{ x: 900 }}
                />
            </div>

            {!!paginatorInfo?.total && (
                <div className="flex items-center justify-end">
                    <Pagination
                        total={paginatorInfo.total}
                        current={paginatorInfo.currentPage}
                        pageSize={paginatorInfo.perPage}
                        onChange={onPagination}
                        showLessItems
                    />
                </div>
            )}
        </>
    );
};

export default AddInventoryVideoList;
