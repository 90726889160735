import * as yup from 'yup';

export const teamMemberValidationSchema = yup.object(
  {
    email: yup.string().email('form:error-invalid-email').required('form:error-email-required-2'),
    mobile: yup.string(),
    isBuyer: yup.boolean(),
    isAdmin: yup.boolean(),
    isTech: yup.boolean(),
    isFinance: yup.boolean(),
  }
);