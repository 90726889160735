import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Label from "../ui/label";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import { useTranslation } from "react-i18next";
import { BankCardCreate, BankCardListResponse, Category, SortOrder } from "../../types";
import Input from "../ui/forms/input";
import Button from "../ui/button";
import Checkbox from "../ui/checkbox/checkbox";
import {
    useBusinessFinanceBankCardCreate,
    useBusinessFinanceBankCardDelete,
    useBusinessFinanceBankCardList,
} from "../../service/hook/businessFinance";
import { toast } from "react-toastify";
import CardBankList, { ICardBank } from "./card-bank-list";
import { userStore } from "../../store";
import { cardBankValidationSchema } from "./dard-bank-validation-schema";

type FormValues = {
    cardName: string;
    cardNumber: string;
    expireDateMonth: string;
    expireDateYear: string;
    cvv: string;
    cardType: string;
    isSecureCardData: boolean;
    nameOfCard: string;
};

const defaultValues = {
    cardName: "",
    cardNumber: "",
    isSecureCardData: false,
    nameOfCard: "",
    expireDateMonth: "",
    expireDateYear: "",
    cvv: "",
    cardType: "",
};

const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
        async (data: any) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors: any) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors: any, currentError: any) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

type IProps = {
    initialValues?: Category | undefined;
};
export default function CreateOrUpdateBusinessCardBankForm({ initialValues }: IProps) {
    const { t } = useTranslation();
    const { businessItem } = userStore((state) => state);
    const [bankCardList, setBankCardList] = useState<any[]>();
    const [page, setPage] = useState(1);
    const [orderBy, setOrder] = useState("created_at");
    const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);
    const [visible, setVisible] = useState(false);

    const resolver = useYupValidationResolver(cardBankValidationSchema);

    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        resolver,
    });

    const {
        mutate: mutateBusinessFinanceBankCardCreate,
        isLoading: isLoadingBusinessFinanceBankCardCreate,
        serverError: serverErrorBusinessFinanceBankCardCreate,
        setServerError: setServerErrorErrorBusinessFinanceBankCardCreate,
    } = useBusinessFinanceBankCardCreate();

    const {
        mutate: mutateBusinessFinanceBankCardList,
        isLoading: isLoadingBusinessFinanceBankCardList,
        serverError: serverErrorBusinessFinanceBankCardList,
        setServerError: setServerErrorErrorBusinessFinanceBankCardList,
    } = useBusinessFinanceBankCardList();

    const {
        mutate: mutateBusinessFinanceBankCardDelete,
        isLoading: isLoadingBusinessFinanceBankCardDelete,
        serverError: serverErrorBusinessFinanceBankCardDelete,
        setServerError: setServerErrorErrorBusinessFinanceBankCardDelete,
    } = useBusinessFinanceBankCardDelete();

    useEffect(() => {
        getBankCardList();
    }, [businessItem]);

    const getBankCardList = () => {
        mutateBusinessFinanceBankCardList(
            {
                businessdetail_id: `${businessItem?.business_id ?? 0}`,
            },
            {
                onSuccess: (successData: BankCardListResponse) => {
                    if (successData.final_result_code === "000") {
                        // try {
                        //     setBankCardList(successData.captured_data ?? []);
                        // } catch (err) {
                        //     console.log("err => ", err);
                        // }
                    } else {
                    }
                },
                onError: (error: Error) => {
                    const {
                        response: { data },
                    }: any = error ?? {};

                    toast.error(data?.general_result);
                    // closeModal();
                },
            }
        );
    };

    function handlePagination(current: any) {
        setPage(current);
    }

    const bankCardDeleteItem = (item: ICardBank) => {
        mutateBusinessFinanceBankCardDelete(
            {
                businessdetail_id: `${businessItem?.business_id ?? 0}`,
                bank_card_id: "1",
            },
            {
                onSuccess: (successData: any) => {
                    if (successData.final_result_code === "000") {
                        try {
                            toast.success(successData.general_result);
                            getBankCardList();
                        } catch (err) {
                            console.log("err => ", err);
                        }
                    } else {
                    }
                },
                onError: (error: Error) => {
                    const {
                        response: { data },
                    }: any = error ?? {};

                    toast.error(data?.general_result);
                    // closeModal();
                },
            }
        );
    };

    const onSubmit = async (values: FormValues) => {
        const input: BankCardCreate = {
            businessdetail_id: `${businessItem?.business_id ?? 0}`,
            name_on_card: values.cardName ?? "",
            card_number: values.cardNumber ?? "",
            exprired_year: `${values.expireDateYear}`,
            exprired_month: `${values.expireDateMonth}`,
            cvc_cvv: `${values.cvv}`,
            card_type: `${values.cardType}`,
            card_alias_name: values.nameOfCard ?? "",
        };

        mutateBusinessFinanceBankCardCreate(input, {
            onSuccess: (successData: BankCardListResponse) => {
                if (successData.final_result_code === "000") {
                    toast.success(successData?.general_result);
                    reset(defaultValues);
                } else {
                    toast.error(successData?.general_result);
                }
            },
            onError: (error: Error) => {
                const {
                    response: { data },
                }: any = error ?? {};

                toast.error(data?.general_result);
                // closeModal();
            },
        });
    };

    const isSecureCardData = watch("isSecureCardData");

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        title={t("form:form-title-business-card-bank-list")}
                        details={
                            [
                                // t("form:form-description-business-detail-01"),
                                // t("form:form-description-business-detail-02"),
                                // t("form:form-description-business-detail-05"),
                            ]
                        }
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />

                    <Card className="w-full sm:w-8/12 md:w-2/3">
                        <CardBankList
                            cardBankList={bankCardList}
                            paginatorInfo={null}
                            onPagination={handlePagination}
                            onOrder={setOrder}
                            onSort={setColumn}
                            onDeleteItem={(item: ICardBank) => {
                                bankCardDeleteItem(item);
                            }}
                        />
                    </Card>
                </div>

                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        title={t("form:form-title-business-card-bank")}
                        details={[]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />
                    <Card className="w-full sm:w-8/12 md:w-2/3">
                        <Input
                            label={t("form:input-label-name-on-card")}
                            {...register("cardName")}
                            error={t(errors.cardName?.message!)}
                            variant="outline"
                            className="mb-5"
                        />
                        <Input
                            label={t("form:input-label-card-number")}
                            {...register("cardNumber")}
                            error={t(errors.cardNumber?.message!)}
                            variant="outline"
                            className="mb-5"
                        />
                        <Label
                            style={{
                                cursor: "pointer",
                                // fontSize: "1.2rem",
                            }}
                            className="sm:py-2 mt-2"
                        >
                            {`${t("form:input-label-expire-date")}`}
                        </Label>
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                // justifyContent: "space-between",
                            }}
                        >
                            <Input
                                // label={t("form:input-label-card-number")}
                                {...register("expireDateMonth")}
                                error={t(errors.expireDateMonth?.message!)}
                                variant="outline"
                                className="mb-5"
                                placeholder="MM"
                                maxLength={2}
                                style={
                                    {
                                        // width: "40%",
                                    }
                                }
                            />
                            <div
                                style={{
                                    width: 10,
                                }}
                            />
                            <Input
                                // label={t("form:input-label-card-number")}
                                {...register("expireDateYear")}
                                error={t(errors.expireDateYear?.message!)}
                                variant="outline"
                                className="mb-5"
                                placeholder="YYYY"
                                maxLength={4}
                                style={
                                    {
                                        // width: "100%",
                                    }
                                }
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                            }}
                        >
                            <Input
                                label={t("form:input-label-cvc-cvv")}
                                {...register("cvv")}
                                error={t(errors.cvv?.message!)}
                                variant="outline"
                                className="mb-5"
                                maxLength={6}
                            />
                            <div
                                style={{
                                    width: 10,
                                }}
                            />
                            <Input
                                label={t("form:input-label-card-type")}
                                {...register("cardType")}
                                error={t(errors.cardType?.message!)}
                                variant="outline"
                                className="mb-5"
                            />
                        </div>
                        <Checkbox
                            {...register("isSecureCardData")}
                            label={t("form:form-label-secure-card-data")}
                            className="mb-5"
                        />
                        <Label
                            style={{
                                cursor: "pointer",
                                // fontSize: "1.2rem",
                            }}
                            className="sm:py-2"
                        >
                            {`${t("form:input-label-card-info-01")}`}
                        </Label>
                        <Input
                            label={t("form:input-label-name-of-card")}
                            {...register("nameOfCard")}
                            error={t(errors.cardNumber?.message!)}
                            variant="outline"
                            className="mb-5"
                            disabled={!isSecureCardData}
                        />
                    </Card>
                </div>

                <div className="mb-4 text-end">
                    <Button
                        loading={
                            isLoadingBusinessFinanceBankCardCreate ||
                            isLoadingBusinessFinanceBankCardDelete
                        }
                        disabled={!isSecureCardData}
                    >
                        {t("form:button-label-submit")}
                    </Button>
                </div>
            </form>
        </>
    );
}
