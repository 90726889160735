import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Navbar from "../navigation/top-navbar";
import MobileNavigation from "../navigation/mobile-navigation";
import { siteSettings } from "../../../settings/site.settings";
import SidebarItem from "../navigation/sidebar-item";
import pubStore from "../../../store/pubStore";
import { authStore, userStore } from "../../../store";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../config/routes";
import { useUser } from "../../../service/hook/user";
import { UserShowResponse } from "../../../types";
import Footer from "../../landing/footer";
import Header from "../../landing/header";

const BaseLayout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { t } = useTranslation("common");
    const navigate = useNavigate();

    const { mutate: getUserMutate, isLoading: userIsLoading } = useUser();

    const [sidebarItems, setSidebarItems] = useState(siteSettings.sidebarLinks.admin);

    const { user, setUser } = userStore((state) => state);
    const { accessToken, email, isAuthorized, setAccessToken, setAuthorized } = authStore(
        (state) => state
    );
    const {
        menuItemIndexLastSelected,
        setMenuItemIndexLastSelected,
        subMenuItemIndexLastSelected,
        setSubMenuItemIndexLastSelected,
    } = pubStore((state) => state);

    useEffect(() => {
        if (accessToken !== "" && isAuthorized) {
            if (user === null) {
                getUserInfo(email);
            }
        } else {
            console.log("accessToken else for wipe data ");
            console.log("wipe data");
            wipeData();
        }
    }, [accessToken, user]);

    const wipeData = () => {
        localStorage.clear();
        sessionStorage.clear();
        setAuthorized(false);
        setAccessToken("");
        navigate(Routes.login);
    };

    const getUserInfo = (email: string) => {
        getUserMutate(
            {
                email,
            },
            {
                onSuccess: (data: UserShowResponse) => {
                    // if (!data.captured_data.email_confirmed) {
                    //     navigate(Routes.verifyEmail, { replace: true });
                    // }

                    setUser({
                        firstName: data.captured_data.first_name,
                        lastName: data.captured_data.last_name,
                        email: data.captured_data.email,
                        isConfirmEmail: data.captured_data.email_confirmed,
                    });
                },
            }
        );
    };

    const SidebarItemMap = () => (
        <Fragment>
            {sidebarItems.map((item: any, index: number) => {
                return (
                    <SidebarItem
                        itemIndex={index}
                        href={item.href === "#" ? undefined : item.href}
                        label={t(item.label)}
                        icon={item.icon}
                        key={item.href}
                        children={item.children}
                        selected={menuItemIndexLastSelected === index}
                        selectedChildren={subMenuItemIndexLastSelected}
                        onClickCallBack={(callbackItem: any) => {
                            if (!callbackItem) {
                                if (menuItemIndexLastSelected !== index)
                                    setMenuItemIndexLastSelected(index);
                            } else {
                                setSubMenuItemIndexLastSelected(
                                    `${callbackItem.pIndex}|${callbackItem.cIndex}`
                                );
                            }
                        }}
                    />
                );
            })}
        </Fragment>
    );

    return (
        <div>
            <Header />
            <div
                className="flex min-h-screen flex-col bg-gray-100 transition-colors duration-150"
                dir={"ltr"}
            >
                {/* <Navbar />
                <MobileNavigation>
                    <SidebarItemMap />
                </MobileNavigation> */}
                <div className="flex flex-1 pt-20">
                    {/* <aside className="xl:w-76 fixed bottom-0 hidden h-full w-72 overflow-y-auto bg-white px-4 pt-22 shadow ltr:left-0 ltr:right-auto rtl:right-0 rtl:left-auto lg:block">
                        <div className="flex flex-col space-y-6 py-3">
                            <SidebarItemMap />
                        </div>
                    </aside> */}
                    <main
                    // className="ltr:xl:pl-76 rtl:xl:pr-76 w-full ltr:lg:pl-72 rtl:lg:pr-72 rtl:lg:pl-0"
                    >
                        <div className="h-full">{children}</div>
                    </main>
                </div>
            </div>
            <Footer />
            {/* <div
                className=""
                style={{
                    bottom: "-20rem",
                }}
            >
                <Footer />
            </div> */}
        </div>
    );
};
export default BaseLayout;
