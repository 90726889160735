export const Bookshelf: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48.75"
      height="60"
      viewBox="0 0 48.75 60"
      fill="currentColor"
      {...props}
    >
      <path
        id="Book_self"
        data-name="Book self"
        d="M228.875,176h-45A1.874,1.874,0,0,0,182,177.875v52.5a1.874,1.874,0,0,0,1.875,1.875h3.75V236H189.5v-3.75h33.75V236h1.875v-3.75h3.75a1.874,1.874,0,0,0,1.875-1.875v-52.5A1.874,1.874,0,0,0,228.875,176Zm-23.437,54.375h-2.812v-11.25H200.75v11.25h-1.875v-11.25H197v11.25h-1.875v-11.25H193.25v11.25h-1.875v-11.25H189.5v11.25h-1.875v-11.25H185.75v11.25h-1.875V213.5h21.563Zm0-18.75h-6.562v-11.25H197v11.25h-1.875v-11.25H193.25v11.25h-1.875v-11.25H189.5v11.25h-1.875v-11.25H185.75v11.25h-1.875V194.75h21.563Zm0-18.75h-6.452l1.764-.6-3.6-10.658-1.777.6,3.6,10.654h-3.848v-11.25H193.25v11.25h-1.875v-11.25H189.5v11.25h-1.875v-11.25H185.75v11.25h-1.875v-15h21.563Zm23.438,37.5h-7.5v-11.25H219.5v11.25H212v-11.25h-1.875v11.25h-2.812V213.5h21.563Zm0-18.75h-5.5l1.752-.592-3.6-10.658-1.777.6,3.6,10.65H219.5V200.379h-1.875v11.246H215.75V200.379h-1.875v11.246H212V200.379h-1.875v11.246h-2.812V194.75h21.563Zm0-18.75H227v-11.25h-1.875v11.25H223.25v-11.25h-1.875v11.25H219.5v-11.25h-1.875v11.25H215.75v-11.25h-1.875v11.25H212v-11.25h-1.875v11.25h-2.812v-15h21.563Z"
        transform="translate(-182 -176)"
        fill="#212121"
      />
    </svg>
  );
};
