import { useCallback, useEffect, useState } from "react";
import { Control, FieldErrors, useForm } from "react-hook-form";
import Label from "../ui/label";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import ValidationError from "../ui/form-validation-error";
import { useTranslation } from "react-i18next";
import SelectInput from "../ui/select-input";
import {
    BusinessDetailByIdItem,
    BusinessDetailByIdListResponse,
    BusinessDetailCreate,
    BusinessDetailCreateResponse,
    BusinessDetailItem,
    BusinessDetailListResponse,
    BusinessDetailUpdate,
    BusinessDetailUpdateResponse,
    BusinessType,
    CountryItem,
    SortOrder,
} from "../../types";
import Input from "../ui/forms/input";
import Button from "../ui/button";
import Checkbox from "../ui/checkbox/checkbox";
import Radio from "../ui/radio/radio";
import CountryList from "../../utils/constants/country.json";
import {
    useBusinessDetailCreate,
    useBusinessDetailUpdate,
    useGetBusinessDetailRowItem,
    useUserBusinessDetailList,
} from "../../service/hook/business";
import { userStore } from "../../store";
import { toast } from "react-toastify";
import DetailList from "./detail-list";
import { getCountryItem } from "../../utils/helper";
import { businessSetupValidationSchema } from "./detail-validation-schema";

function CountryRegion({
    control,
    errors,
    defaultValue,
    onChangeValue,
}: {
    control: Control<FormValues>;
    errors: FieldErrors<FormValues>;
    defaultValue?: CountryItem;
    onChangeValue: (item: CountryItem) => void;
}) {
    const errorMessage: string = errors.country?.message as string;

    const { t } = useTranslation();
    return (
        <div className="mb-5">
            <Label>{t("form:input-label-country-region")}</Label>
            <SelectInput
                name="country"
                control={control}
                value={defaultValue}
                onChange={(value: CountryItem) => {
                    onChangeValue(value);
                }}
                getOptionLabel={(option: any) => option.CountryName}
                getOptionValue={(option: any) => option.Alpha3Code}
                options={CountryList!}
                isLoading={false}
            />
            <ValidationError message={errorMessage} />
        </div>
    );
}

type FormValues = {
    firstName?: string;
    lastName?: string;
    isReceiveUpdate?: boolean;
    businessMobilePhone?: string;
    businessName: string;
    type: string;
    vatNumber: string;
    country?: {
        Alpha3Code?: string;
        Alpha2Code?: string;
        CountryName?: string;
    };
};

const defaultValues = {
    firstName: "",
    lastName: "",
    isReceiveUpdate: false,
    businessMobilePhone: "",
    businessName: "",
    type: "",
    vatNumber: "",
};

const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
        async (data: any) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors: any) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors: any, currentError: any) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

export default function CreateOrUpdateBusinessDetailForm() {
    const { t } = useTranslation();
    const { user, businessItem, setRegisteredBusinessId } = userStore((state) => state);

    const [addOrEditMode, setAddOrEditMode] = useState<boolean>(false);

    const [detailList, setDetailList] = useState<BusinessDetailItem[]>();
    const [editItem, setEditItem] = useState<BusinessDetailByIdItem | null>();
    const [countryItem, setCountryItem] = useState<CountryItem | undefined>();

    const [page, setPage] = useState(1);
    const [orderBy, setOrder] = useState("created_at");
    const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);
    const [visible, setVisible] = useState(false);

    // for fill business detail grid list
    const {
        mutate: mutateBusinessDetailList,
        isLoading: isLoadingBusinessDetailList,
        serverError: serverErrorBusinessDetailList,
        setServerError,
    } = useUserBusinessDetailList();

    const {
        mutate: mutateBusinessGetRowItemDetail,
        isLoading: isLoadingBusinessGetRowItemDetail,
        serverError: serverErrorBusinessGetRowItemDetail,
        setServerError: setServerErrorBusinessGetRowItemDetail,
    } = useGetBusinessDetailRowItem();

    const {
        mutate: mutateBusinessDetailCreate,
        isLoading: isLoadingBusinessDetailCreate,
        serverError: serverErrorBusinessDetailCreate,
        setServerError: setServerErrorBusinessDetailCreate,
    } = useBusinessDetailCreate();

    const {
        mutate: mutateBusinessDetailUpdate,
        isLoading: isLoadingBusinessDetailUpdate,
        serverError: serverErrorBusinessDetailUpdate,
        setServerError: setServerErrorBusinessDetailUpdate,
    } = useBusinessDetailUpdate();

    function handlePagination(current: any) {
        setPage(current);
    }

    const resolver = useYupValidationResolver(businessSetupValidationSchema);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        resolver,
    });

    useEffect(() => {
        getDetailList();
    }, [businessItem]);

    const getDetailList = () => {
        mutateBusinessDetailList(
            {
                businessdetail_id: `${businessItem?.business_id ?? 0}`,
            },
            {
                onSuccess: (successData: BusinessDetailListResponse) => {
                    //set list to state detailList
                    if (successData.final_result_code === "000") {
                        const list: BusinessDetailItem[] = [successData.captured_data];
                        setDetailList(list);
                    }
                },
            }
        );
    };

    const formReset = (item?: BusinessDetailByIdItem) => {
        if (item) {
            reset({
                isReceiveUpdate: item.accepted_to_get_updates_via_sms_email,
                businessName: item.business_name,
                type: item.business_type,
                vatNumber: item.vat_registration_number_tax,
            });
            getCountryItem(item.country ?? "", (country: CountryItem) => {
                setCountryItem(country);
                setValue("country", country);
            });
        } else {
            reset(defaultValues);
            setCountryItem(undefined);
        }
    };

    const editItemService = async (item: any) => {
        mutateBusinessGetRowItemDetail(
            {
                businessdetail_id: businessItem?.business_id ?? 0,
            },
            {
                onSuccess: (successData: BusinessDetailByIdListResponse) => {
                    //fill all field with below response
                    if (successData.final_result_code === "000") {
                        setAddOrEditMode(true);
                        const businessDetailByIdItem: BusinessDetailByIdItem =
                            successData.captured_data;
                        setEditItem(businessDetailByIdItem);
                        formReset({ ...businessDetailByIdItem });
                    }
                },
            }
        );
    };

    const onSubmit = async (values: FormValues) => {
        if (editItem) {
            const updateInput: BusinessDetailUpdate = {
                businessdetail_id: businessItem?.business_id ?? 0,
                business_name: values.businessName,
                country: values?.country?.Alpha3Code ?? "",
                business_initial_admin_user: user?.email ?? "",
                accepted_to_get_updates_via_sms_email: values.isReceiveUpdate ? "True" : "False",
                vat_registration_number_tax: values?.vatNumber ?? "",
                business_type: values?.type ?? "",
                email: user?.email ?? "",
                business_phone: "",
                first_name: "",
                last_name: "",
                group: "seller",
            };

            mutateBusinessDetailUpdate(updateInput, {
                onSuccess: (successData: BusinessDetailUpdateResponse) => {
                    if (successData.final_result_code === "000") {
                        try {
                            getDetailList();
                            setAddOrEditMode(!addOrEditMode);
                            setEditItem(null);
                            formReset();
                        } catch (err) {
                            console.log("err => ", err);
                        }

                        toast.success(successData.general_result);
                    } else {
                        toast.error(successData.general_result);
                    }
                },
                onError: (error: Error) => {
                    const {
                        response: { data },
                    }: any = error ?? {};

                    toast.error(data?.general_result);
                },
            });
        } else {
            const input: BusinessDetailCreate = {
                business_name: values.businessName,
                country: values?.country?.Alpha3Code ?? "",
                business_initial_admin_user: user?.email ?? "",
                accepted_to_get_updates_via_sms_email: values.isReceiveUpdate ?? false,
                vat_registration_number_tax: values?.vatNumber ?? "",
                business_type: values?.type ?? "",
                email: user?.email ?? "",
                business_phone: values.businessMobilePhone ?? "",
                first_name: values.firstName ?? "",
                last_name: values.lastName ?? "",
                group: "seller",
            };
            mutateBusinessDetailCreate(input, {
                onSuccess: (successData: BusinessDetailCreateResponse) => {
                    if (successData.final_result_code === "000") {
                        try {
                            const businessId = successData.Business_Id;
                            setRegisteredBusinessId(businessId);

                            getDetailList();
                            setAddOrEditMode(!addOrEditMode);
                            setEditItem(null);
                            formReset();
                        } catch (err) {
                            console.log("err => ", err);
                        }

                        toast.success(successData.general_result);
                    } else {
                        toast.error(successData.general_result);
                    }
                },
                onError: (error: Error) => {
                    const {
                        response: { data },
                    }: any = error ?? {};

                    toast.error(data?.general_result);
                },
            });
        }
    };

    return (
        <>
            <div className="my-5 flex flex-wrap sm:my-8">
                <Description
                    // title={t("form:form-title-business-detail")}
                    details={[
                        t("form:form-description-business-detail-01"),
                        t("form:form-description-business-detail-02"),
                        t("form:form-description-business-detail-05"),
                    ]}
                    className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                />

                <Card className="w-full sm:w-8/12 md:w-2/3">
                    <DetailList
                        detailList={detailList}
                        paginatorInfo={null}
                        onPagination={handlePagination}
                        onOrder={setOrder}
                        onSort={setColumn}
                        onEditItem={(item: BusinessDetailItem) => {
                            editItemService(item);
                        }}
                    />
                    <Button
                        onClick={() => {
                            setAddOrEditMode(!addOrEditMode);
                            setEditItem(null);
                            formReset();
                        }}
                        loading={
                            isLoadingBusinessDetailList ||
                            isLoadingBusinessGetRowItemDetail ||
                            isLoadingBusinessDetailUpdate
                        }
                        disabled={
                            isLoadingBusinessDetailList ||
                            isLoadingBusinessGetRowItemDetail ||
                            isLoadingBusinessDetailUpdate
                        }
                    >
                        {isLoadingBusinessDetailList || isLoadingBusinessGetRowItemDetail
                            ? t("form:button-label-waiting")
                            : !addOrEditMode
                            ? t("form:button-label-add")
                            : editItem
                            ? t("form:button-label-edit-cancel")
                            : t("form:button-label-cancel")}
                    </Button>
                </Card>
            </div>

            <form id={"detail-form"} onSubmit={handleSubmit(onSubmit)}>
                {addOrEditMode && (
                    <>
                        <div className="flex border-b border-dashed border-border-base py-5 sm:py-8" />
                        <div className="my-5 flex flex-wrap sm:my-8">
                            <Description
                                title={t("form:form-title-contact-info")}
                                details={[]}
                                className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                            />

                            <Card className="w-full sm:w-8/12 md:w-2/3">
                                <Input
                                    label={t("form:input-label-first-name")}
                                    {...register("firstName")}
                                    error={t(errors.firstName?.message!)}
                                    variant="outline"
                                    className="mb-5"
                                    disabled={!!editItem}
                                />
                                <Input
                                    label={t("form:input-label-last-name")}
                                    {...register("lastName")}
                                    error={t(errors.lastName?.message!)}
                                    variant="outline"
                                    className="mb-5"
                                    disabled={!!editItem}
                                />

                                <Checkbox
                                    {...register("isReceiveUpdate")}
                                    label={t("form:form-label-recieve-text-update-is-confirm")}
                                    className="mb-5"
                                />

                                <Input
                                    label={t("form:form-input-title-business-mobile-phone")}
                                    {...register("businessMobilePhone")}
                                    error={t(errors.lastName?.message!)}
                                    variant="outline"
                                    className="mb-5"
                                    disabled={!!editItem}
                                />
                            </Card>
                        </div>
                        <div className="flex border-b border-dashed border-border-base py-5 sm:py-8" />

                        <div className="my-5 flex flex-wrap sm:my-8">
                            <Description
                                title={t("form:form-title-business-info")}
                                details={[
                                    t("form:form-description-business-detail-03"),
                                    t("form:form-description-business-detail-04"),
                                ]}
                                className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                            />

                            <Card className="w-full sm:w-8/12 md:w-2/3">
                                <Input
                                    label={t("form:input-label-business-name")}
                                    {...register("businessName")}
                                    error={t(errors.businessName?.message!)}
                                    variant="outline"
                                    className="mb-5"
                                />

                                <CountryRegion
                                    control={control}
                                    errors={errors}
                                    defaultValue={countryItem}
                                    onChangeValue={setCountryItem}
                                />

                                <div className="mb-5">
                                    <Label>{t("form:input-label-business-type")}</Label>
                                    <div className="space-y-3.5">
                                        <Radio
                                            label={t("form:input-label-radio-sole-trader")}
                                            {...register("type")}
                                            id="fixed"
                                            value={BusinessType.SOLE_TRADER}
                                            // error={t(errors.type?.message!)}
                                        />
                                        <Radio
                                            label={t("form:input-label-radio-others")}
                                            {...register("type")}
                                            id="percentage"
                                            value={BusinessType.OTHERS}
                                            error={t(errors.type?.message!)}
                                        />
                                    </div>
                                </div>

                                <Input
                                    label={t("form:input-label-vat-number-optional")}
                                    {...register("vatNumber")}
                                    error={t(errors.vatNumber?.message!)}
                                    variant="outline"
                                    className="mb-5"
                                    maxLength={20}
                                />
                            </Card>
                        </div>

                        <div
                            className="mb-4 text-start"
                            style={{
                                display: "flex",
                                justifyContent: "end",
                            }}
                        >
                            {editItem && (
                                <Button
                                    id={"cancel-edit"}
                                    type="reset"
                                    onClick={() => {
                                        setAddOrEditMode(!addOrEditMode);
                                        setEditItem(null);
                                        formReset();
                                    }}
                                    className="mr-4"
                                    loading={
                                        isLoadingBusinessDetailList || isLoadingBusinessDetailUpdate
                                    }
                                    disabled={
                                        isLoadingBusinessDetailList || isLoadingBusinessDetailUpdate
                                    }
                                >
                                    {t("form:button-label-cancel")}
                                </Button>
                            )}

                            <Button
                                loading={
                                    isLoadingBusinessDetailList || isLoadingBusinessDetailUpdate
                                }
                                disabled={
                                    isLoadingBusinessDetailList || isLoadingBusinessDetailUpdate
                                }
                            >
                                {editItem
                                    ? t("form:button-label-update")
                                    : t("form:button-label-submit")}
                            </Button>
                        </div>
                    </>
                )}
            </form>
        </>
    );
}
