import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import client from "../client";

export function useAiAnalysisDescriptionGenerator() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.analysis.DescriptionGenerator, {
        onSuccess: (data) => {
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}