import { Table } from "../ui/table";
import { useTranslation } from "react-i18next";
import { MappedPaginatorInfo, SortOrder, BusinessInPersonServicesGridItem } from "../../types";
import { useState } from "react";
import Pagination from "../ui/pagination";
import { TrashIcon } from "../icons/trash";
import { Eye } from "../icons/eye-icon";
import { useNavigate } from "react-router-dom";
import { useModalAction } from "../../provider/modal.context";
// import { Eye } from "../icons/eye-icon";

export interface IProductOffer extends BusinessInPersonServicesGridItem {}

export type IProps = {
    offerList: IProductOffer[] | undefined;
    paginatorInfo: MappedPaginatorInfo | null;
    onPagination: (current: number) => void;
    onSort: (current: any) => void;
    onOrder: (current: string) => void;
    onDeleteItem: (item: IProductOffer) => void;
};

type SortingObjType = {
    sort: SortOrder;
    column: string | null;
};

const InPersonServiceList = ({
    offerList,
    paginatorInfo,
    onPagination,
    onSort,
    onOrder,
    onDeleteItem,
}: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { openModal } = useModalAction();

    const alignLeft = "left";
    const alignRight = "right";

    const [sortingObj, setSortingObj] = useState<SortingObjType>({
        sort: SortOrder.Desc,
        column: null,
    });

    let columns = [
        {
            title: "Row",
            dataIndex: "row",
            key: "row",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (value: string) => {
                return <span className="truncate whitespace-nowrap">{value ?? "-"}</span>;
            },
        },
        {
            title: "Service title",
            dataIndex: "service_title",
            key: "service_title",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (value: string) => {
                return <span className="truncate whitespace-nowrap">{value ?? "-"}</span>;
            },
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (value: string) => {
                return <span className="truncate whitespace-nowrap">{value ?? "-"}</span>;
            },
        },
        {
            title: "Action",
            className: "cursor-pointer",
            dataIndex: "action",
            key: "action",
            align: alignRight,
            width: 130,
            render: function Render(value: number, record: IProductOffer) {
                return (
                    <div
                        className="space-x-8"
                        style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "end",
                        }}
                    >
                        <button
                            onClick={() => {
                                openModal("InPersonServicesDetailsModal", {
                                    item: { inPersonServiceId: record.binp_srv_id },
                                    isPreview: true,
                                    callback: (item: any) => {
                                        // if (item?.refresh) {
                                        //     // getBusinessInPersonGrid();
                                        // }
                                    },
                                });
                            }}
                            className="text-body transition duration-200 hover:text-heading focus:outline-none"
                            title={t("common:text-edit")}
                        >
                            <Eye width={16} />
                        </button>

                        <button
                            onClick={() => {
                                onDeleteItem(record);
                            }}
                            className="text-red-500 transition duration-200 hover:text-red-600 focus:outline-none"
                            title={t("common:text-delete")}
                        >
                            <TrashIcon width={16} />
                        </button>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
                <Table
                    /* @ts-ignore */
                    columns={columns}
                    emptyText={t("table:empty-table-data")}
                    data={offerList}
                    rowKey="id"
                    // scroll={{ x: 900 }}
                />
            </div>

            {!!paginatorInfo?.total && (
                <div className="flex items-center justify-end">
                    <Pagination
                        total={paginatorInfo.total}
                        current={paginatorInfo.currentPage}
                        pageSize={paginatorInfo.perPage}
                        onChange={onPagination}
                        showLessItems
                    />
                </div>
            )}
        </>
    );
};

export default InPersonServiceList;
