import { Routes, Route } from "react-router-dom";
import { Routes as RoutesPath } from "../config/routes";
import AuthorizedRoute from "../components/AuthorizedRoute";
import InPersonServicesView from "../views/businessInPerson/inPersonServices";

const BusinessInPersonRoutes = () => {
    return (
        <Routes>
            <Route
                path={RoutesPath.BusinessInPersonServices}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <InPersonServicesView />
                    </AuthorizedRoute>
                }
            />
        </Routes>
    );
};

export default BusinessInPersonRoutes;
