import { useCallback, useEffect, useState } from "react";
import { Control, Controller, FieldErrors, FieldValues, useForm } from "react-hook-form";
import Label from "../ui/label";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import ValidationError from "../ui/form-validation-error";
import { useTranslation } from "react-i18next";
import SelectInput from "../ui/select-input";
import {
    AvailabilityTypeModel,
    CurrencyItem,
    ProductManagementCategoryItem,
    ProductManagementCategoryItemBase,
    ProductManagementCategoryListResponse,
    ProductManagementCategoryReq,
    ProductManagementImage,
    ProductManagementInventoryItem,
    ProductManagementMarketPlaceListItem,
    ProductManagementMarketPlaceListResponse,
    ProductManagementMarketPlaceReq,
    ProductManagementOfferCreateOrUpdateReq,
    ProductManagementOfferCreateOrUpdateResponse,
    ProductManagementPolicyItem,
    SortOrder,
    ProductManagementOfferDetailsReq,
    ProductManagementOfferDetailsResponse,
    ProductManagementOfferDetails,
} from "../../types";
import Input from "../ui/forms/input";
import Button from "../ui/button";
import {
    useProductManagementCategoryList,
    useProductManagementMarketPlaceList,
} from "../../service/hook/business";
import { userStore } from "../../store";
import { useModalAction } from "../../provider/modal.context";
import {
    useProductManagementOfferCreateOrUpdate,
    useProductManagementOfferDetails,
} from "../../service/hook/sector_1";
import { inventoryAddValidationSchema } from "./inventory-add-validation-schema";
import TextArea from "../ui/text-area";
import AddInventoryImageList from "./inventory-add-form-image-list";
import AddInventoryAspectsList from "./inventory-add-form-aspects-list";
import Checkbox from "../ui/checkbox/checkbox";
import CurrencyListItems from "../../utils/constants/currency.json";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker } from "../ui/date-picker";
import AddOfferPolicyList from "./offer-add-form-policy-list";

function MarketPlaceList({
    control,
    errors,
    items,
    isLoading,
    defaultValue,
    onChangeValue,
}: {
    control: Control<FormValues>;
    errors: FieldErrors<FormValues>;
    items: ProductManagementMarketPlaceListItem[];
    isLoading: boolean;
    defaultValue?: ProductManagementMarketPlaceListItem;
    onChangeValue: (item: ProductManagementMarketPlaceListItem) => void;
}) {
    const { t } = useTranslation();
    const errorMessage: string = errors.marketPlace?.message as string;

    return (
        <div className="mb-5 w-full">
            <Label>{"Marketplace"}</Label>
            <SelectInput
                name="marketPlace"
                control={control}
                value={defaultValue}
                onChange={(value: ProductManagementMarketPlaceListItem) => {
                    onChangeValue(value);
                }}
                getOptionLabel={(option: any) => `${option.marketplace_name}`}
                getOptionValue={(option: any) => option.marketplace_id}
                options={items!}
                isLoading={false}
            />
            <ValidationError message={t(errorMessage)} />
        </div>
    );
}

function CategoryLevel<T extends ProductManagementCategoryItemBase>({
    control,
    errors,
    items,
    defaultValue,
    onChangeValue,
    name,
    title,
}: {
    control: Control<FormValues>;
    errors: FieldErrors<FieldValues>;
    items: T[];
    defaultValue?: T;
    onChangeValue: (item: T) => void;
    name?: string;
    title?: string;
}) {
    const { t } = useTranslation();
    const errorMessage: string = errors[name ?? ""]?.message as string;

    return (
        <div className="mb-5 w-full">
            <Label>{title ?? ""}</Label>
            <SelectInput
                name={name ?? ""}
                control={control}
                value={defaultValue}
                onChange={(value: T) => {
                    onChangeValue(value);
                }}
                getOptionLabel={(option: any) => option.category_name}
                getOptionValue={(option: any) => option.category_id}
                options={items!}
                isLoading={false}
                className="min-h-max"
            />
            <ValidationError message={t(errorMessage)} />
        </div>
    );
}

function CurrencyList({
    control,
    errors,
    defaultValue,
    onChangeValue,
}: {
    control: Control<FormValues>;
    errors: FieldErrors<FormValues>;
    defaultValue?: CurrencyItem;
    onChangeValue: (item: CurrencyItem) => void;
}) {
    const errorMessage: string = errors.currency?.message as string;

    const { t } = useTranslation();
    return (
        <div className="mb-5">
            <Label>{"Currency"}</Label>
            <SelectInput
                name="currency"
                control={control}
                value={defaultValue}
                onChange={(value: CurrencyItem) => {
                    onChangeValue(value);
                }}
                getOptionLabel={(option: any) => option.Title}
                getOptionValue={(option: any) => option.CurrencyCode}
                options={CurrencyListItems!}
                isLoading={false}
            />
            <ValidationError message={errorMessage} />
        </div>
    );
}

type FormValues = {
    sku: string;
    marketPlace: any;
    productQuantity?: string;
    categoryLevel1: any;
    categoryLevel2: any;
    categoryLevel3: any;
    productPrice?: string;
    currency: any;
    description: string;
    listingStartDate: string;
    qntLimitPeyBuyer: string;
    repairScore: string;
    tax: string;
    isTax: boolean;
};

const defaultValues = {
    sku: "",
    description: "",
    listingStartDate: "",
    qntLimitPeyBuyer: "",
    repairScore: "",
    tax: "",
    isTax: false,
};

const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
        async (data: any) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors: any) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors: any, currentError: any) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

export default function CreateOrUpdateProductOfferDetailsForm({
    isEditMode,
}: {
    isEditMode?: boolean;
}) {
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation();
    const { user, businessItem } = userStore((state) => state);

    const { openModal } = useModalAction();

    const [marketPlaceList, setMarketPlaceList] = useState<ProductManagementMarketPlaceListItem[]>(
        []
    );
    const [selectedMarketPlace, setSelectedMarketPlace] = useState<
        ProductManagementMarketPlaceListItem | undefined
    >(undefined);

    const [categoryLvl1List, setCategoryLvl1List] = useState<ProductManagementCategoryItem[]>([]);
    const [selectedCategoryLvl1, setSelectedCategoryLvl1] = useState<
        ProductManagementCategoryItem | undefined
    >(undefined);

    const [categoryLvl2List, setCategoryLvl2List] = useState<ProductManagementCategoryItem[]>([]);
    const [selectedCategoryLvl2, setSelectedCategoryLvl2] = useState<
        ProductManagementCategoryItem | undefined
    >(undefined);

    const [categoryLvl3List, setCategoryLvl3List] = useState<ProductManagementCategoryItem[]>([]);
    const [selectedCategoryLvl3, setSelectedCategoryLvl3] = useState<
        ProductManagementCategoryItem | undefined
    >(undefined);

    const [currencyItem, setCurrencyItem] = useState<CurrencyItem | undefined>();

    const [selectedPolicyList, setSelectedPolicyList] = useState<ProductManagementPolicyItem[]>([]);

    const [startTime, setStartTime] = useState<Date>(new Date());

    const [energyLabelDescription, setEnergyLabelDescription] = useState<string>("");
    const [selectedProductImageList, setSelectedProductImageList] = useState<
        ProductManagementImage[]
    >([]);

    const [selectedOfferSignalWordsList, setSelectedOfferSignalWordsList] = useState<
        AvailabilityTypeModel[]
    >([]);

    const [selectedOfferStatementsList, setSelectedOfferStatementsList] = useState<
        AvailabilityTypeModel[]
    >([]);

    const [offerDetails, setOfferDetails] = useState<ProductManagementOfferDetails | undefined>();

    const [page, setPage] = useState(1);
    const [orderBy, setOrder] = useState("created_at");
    const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);
    const [visible, setVisible] = useState(false);

    function handlePagination(current: any) {
        setPage(current);
    }

    const {
        mutate: mutateProductManagementMarketPlaceList,
        isLoading: isLoadingProductMangementMarketPlaceList,
        serverError: serverErrorProductMangementMarketPlaceList,
        setServerError: setServerErrorProductMangementMarketPlaceList,
    } = useProductManagementMarketPlaceList();

    const {
        mutate: mutateProductManagementCategoryList,
        isLoading: isLoadingProductMangementCategoryList,
        serverError: serverErrorProductMangementCategoryList,
        setServerError: setServerErrorProductMangementCategoryList,
    } = useProductManagementCategoryList();

    const {
        mutate: mutateProductManagementOfferCreateOrUpdate,
        isLoading: isLoadingProductMangementOfferCreateOrUpdate,
        serverError: serverErrorProductMangementOfferCreateOrUpdate,
        setServerError: setServerErrorProductMangementOfferCreateOrUpdate,
    } = useProductManagementOfferCreateOrUpdate();

    const {
        mutate: mutateProductManagementOfferDetails,
        isLoading: isLoadingProductMangementOfferDetails,
        serverError: serverErrorProductMangementOfferDetails,
        setServerError: setServerErrorProductMangementOfferDetails,
    } = useProductManagementOfferDetails();

    const resolver = useYupValidationResolver(inventoryAddValidationSchema);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        reset,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        resolver,
    });

    useEffect(() => {
        getData();
    }, [businessItem]);

    useEffect(() => {
        const { offerId } = params;
        if (offerId) {
            if (marketPlaceList.length > 0) {
                setSelectedMarketPlace(
                    marketPlaceList.find(
                        (findItem) => findItem.marketplace_id === offerDetails?.marketplaceId
                    )
                );
            }
            if (categoryLvl1List.length > 0) {
                setSelectedCategoryLvl1(
                    categoryLvl1List.find(
                        (findItem) => findItem.category_id === offerDetails?.categoryId_level1
                    )
                );
            }
            if (categoryLvl2List.length > 0) {
                setSelectedCategoryLvl2(
                    categoryLvl2List.find(
                        (findItem) => findItem.category_id === offerDetails?.categoryId_level2
                    )
                );
            }
            if (categoryLvl3List.length > 0) {
                setSelectedCategoryLvl3(
                    categoryLvl3List.find(
                        (findItem) => findItem.category_id === offerDetails?.categoryId_level3
                    )
                );
            }
            if (marketPlaceList.length > 0 && categoryLvl1List.length > 0) {
                const { offerId } = params;
                if (offerId) {
                    getProductOfferItem(parseInt(offerId));
                }
            }
        }
    }, [marketPlaceList, categoryLvl1List, categoryLvl2List, categoryLvl3List]);

    useEffect(() => {
        if (selectedCategoryLvl1) {
            getCategoryList({
                level: 2,
                parent: selectedCategoryLvl1.category_id,
            });
        }
    }, [selectedCategoryLvl1]);

    useEffect(() => {
        if (selectedCategoryLvl2) {
            getCategoryList({
                level: 3,
                parent: selectedCategoryLvl2.category_id,
            });
        }
    }, [selectedCategoryLvl2]);

    const getData = async () => {
        await getMarketPlaceList();
        await getCategoryList({
            level: 1,
        });
    };

    const getMarketPlaceList = async (id?: string) => {
        const input: ProductManagementMarketPlaceReq = {};

        if (id) {
            input.marketplace_id = id;
        }

        await mutateProductManagementMarketPlaceList(input, {
            onSuccess: (successData: ProductManagementMarketPlaceListResponse) => {
                if (successData.final_result_code === "000") {
                    setMarketPlaceList(successData.captured_data ?? []);
                }
            },
        });
    };

    const getCategoryList = async (input: ProductManagementCategoryReq) => {
        await mutateProductManagementCategoryList(input, {
            onSuccess: (successData: ProductManagementCategoryListResponse) => {
                if (successData.final_result_code === "000") {
                    if (input.level === 1) {
                        setCategoryLvl1List(successData.captured_data ?? []);
                    } else if (input.level === 2) {
                        setCategoryLvl2List(successData.captured_data ?? []);
                    } else if (input.level === 3) {
                        setCategoryLvl3List(successData.captured_data ?? []);
                    }
                }
            },
        });
    };

    const getProductOfferItem = async (offerId: number) => {
        const input: ProductManagementOfferDetailsReq = {
            businessdetail_id: `${businessItem?.business_id ?? 0}`,
        };

        if (offerId) {
            input.offer_id = offerId;
        }

        await mutateProductManagementOfferDetails(input, {
            onSuccess: (successData: ProductManagementOfferDetailsResponse) => {
                if (successData.final_result_code === "000") {
                    setOfferDetails(successData.captured_data);
                    formReset(successData.captured_data);
                }
            },
        });
    };

    const formReset = (item?: ProductManagementOfferDetails) => {
        if (item) {
            reset({
                sku: item.sku_code,
                productPrice: item.price_value,
                isTax: item.tax.applyTax,
                tax: `${item.tax.vatPercentage ?? ""}`,
                listingStartDate: item.listing_StartDate,
                qntLimitPeyBuyer: item.quantityLimitPerBuyer,
                repairScore: item.regulatory.repairScore,
                productQuantity: `${item.product_available_Quantity ?? 0}`,
                description: item.listing_Description,
            });
            if (marketPlaceList.length > 0) {
                setSelectedMarketPlace(
                    marketPlaceList.find(
                        (findItem) => findItem.marketplace_id === item?.marketplaceId
                    )
                );
            }
            if (categoryLvl1List.length > 0) {
                setSelectedCategoryLvl1(
                    categoryLvl1List.find(
                        (findItem) => findItem.category_id === item?.categoryId_level1
                    )
                );
            }
            if (categoryLvl2List.length > 0) {
                setSelectedCategoryLvl2(
                    categoryLvl2List.find(
                        (findItem) => findItem.category_id === item?.categoryId_level2
                    )
                );
            }
            if (categoryLvl3List.length > 0) {
                setSelectedCategoryLvl3(
                    categoryLvl3List.find(
                        (findItem) => findItem.category_id === item?.categoryId_level3
                    )
                );
            }
            if (CurrencyListItems.length > 0) {
                setCurrencyItem(
                    CurrencyListItems.find(
                        (findItem) => findItem.CurrencyCode === item?.price_currency
                    )
                );
            }
            if (item.listing_StartDate) {
                const date = new Date(item.listing_StartDate);
                console.log("date => ", date);

                setStartTime(date);
            }
            if (item?.listingPolicies) {
                const policyKeys = Object.keys(item.listingPolicies) ?? [];
                const policyValues = Object.values(item.listingPolicies) ?? [];

                if (policyKeys.length > 0 && policyValues.length > 0)
                    setSelectedPolicyList(
                        policyKeys?.map((mapItem: string, index) => {
                            const item: ProductManagementPolicyItem = {
                                file_id: index,
                                file_name: `${policyValues[index]}`.substring(
                                    `${policyValues[index]}`.lastIndexOf("/") + 1
                                ),
                                file_urls: {
                                    url: "",
                                    title: "",
                                },
                                url_type: "",
                                policy_type: policyKeys[index],
                                _url: policyValues[index] as string,
                                selected: true,
                            };
                            return item;
                        }) ?? []
                    );
            }
            if (item.regulatory.energyEfficiencyLabel) {
                if (item.regulatory.energyEfficiencyLabel.imageURL)
                    setSelectedProductImageList([
                        {
                            file_id: 1,
                            file_name: item.regulatory.energyEfficiencyLabel.imageURL.substring(
                                item.regulatory.energyEfficiencyLabel.imageURL.lastIndexOf("/") + 1
                            ),
                            file_urls: [
                                {
                                    url: "",
                                    title: "",
                                },
                            ],
                            url_type: "",
                            _url: item.regulatory.energyEfficiencyLabel.imageURL,
                            selected: true,
                        },
                    ]);
                if (item.regulatory.energyEfficiencyLabel.imageDescription) {
                    setEnergyLabelDescription(
                        item.regulatory.energyEfficiencyLabel.imageDescription
                    );
                }
            }
            if ((item.regulatory.signalWords?.length ?? 0) > 0) {
                setSelectedOfferSignalWordsList(
                    item.regulatory.signalWords?.map((mapItem, index) => {
                        const item: AvailabilityTypeModel = {
                            id: index,
                            value: mapItem.signalWordId,
                            title: mapItem.signalWordDescription,
                        };

                        return item;
                    }) ?? []
                );
            }
            if ((item.regulatory.statements?.length ?? 0) > 0) {
                setSelectedOfferStatementsList(
                    item.regulatory.statements?.map((mapItem, index) => {
                        const item: AvailabilityTypeModel = {
                            id: index,
                            value: mapItem.statementId,
                            title: mapItem.statementDescription,
                        };

                        return item;
                    }) ?? []
                );
            }
        } else {
            // reset(defaultValues);
            // setPolicyTypeItemSelected(undefined);
        }
    };

    const onSubmit = async (values: FormValues) => {
        try {
            console.log("values => ", values);
            let productOffer: ProductManagementOfferCreateOrUpdateReq = {
                email: user?.email ?? "",
                businessdetail_id: `${businessItem?.business_id ?? 0}`,
                sku_code: values.sku,
                marketplaceId: "GBR",
                offer_format: "FIXED_PRICE",
                listing_Duration: "0",
                product_available_Quantity: parseInt(values.productQuantity ?? "0"),
                listing_Description: values.description ?? "",
                tax: {
                    applyTax: values.isTax,
                    vatPercentage: parseInt(values.tax ?? "0"),
                },
            };

            if (values.listingStartDate) {
                productOffer.listing_StartDate = values.listingStartDate;
            }

            console.log("productOffer => ", productOffer);

            if (values.qntLimitPeyBuyer) {
                productOffer.quantityLimitPerBuyer = values.qntLimitPeyBuyer;
            }

            if (values.productPrice) {
                productOffer.pricingSummary = {
                    price: {
                        currency: currencyItem?.CurrencyCode ?? "GBR",
                        value: parseFloat(parseFloat(values.productPrice).toFixed(2)) ?? 0,
                    },
                };
            }

            if (selectedPolicyList.length > 0) {
                const listingPolicy: any = {};

                for (let i = 0; i < selectedPolicyList.length; i++) {
                    const item = selectedPolicyList[i];
                    listingPolicy[item.policy_type] = item._url;
                }

                productOffer.listingPolicies = listingPolicy;
            }

            if (selectedCategoryLvl1) {
                productOffer.categoryId_level1 = selectedCategoryLvl1.category_id ?? "";
            }

            if (selectedCategoryLvl2) {
                productOffer.categoryId_level2 = selectedCategoryLvl2.category_id ?? "";
            }

            if (selectedCategoryLvl3) {
                productOffer.categoryId_level3 = selectedCategoryLvl3.category_id ?? "";
            }

            let regulatory: any = {};
            // image list
            if (selectedProductImageList.length > 0) {
                regulatory.energyEfficiencyLabel = {
                    imageDescription: energyLabelDescription,
                    imageURL: selectedProductImageList[0]._url,
                };
            }

            if (values.repairScore) {
                regulatory.repairScore = values.repairScore; //   # 0.0 <= x <= 10.0  > #it is limited to one decimal place
            }

            if (selectedOfferSignalWordsList.length > 0) {
                let signalWords: {
                    signalWordId: string;
                    signalWordDescription: string;
                }[] = [];
                for (let i = 0; i < selectedOfferSignalWordsList.length; i++) {
                    const item = selectedOfferSignalWordsList[i];
                    signalWords.push({
                        signalWordId: item.value,
                        signalWordDescription: item.value,
                    });
                }
                regulatory.signalWords = signalWords;
            }

            if (selectedOfferStatementsList.length > 0) {
                const statements: {
                    statementId: string;
                    statementDescription: string;
                }[] = [];
                for (let i = 0; i < selectedOfferStatementsList.length; i++) {
                    const item = selectedOfferStatementsList[i];
                    // statements[item.title] = item.value;
                    statements.push({
                        statementId: item.value,
                        statementDescription: item.value,
                    });
                }
                regulatory.statements = statements;
            }

            // if (values.repairScore) {
            //     productOffer.regulatory = values.repairScore;
            // }
            if (regulatory) {
                productOffer.regulatory = regulatory;
            }

            if (params.offerId) {
                productOffer.offer_id = offerDetails?.offer_id;
            }

            // return false;

            // console.log("productOffer => ", productOffer.pricingSummary?.price);

            // return false;
            mutateProductManagementOfferCreateOrUpdate(productOffer, {
                onSuccess: (successData: ProductManagementOfferCreateOrUpdateResponse) => {
                    if (successData.final_result_code === "000") {
                        // resetForm();
                        // getTeamMemberList();
                        toast.success(successData.general_result);
                        navigate(-1);
                    } else {
                        toast.error(successData.general_result);
                    }
                },
                onError: (error: Error) => {
                    const {
                        response: { data },
                    }: any = error ?? {};

                    toast.error(data?.general_result);

                    if (data?.final_result_code === "001") {
                        toast.error(data?.general_result[0]);
                    }
                },
            });
        } catch (error) {
            console.log("error => ", error);
        }
    };

    const isTax = watch("isTax");

    return (
        <>
            <form id={"inventory-add-form"} onSubmit={handleSubmit(onSubmit)} className="">
                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        title={"Enter product offer details "}
                        details={[]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />

                    <Card className="w-full sm:w-8/12 md:w-2/3 flex-col space-y-4">
                        <div className="w-full flex space-x-4 justify-center items-center">
                            <Input
                                label={"Product code (SKU)"}
                                {...register("sku")}
                                placeholder="SKU"
                                error={t(errors.sku?.message!)}
                                variant="outline"
                                maxLength={70}
                                className="w-full"
                            />

                            <Button
                                variant={"secondary"}
                                size={"small"}
                                form={"findSKUModal"}
                                className="mt-5"
                                loading={false}
                                onClick={() => {
                                    openModal("ProductManagement_Offer_Add_SKU_From_SKUList", {
                                        item: {},
                                        callback: (
                                            selectedProductItem: ProductManagementInventoryItem
                                        ) => {
                                            setValue("sku", selectedProductItem.sku_code);
                                        },
                                    });
                                }}
                            >
                                {"Find"}
                            </Button>
                        </div>

                        <MarketPlaceList
                            control={control}
                            errors={errors}
                            items={marketPlaceList}
                            isLoading={isLoadingProductMangementMarketPlaceList}
                            defaultValue={selectedMarketPlace}
                            onChangeValue={(item) => {
                                setSelectedMarketPlace(item);
                            }}
                        />

                        <Input
                            label={"Total product quantity"}
                            {...register("productQuantity")}
                            placeholder="Total product Quantity"
                            error={t(errors.productQuantity?.message!)}
                            variant="outline"
                            maxLength={9}
                            className="w-full"
                        />
                    </Card>
                </div>

                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        title={"Select product categories"}
                        details={[
                            "(Which category this product belongs to? it will help customer to find simply)",
                        ]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />
                    <Card className="w-full sm:w-8/12 md:w-2/3 flex-col space-y-4">
                        <CategoryLevel<ProductManagementCategoryItem>
                            control={control}
                            name={"categoryLevel1"}
                            title={"Category level 1"}
                            errors={errors}
                            items={categoryLvl1List}
                            defaultValue={selectedCategoryLvl1}
                            onChangeValue={(item) => {
                                setSelectedCategoryLvl1(item);
                                setError("categoryLevel1", {
                                    message: "",
                                });
                            }}
                        />
                        <CategoryLevel<ProductManagementCategoryItem>
                            control={control}
                            name={"categoryLevel2"}
                            title={"Category level 2"}
                            errors={errors}
                            items={categoryLvl2List}
                            defaultValue={selectedCategoryLvl2}
                            onChangeValue={(item) => {
                                setSelectedCategoryLvl2(item);
                                setError("categoryLevel2", {
                                    message: "",
                                });
                            }}
                        />
                        <CategoryLevel<ProductManagementCategoryItem>
                            control={control}
                            name={"categoryLevel3"}
                            title={"Category level 3"}
                            errors={errors}
                            items={categoryLvl3List}
                            defaultValue={selectedCategoryLvl3}
                            onChangeValue={(item) => {
                                setSelectedCategoryLvl3(item);
                                setError("categoryLevel3", {
                                    message: "",
                                });
                            }}
                        />
                    </Card>
                </div>

                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        title={"Enter product details"}
                        details={[]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />
                    <Card className="w-full sm:w-8/12 md:w-2/3 flex-col space-y-4">
                        <Input
                            label={"Product price value :"}
                            {...register("productPrice")}
                            placeholder="Product price"
                            error={t(errors.productPrice?.message!)}
                            variant="outline"
                            maxLength={10}
                            className="w-full"
                            onChange={(e) => {
                                if (e.target.value.length > 0) {
                                    const value = e.target.value;

                                    if (
                                        !isNaN(parseInt(value)) &&
                                        (value.split(".").length === 1 ||
                                            value.split(".")[1]?.length <= 2)
                                    ) {
                                        console.log("value => ", value);

                                        // let inputValue = parseFloat(value).toFixed(2);
                                        let inputValue = value;

                                        console.log("inputValue => ", inputValue);

                                        if (inputValue.endsWith("00")) {
                                            inputValue = inputValue.substring(
                                                inputValue.lastIndexOf(".")
                                            );
                                        }

                                        // setValue("productPrice", inputValue);
                                        clearErrors("productPrice");
                                    } else {
                                        setError("productPrice", {
                                            message:
                                                "The value is invalid. The number of floating-point digits should not exceed 2 characters.",
                                        });
                                    }
                                } else {
                                    clearErrors("productPrice");
                                }
                            }}
                        />

                        <CurrencyList
                            control={control}
                            errors={errors}
                            defaultValue={currencyItem}
                            onChangeValue={setCurrencyItem}
                        />

                        <TextArea
                            label={"Listing description"}
                            {...register("description")}
                            error={t(errors.description?.message!)}
                            variant="outline"
                            placeholder="Description"
                            className="mb-5"
                            maxLength={4000}
                        />
                    </Card>
                </div>

                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        title={"Listing start date"}
                        details={[
                            "You can enter and select listing start date and time ",
                            "(If you don't choose any date, after publishing will be listed.)",
                        ]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />
                    <Card className="w-full sm:w-8/12 md:w-2/3 w-full flex-col space-y-4">
                        <Label>{"Select listing start date and time"}</Label>
                        <Controller
                            control={control}
                            name="listingStartDate"
                            render={({ field: { onChange, onBlur, value } }) => (
                                //@ts-ignore
                                <DatePicker
                                    className="w-full"
                                    selected={startTime}
                                    onChange={(date: any) => {
                                        const selectedTime: string = `${date.getFullYear()}-${(
                                            date.getMonth() + 1
                                        )
                                            .toString()
                                            .padStart(2, "0")}-${date
                                            .getDate()
                                            .toString()
                                            .padStart(2, "0")} ${date
                                            .getHours()
                                            .toString()
                                            .padStart(2, "0")}:${date
                                            .getMinutes()
                                            .toString()
                                            .padStart(2, "0")}:${date
                                            .getSeconds()
                                            .toString()
                                            .padStart(2, "0")}`;

                                        onChange(selectedTime);
                                        setStartTime(date);
                                    }}
                                    showYearDropdown
                                    showMonthDropdown
                                    showTimeSelect
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="yyy-MM-dd HH:mm:ss"
                                    timeFormat="HH:mm"
                                />
                            )}
                        />

                        <div className="flex items-center space-x-2">
                            <Label className="mt-2.5">{"Listing duration: "}</Label>
                            <h5 className="text-base font-bold text-body-dark">
                                {"(Till cancelling)"}
                            </h5>
                        </div>

                        <Label>{"Listing policy"}</Label>

                        <AddOfferPolicyList
                            items={selectedPolicyList}
                            paginatorInfo={null}
                            onPagination={handlePagination}
                            onOrder={setOrder}
                            onSort={setColumn}
                            onDeleteItem={(item: ProductManagementPolicyItem) => {
                                setSelectedPolicyList(
                                    selectedPolicyList.filter((filterItem) => {
                                        return filterItem.file_id !== item.file_id;
                                    })
                                );
                            }}
                        />

                        <div className="mb-4 text-right space-x-4 mt-4">
                            <Button
                                variant={"secondary"}
                                size={"small"}
                                form={"addImageDialog"}
                                loading={false}
                                onClick={() => {
                                    openModal(
                                        "ProductManagement_Offer_Add_Policy_From_PolicyList",
                                        {
                                            item: { selectedItems: selectedPolicyList },
                                            callback: (
                                                selectedPolicyList: ProductManagementPolicyItem[]
                                            ) => {
                                                setSelectedPolicyList(selectedPolicyList);
                                            },
                                        }
                                    );
                                }}
                            >
                                {"Add new policy"}
                            </Button>
                        </div>

                        <Input
                            label={"Quantity limit per buyer"}
                            {...register("qntLimitPeyBuyer")}
                            placeholder="Quantity limit"
                            error={t(errors.qntLimitPeyBuyer?.message!)}
                            variant="outline"
                            maxLength={9}
                            className="w-full"
                        />
                    </Card>
                </div>

                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        title={"Regulatory"}
                        details={["You must enter between 0.0 and 10.0."]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />
                    <Card className="w-full sm:w-8/12 md:w-2/3 w-full flex-col space-y-4">
                        <Input
                            label={"Repair score"}
                            {...register("repairScore")}
                            placeholder="(ex: 2.3) from: 0.0 to 10.0"
                            error={t(errors.repairScore?.message!)}
                            variant="outline"
                            maxLength={4}
                            className="w-full"
                            onChange={(e) => {
                                if (e.target.value.length > 0) {
                                    const value = parseFloat(e.target.value);

                                    if (!isNaN(value) && value >= 0.0 && value <= 10.0) {
                                        clearErrors("repairScore");
                                    } else {
                                        setError("repairScore", {
                                            message:
                                                "The value is invalid. You must enter between 0.0 and 10.0.",
                                        });
                                    }
                                } else {
                                    clearErrors("repairScore");
                                }
                            }}
                        />

                        <Label>{"Energy efficiency label"}</Label>

                        <AddInventoryImageList
                            imageList={selectedProductImageList}
                            paginatorInfo={null}
                            onPagination={handlePagination}
                            onOrder={setOrder}
                            onSort={setColumn}
                            onDeleteItem={(item: ProductManagementImage) => {
                                setSelectedProductImageList(
                                    selectedProductImageList.filter((filterItem) => {
                                        return filterItem.file_id !== item.file_id;
                                    })
                                );
                            }}
                        />

                        <div className="mb-4 text-right space-x-4 mt-4">
                            <Button
                                variant={"secondary"}
                                size={"small"}
                                form={"addImageDialog"}
                                loading={false}
                                onClick={() => {
                                    openModal(
                                        "ProductManagement_Inventory_Add_Image_From_ImageList",
                                        {
                                            item: { selectedItems: selectedProductImageList },
                                            isShowEnergyLabelDescription: true,
                                            callback: (
                                                selectedImageList: ProductManagementImage[],
                                                description: string
                                            ) => {
                                                setSelectedProductImageList(selectedImageList);
                                                if (description) {
                                                    setEnergyLabelDescription(description);
                                                }
                                            },
                                        }
                                    );
                                }}
                            >
                                {"Add new image"}
                            </Button>
                        </div>

                        {/* ******************************************************** */}

                        <Label>{"Signal words"}</Label>

                        <AddInventoryAspectsList
                            imageList={selectedOfferSignalWordsList}
                            paginatorInfo={null}
                            onPagination={handlePagination}
                            onOrder={setOrder}
                            onSort={setColumn}
                            onDeleteItem={(item: AvailabilityTypeModel) => {
                                setSelectedOfferSignalWordsList(
                                    selectedOfferSignalWordsList.filter((filterItem) => {
                                        return filterItem.id !== item.id;
                                    })
                                );
                            }}
                            options={{
                                title2: "Description",
                            }}
                        />

                        <div className="mb-4 text-right space-x-4 mt-4">
                            <Button
                                variant={"secondary"}
                                size={"small"}
                                form={"addAspectDialog"}
                                loading={false}
                                onClick={() => {
                                    openModal(
                                        "ProductManagement_Inventory_Add_Aspect_From_AspectList",
                                        {
                                            item: {},
                                            inputTextArea: true,
                                            modalTitle: "Signal word",
                                            maxLengthTitle: 30,
                                            maxLengthValue: 100,
                                            labelValue: "Description",
                                            placeholderValue: "Description",
                                            callback: (item: AvailabilityTypeModel) => {
                                                setSelectedOfferSignalWordsList([
                                                    ...selectedOfferSignalWordsList,
                                                    {
                                                        ...item,
                                                        id: new Date().getTime(),
                                                    },
                                                ]);
                                            },
                                        }
                                    );
                                }}
                            >
                                {"Add new signal words"}
                            </Button>
                        </div>

                        {/* ******************************************************** */}

                        <Label>{"Statements"}</Label>

                        <AddInventoryAspectsList
                            imageList={selectedOfferStatementsList}
                            paginatorInfo={null}
                            onPagination={handlePagination}
                            onOrder={setOrder}
                            onSort={setColumn}
                            onDeleteItem={(item: AvailabilityTypeModel) => {
                                setSelectedOfferStatementsList(
                                    selectedOfferStatementsList.filter((filterItem) => {
                                        return filterItem.id !== item.id;
                                    })
                                );
                            }}
                            onPreviewItem={(item: AvailabilityTypeModel) => {
                                openModal(
                                    "ProductManagement_Inventory_Add_Aspect_From_AspectList",
                                    {
                                        item: {},
                                        isPreview: true,
                                        valueTitle: item.title,
                                        valueValue: item.value,
                                        inputTextArea: true,
                                        modalTitle: "Statements",
                                        maxLengthTitle: 30,
                                        maxLengthValue: 150,
                                        labelValue: "Description",
                                        placeholderValue: "Description",
                                        callback: (item: AvailabilityTypeModel) => {
                                            setSelectedOfferStatementsList([
                                                ...selectedOfferStatementsList,
                                                {
                                                    ...item,
                                                    id: new Date().getTime(),
                                                },
                                            ]);
                                        },
                                    }
                                );
                            }}
                            options={{
                                title1: "Statement ID",
                                title2: "Description",
                            }}
                            isShowPreview
                        />

                        <div className="mb-4 text-right space-x-4 mt-4">
                            <Button
                                variant={"secondary"}
                                size={"small"}
                                form={"addAspectDialog"}
                                loading={false}
                                onClick={() => {
                                    openModal(
                                        "ProductManagement_Inventory_Add_Aspect_From_AspectList",
                                        {
                                            item: {},
                                            inputTextArea: true,
                                            modalTitle: "Statements",
                                            maxLengthTitle: 30,
                                            maxLengthValue: 150,
                                            labelValue: "Description",
                                            placeholderValue: "Description",
                                            callback: (item: AvailabilityTypeModel) => {
                                                setSelectedOfferStatementsList([
                                                    ...selectedOfferStatementsList,
                                                    {
                                                        ...item,
                                                        id: new Date().getTime(),
                                                    },
                                                ]);
                                            },
                                        }
                                    );
                                }}
                            >
                                {"Add new statements"}
                            </Button>
                        </div>

                        {/* ******************************************************** */}
                    </Card>
                </div>

                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        title={"TAX"}
                        details={[]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />
                    <Card className="w-full sm:w-8/12 md:w-2/3 w-full flex-col space-y-4">
                        <Checkbox {...register("isTax")} label={"Apply tax"} className="mb-5" />

                        <Input
                            label={"VAT percentage"}
                            {...register("tax")}
                            placeholder="VAT percentage"
                            error={t(errors.tax?.message!)}
                            variant="outline"
                            maxLength={3}
                            className="w-full"
                            disabled={!isTax}
                            note={
                                "( Value added TAX which is calculated by seller and depends on the country )"
                            }
                        />

                        <div
                            style={{
                                height: 8,
                            }}
                        />
                        <div className="mb-2 text-center space-x-4 mt-8">
                            <Button
                                variant={"cancelForm"}
                                form={"backToList"}
                                loading={false}
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                {t("form:button-label-cancel")}
                            </Button>

                            {(!params.offerId || isEditMode) && (
                                <Button
                                    form={"inventory-add-form"}
                                    loading={isLoadingProductMangementOfferCreateOrUpdate}
                                    disabled={isLoadingProductMangementOfferCreateOrUpdate}
                                >
                                    {t("form:button-label-submit")}
                                </Button>
                            )}
                        </div>
                    </Card>
                </div>
            </form>
        </>
    );
}
