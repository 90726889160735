import { CountryItem } from "../types";
import CountryList from "./constants/country.json";

export const getCountryItem = (country: string, callBack: (item: CountryItem) => void) => {
    try {
        const countryItem = CountryList.find(
            (fItem) => {
                if (country.length === 2) {
                    return country.toLowerCase() === fItem.Alpha2Code.toLowerCase()
                }
                else if (country.length === 3) {
                    return country.toLowerCase() === fItem.Alpha3Code.toLowerCase()
                } else {
                    return country.toLowerCase() === fItem.CountryName.toLowerCase()
                }
            }
        );
        if (countryItem) {
            callBack(countryItem);
        }
    } catch (error) {
        console.log("error => ", error);
    }
};