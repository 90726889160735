import { useTranslation } from "react-i18next";
import PublishByOfferGroupForm from "../../../components/productListing/publishByOfferGroup-form";

export default function PublishByOfferGroupView({ children, layout }: any) {
    const { t } = useTranslation();

    return (
        <>
            <div className="flex border-b border-dashed border-border-base py-5 sm:py-8">
                <h1 className="text-lg font-semibold text-heading">
                    {`${t("common:sidebar-nav-item-product-listings")} / ${t(
                        "common:sidebar-nav-sub-item-product-publish-group"
                    )}`}
                </h1>
            </div>
            <PublishByOfferGroupForm />
        </>
    );
}
