import { useCallback, useEffect, useState } from "react";
import { Control, FieldErrors, useForm } from "react-hook-form";
import Label from "../ui/label";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import ValidationError from "../ui/form-validation-error";
import { useTranslation } from "react-i18next";
import SelectInput from "../ui/select-input";
import {
    ProductManagementBrandListReq,
    ProductManagementInventoryBrandItem,
    ProductManagementInventoryBranchListResponse,
    ProductManagementInventoryItem,
    ProductManagementInventoryProductConditionItem,
    ProductManagementMarketPlaceListItem,
    ProductManagementMarketPlaceListResponse,
    ProductManagementMarketPlaceReq,
    ProductManagementOfferListReq,
    ProductManagementOfferListResponse,
    ProductManagementPolicyTypeItem,
    SortOrder,
    ProductManagementOfferListItem,
    ProductListingPublishByOfferCreateOrUpdateReq,
    ProductListingPublishByOfferCreateOrUpdateResponse,
    ProductManagementInventoryGroupListReq,
    ProductManagementInventoryGroupListResponse,
    ProductManagementInventoryGroupItem,
    ProductListingPublishByOfferGroupCreateOrUpdateReq,
    ProductListingPublishByOfferGroupCreateOrUpdateResponse,
} from "../../types";
import Input from "../ui/forms/input";
import Button from "../ui/button";
import {
    useProductManagementMarketPlaceList,
    useProductManagementProductConditionList,
} from "../../service/hook/business";
import { userStore } from "../../store";
import { useModalAction } from "../../provider/modal.context";
import {
    useProductListingPublishByOfferCreateOrUpdate,
    useProductListingPublishByOfferGroupCreateOrUpdate,
    useProductManagementBrandList,
    useProductManagementInventoryGroupList,
    useProductManagementList,
    useProductManagementOfferGridShow,
} from "../../service/hook/sector_1";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../config/routes";
import PublishByOfferList from "./publishByOffer-list";
import { toast } from "react-toastify";
import PublishByOfferGroupList from "./publishByOfferGroup-list";

type FormValues = {
    skuGroupCode: string;
};

const defaultValues = {
    skuGroupCode: "",
};

const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
        async (data: any) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors: any) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors: any, currentError: any) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

export default function PublishByOfferGroupForm() {
    const { t } = useTranslation();
    const { user, businessItem } = userStore((state) => state);

    // const { openModal } = useModalAction();

    const [inventoryGroupList, setInventoryGroupList] = useState<
        ProductManagementInventoryGroupItem[]
    >([]);

    const [skuGroupInput, setSkuGroupInput] = useState<string>("");

    const [page, setPage] = useState(1);
    const [orderBy, setOrder] = useState("created_at");
    const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);
    const [visible, setVisible] = useState(false);

    const {
        mutate: mutateProductManagementInventoryGroup,
        isLoading: isLoadingProductMangementInventoryGroup,
        serverError: serverErrorProductMangementInventoryGroup,
        setServerError: setServerErrorProductMangementInventoryGroup,
    } = useProductManagementInventoryGroupList();

    const {
        mutate: mutateProductListingPublishByOfferGroupCreateOrUpdate,
        isLoading: isLoadingProductListingPublishByOfferGroupCreateOrUpdate,
        serverError: serverErrorProductListingPublishByOfferGroupCreateOrUpdate,
        setServerError: setServerErrorProductListingPublishByOfferGroupCreateOrUpdate,
    } = useProductListingPublishByOfferGroupCreateOrUpdate();

    function handlePagination(current: any) {
        setPage(current);
    }

    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        // resolver,
    });

    useEffect(() => {
        getProductOfferGroupList();
    }, [businessItem]);

    const getProductOfferGroupList = () => {
        const input: ProductManagementInventoryGroupListReq = {
            businessdetail_id: `${businessItem?.business_id ?? 0}`,
            page: 1,
            size: 100,
        };

        if (skuGroupInput.length > 0) {
            input.sku_group_code = skuGroupInput;
        }

        mutateProductManagementInventoryGroup(input, {
            onSuccess: (successData: ProductManagementInventoryGroupListResponse) => {
                if (successData.final_result_code === "000") {
                    setInventoryGroupList(successData.captured_data ?? []);
                }
            },
        });
    };

    const publishItem = async (item: any) => {
        // {
        //     "product_inventory_group_id": 2,
        //     "sku_group_code": "sku_grp_blue",
        //     "brand_id": null,
        //     "brand": null,
        //     "product_title": "products with blue color",
        //     "_updated_at": "2024-01-14"
        // }

        console.log("publishItem => ", item);

        // return false;

        const req: ProductListingPublishByOfferGroupCreateOrUpdateReq = {
            email: user?.email ?? "",
            businessdetail_id: `${businessItem?.business_id ?? 0}`,
            sku_group_code: item.sku_group_code ?? "",
            offer_format: "FIXED_PRICE",
            marketplaceId: "GBR",
        };

        mutateProductListingPublishByOfferGroupCreateOrUpdate(req, {
            onSuccess: (successData: ProductListingPublishByOfferGroupCreateOrUpdateResponse) => {
                if (successData.final_result_code === "000") {
                    toast.success(successData.general_result);
                    getProductOfferGroupList();
                }
            },
        });
    };

    const onSubmit = async () => {
        getProductOfferGroupList();
    };

    return (
        <>
            <div className="my-5 flex flex-wrap sm:my-8">
                <Description
                    details={[
                        "Here, you can publish a product inventory group.",
                        "Each product(SKU) code in the group, has to be introduced already in your offer data, otherwise your product group won't get published.",
                    ]}
                    className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                />

                <Card className="w-full sm:w-8/12 md:w-2/3 flex-col space-y-4">
                    <Input
                        label={"Product(SKU) group code"}
                        name="skuGroupCode"
                        placeholder="SKU group code"
                        value={skuGroupInput}
                        onChange={(e) => {
                            setSkuGroupInput(e.target.value);
                        }}
                        error={t(errors.skuGroupCode?.message!)}
                        variant="outline"
                    />

                    <div
                        className="mb-4 text-start"
                        style={{
                            display: "flex",
                            justifyContent: "end",
                        }}
                    >
                        <div
                            className="mb-4 text-start space-x-4"
                            style={{
                                display: "flex",
                                justifyContent: "start",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    onSubmit();
                                }}
                                loading={false}
                                disabled={false}
                            >
                                {"Search"}
                            </Button>
                        </div>
                    </div>

                    <PublishByOfferGroupList
                        offerList={inventoryGroupList}
                        paginatorInfo={null}
                        onPagination={handlePagination}
                        onOrder={setOrder}
                        onSort={setColumn}
                        onPublish={(item: ProductManagementInventoryGroupItem) => {
                            publishItem(item);
                        }}
                    />
                </Card>
            </div>
        </>
    );
}
