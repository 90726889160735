import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import { useTranslation } from "react-i18next";
import FileInput from "../ui/file-input";
import { BusinessLogoListResponse, Category } from "../../types";
import Input from "../ui/forms/input";
import Button from "../ui/button";
import {
    useBusinessLogoCreate,
    useBusinessLogoDelete,
    useBusinessLogoList,
} from "../../service/hook/business";
import { userStore } from "../../store";
import { toast } from "react-toastify";
import { TrashIcon } from "../icons/trash";

type FormValues = {
    files: string;
    name: string;
};

const defaultValues = {
    files: "",
    name: "",
};

type IProps = {
    initialValues?: Category | undefined;
};
export default function CreateOrUpdateBusinessLogoForm({ initialValues }: IProps) {
    const { t } = useTranslation();
    const { businessItem } = userStore((state) => state);

    const [logoList, setLogoList] = useState<any>();

    const [document, setDocument] = useState<any>();

    const {
        mutate: mutateBusinessLogoCreate,
        isLoading: isLoadingBusinessLogoCreate,
        serverError: serverErrorBusinessLogoCreate,
        setServerError: setServerErrorBusinessLogoCreate,
    } = useBusinessLogoCreate();

    const {
        mutate: mutateBusinessLogoList,
        isLoading: isLoadingBusinessLogoList,
        serverError: serverErrorBusinessLogoList,
        setServerError: setServerErrorBusinessLogoList,
    } = useBusinessLogoList();

    const {
        mutate: mutateBusinessLogoDelete,
        isLoading: isLoadingBusinessLogoDelete,
        serverError: serverErrorBusinessLogoDelete,
        setServerError: setServerErrorBusinessLogoDelete,
    } = useBusinessLogoDelete();

    useEffect(() => {
        getBusinessLogoList();
    }, [businessItem]);

    const getBusinessLogoList = () => {
        mutateBusinessLogoList(
            {
                businessdetail_id: businessItem?.business_id ?? 0,
            },
            {
                onSuccess: (successData: BusinessLogoListResponse) => {
                    if (successData.final_result_code === "000") {
                        setLogoList(successData.captured_data);
                    } else {
                        setLogoList(undefined);
                    }
                },
                onError: (error: Error) => {
                    const {
                        response: { data },
                    }: any = error ?? {};

                    toast.error(data?.general_result);
                },
            }
        );
    };

    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        // resolver: yupResolver(businessSetupValidationSchema),
    });

    const deleteLogoItem = (item: any) => {
        mutateBusinessLogoDelete(
            {
                businessdetail_id: businessItem?.business_id ?? 0,
            },
            {
                onSuccess: (successData: any) => {
                    if (successData.final_result_code === "000") {
                        try {
                            toast.success(successData.general_result);
                            getBusinessLogoList();
                        } catch (err) {
                            console.log("err => ", err);
                        }
                    } else {
                    }
                },
                onError: (error: Error) => {
                    const {
                        response: { data },
                    }: any = error ?? {};

                    toast.error(data?.general_result);
                },
            }
        );
    };

    const onSubmit = async (values: FormValues) => {
        const formData = new FormData();

        formData.append("image", document);
        formData.append("businessdetail_id", `${businessItem?.business_id ?? 0}`);

        mutateBusinessLogoCreate(formData, {
            onSuccess: (successData: any) => {
                if (successData.final_result_code === "000") {
                    getBusinessLogoList();
                    setDocument(undefined);
                    reset(defaultValues);
                    toast.success(successData.general_result);
                } else {
                }
            },
            onError: (error: Error) => {
                const {
                    response: { data },
                }: any = error ?? {};

                toast.error(data?.general_result);
                // closeModal();
            },
        });
    };

    return (
        <form id={"logo-form"} onSubmit={handleSubmit(onSubmit)}>
            <div className="my-5 flex flex-wrap border-b border-dashed border-border-base pb-8 sm:my-8">
                <Description
                    title={t("form:input-label-logo-file")}
                    details={[
                        t("form:form-description-business-logo-01"),
                        t("form:form-description-business-logo-02"),
                    ]}
                    className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5"
                />

                {logoList && (
                    <Card
                        className="w-full sm:w-3/12 md:w-1/3"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                        }}
                    >
                        <img width={120} height={120} src={logoList?.file_urls[0]?.url ?? "#"} />

                        <button
                            onClick={() => {
                                deleteLogoItem(logoList);
                            }}
                            className="text-red-500 transition duration-200 hover:text-red-600 focus:outline-none"
                            title={t("common:text-delete")}
                            style={{
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                            }}
                        >
                            <TrashIcon width={16} />
                        </button>
                    </Card>
                )}

                <Card
                    className={`w-full sm:w-${logoList ? "4/12" : "8/12"} md:w-${
                        logoList ? "1/3" : "2/3"
                    }`}
                >
                    <FileInput
                        name="image"
                        control={control}
                        multiple={false}
                        acceptFile={true}
                        onChange={(file: any) => {
                            if (file.length) {
                                setDocument(file[0]);
                            }
                        }}
                    />
                </Card>
            </div>

            <div className="my-5 flex flex-wrap sm:my-8">
                <Description
                    title={t("form:input-label-name-optional")}
                    details={[]}
                    className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                />

                <Card className="w-full sm:w-8/12 md:w-2/3">
                    <Input
                        label={t("form:input-label-name-optional")}
                        showLabel={false}
                        {...register("files")}
                        error={t(errors.name?.message!)}
                        variant="outline"
                        className="mb-5"
                    />
                </Card>
            </div>

            <div className="mb-4 text-end">
                <Button loading={isLoadingBusinessLogoCreate}>
                    {t("form:button-label-submit")}
                </Button>
            </div>
        </form>
    );
}
