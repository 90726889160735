import { Table } from "../ui/table";
import { useTranslation } from "react-i18next";
import {
    Product,
    MappedPaginatorInfo,
    ProductType,
    Shop,
    SortOrder,
    BankAccountItem,
} from "../../types";
import { useState } from "react";
// import TitleWithSort from "@/components/ui/title-with-sort";
// import { Routes } from "@/config/routes";
// import LanguageSwitcher from "@/components/ui/lang-action/action";
import Pagination from "../ui/pagination";
// import TitleWithSort from "../ui/title-with-sort";
// import Badge from "../ui/badge/badge";
import { usePrice } from "../../utils/use-price";
import { TrashIcon } from "../icons/trash";

export interface IAccountNumber extends BankAccountItem {}

export type IProps = {
    accountNumbers: IAccountNumber[] | undefined;
    paginatorInfo: MappedPaginatorInfo | null;
    onPagination: (current: number) => void;
    onSort: (current: any) => void;
    onOrder: (current: string) => void;
    onDeleteItem: (item: IAccountNumber) => void;
};

type SortingObjType = {
    sort: SortOrder;
    column: string | null;
};

const BankAccountList = ({
    accountNumbers,
    paginatorInfo,
    onPagination,
    onSort,
    onOrder,
    onDeleteItem,
}: IProps) => {
    // const { data, paginatorInfo } = products! ?? {};
    // const router = useRouter();
    const { t } = useTranslation();
    // const { alignLeft, alignRight } = useIsRTL();

    const alignLeft = "left";
    const alignRight = "right";

    const [sortingObj, setSortingObj] = useState<SortingObjType>({
        sort: SortOrder.Desc,
        column: null,
    });

    const onHeaderClick = (column: string | null) => ({
        onClick: () => {
            onSort((currentSortDirection: SortOrder) =>
                currentSortDirection === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc
            );
            onOrder(column!);

            setSortingObj({
                sort: sortingObj.sort === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc,
                column: column,
            });
        },
    });

    let columns = [
        {
            title: t("table:table-item-account-number"),
            dataIndex: "account_number",
            key: "account_number",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (accountNumber: string) => {
                return <span className="truncate whitespace-nowrap">{accountNumber ?? "-"}</span>;
            },
        },
        {
            title: t("table:table-item-action"),
            className: "cursor-pointer",
            dataIndex: "action",
            key: "action",
            align: alignRight,
            width: 180,
            // onHeaderCell: () => onHeaderClick("price"),
            render: function Render(value: number, record: IAccountNumber) {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "end",
                        }}
                    >
                        <button
                            onClick={() => {
                                onDeleteItem(record);
                            }}
                            className="text-red-500 transition duration-200 hover:text-red-600 focus:outline-none"
                            title={t("common:text-delete")}
                        >
                            <TrashIcon width={16} />
                        </button>
                        {/* <div
                            style={{
                                width: "20px",
                            }}
                        />
                        <button
                            onClick={() => {
                                // onEditItem()
                            }}
                            className="text-body transition duration-200 hover:text-heading focus:outline-none"
                            title={t("common:text-edit")}
                        >
                            <EditIcon width={16} />
                        </button> */}
                    </div>
                );
            },
        },
    ];

    // if (router?.query?.shop) {
    //     columns = columns?.filter((column) => column?.key !== "shop");
    // }

    return (
        <>
            <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
                <Table
                    /* @ts-ignore */
                    columns={columns}
                    emptyText={t("table:empty-table-data")}
                    data={accountNumbers}
                    rowKey="id"
                    scroll={{ x: 900 }}
                />
            </div>

            {!!paginatorInfo?.total && (
                <div className="flex items-center justify-end">
                    <Pagination
                        total={paginatorInfo.total}
                        current={paginatorInfo.currentPage}
                        pageSize={paginatorInfo.perPage}
                        onChange={onPagination}
                        showLessItems
                    />
                </div>
            )}
        </>
    );
};

export default BankAccountList;
