import { Table } from "../ui/table";
import { useTranslation } from "react-i18next";
import { MappedPaginatorInfo, SortOrder, AvailabilityTypeModel } from "../../types";
import { useState } from "react";
import Pagination from "../ui/pagination";
import { EditIcon } from "../icons/edit copy";
import { TrashIcon } from "../icons/trash";
import { Eye } from "../icons/eye-icon";

export interface IImageSelected extends AvailabilityTypeModel {}

export interface IOptions {
    title1?: string;
    title2?: string;
}

export type IProps = {
    imageList: IImageSelected[] | undefined;
    paginatorInfo: MappedPaginatorInfo | null;
    onPagination: (current: number) => void;
    onSort: (current: any) => void;
    onOrder: (current: string) => void;
    onDeleteItem: (item: IImageSelected) => void;
    onPreviewItem?: (item: IImageSelected) => void;
    options?: IOptions;
    isShowPreview?: boolean;
};

type SortingObjType = {
    sort: SortOrder;
    column: string | null;
};

const ProfileManagementTeamList = ({
    imageList,
    paginatorInfo,
    onPagination,
    onSort,
    onOrder,
    onDeleteItem,
    onPreviewItem,
    options,
    isShowPreview,
}: IProps) => {
    const { t } = useTranslation();

    const alignLeft = "left";
    const alignRight = "right";

    const [sortingObj, setSortingObj] = useState<SortingObjType>({
        sort: SortOrder.Desc,
        column: null,
    });

    let columns = [
        {
            title: options?.title1 ? options.title1 : "Title",
            dataIndex: "title",
            key: "title",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (value: string) => {
                return <span className="truncate whitespace-nowrap">{value ?? "-"}</span>;
            },
        },
        {
            title: options?.title2 ? options.title2 : "Value",
            dataIndex: "value",
            key: "value",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (value: string) => {
                return <span className="truncate whitespace-nowrap">{value ?? "-"}</span>;
            },
        },
        {
            title: t("table:table-item-action"),
            className: "cursor-pointer",
            dataIndex: "action",
            key: "action",
            align: alignRight,
            width: 60,
            render: function Render(value: number, record: IImageSelected) {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "end",
                        }}
                        className="space-x-4"
                    >
                        {isShowPreview && (
                            <button
                                form="preview"
                                name="preview"
                                onClick={() => {
                                    onPreviewItem && onPreviewItem(record);
                                }}
                                className="text-body transition duration-200 hover:text-heading focus:outline-none"
                                title={"preview"}
                            >
                                <Eye width={16} />
                            </button>
                        )}

                        <button
                            form="delete"
                            onClick={() => {
                                onDeleteItem(record);
                            }}
                            className="text-red-500 transition duration-200 hover:text-red-600 focus:outline-none"
                            title={t("common:text-delete")}
                        >
                            <TrashIcon width={16} />
                        </button>
                        {/* <div
                            style={{
                                width: "20px",
                            }}
                        />
                        <button
                            onClick={() => {
                                onDeleteItem(record);
                            }}
                            className="text-body transition duration-200 hover:text-heading focus:outline-none"
                            title={t("common:text-edit")}
                        >
                            <EditIcon width={16} />
                        </button> */}
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
                <Table
                    /* @ts-ignore */
                    columns={columns}
                    emptyText={t("table:empty-table-data")}
                    data={imageList}
                    rowKey="id"
                    // scroll={{ x: 900 }}
                />
            </div>

            {!!paginatorInfo?.total && (
                <div className="flex items-center justify-end">
                    <Pagination
                        total={paginatorInfo.total}
                        current={paginatorInfo.currentPage}
                        pageSize={paginatorInfo.perPage}
                        onChange={onPagination}
                        showLessItems
                    />
                </div>
            )}
        </>
    );
};

export default ProfileManagementTeamList;
