export const AttributeValueIcon: React.FC<React.SVGAttributes<{}>> = (
  props
) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.986 19.2" {...props}>
    <g
      id="attribute_value"
      data-name="attribute value"
      transform="translate(-6003.613 -2943.9)"
    >
      <path
        id="settings-outline"
        d="M57.257,48.321a2.784,2.784,0,0,0-2.775,1.527,2.636,2.636,0,0,0,.545,3.054,2.828,2.828,0,0,0,3.142.53,2.679,2.679,0,0,0,1.571-2.7A2.737,2.737,0,0,0,57.257,48.321ZM63.925,51a6.312,6.312,0,0,1-.066.874l1.956,1.491a.446.446,0,0,1,.106.578l-1.85,3.112a.474.474,0,0,1-.569.193l-1.943-.76a.714.714,0,0,0-.656.074,7.168,7.168,0,0,1-.932.527.672.672,0,0,0-.382.511L59.3,59.614a.475.475,0,0,1-.462.386h-3.7a.477.477,0,0,1-.463-.373l-.291-2.011a.678.678,0,0,0-.389-.514,6.767,6.767,0,0,1-.928-.529.709.709,0,0,0-.654-.072l-1.942.76a.474.474,0,0,1-.569-.193l-1.85-3.112a.446.446,0,0,1,.106-.578l1.653-1.262a.667.667,0,0,0,.26-.592c-.016-.175-.025-.35-.025-.526s.009-.348.025-.519a.665.665,0,0,0-.263-.586l-1.652-1.262a.446.446,0,0,1-.1-.575l1.85-3.112a.474.474,0,0,1,.569-.193l1.943.76a.714.714,0,0,0,.656-.074A7.168,7.168,0,0,1,54,44.91a.672.672,0,0,0,.382-.511l.291-2.014A.475.475,0,0,1,55.135,42h3.7a.477.477,0,0,1,.463.373l.291,2.011a.678.678,0,0,0,.389.514,6.767,6.767,0,0,1,.928.529.709.709,0,0,0,.654.072l1.942-.76a.474.474,0,0,1,.569.193l1.85,3.112a.446.446,0,0,1-.106.578l-1.653,1.262a.667.667,0,0,0-.262.592C63.915,50.65,63.925,50.825,63.925,51Z"
        transform="translate(5956.265 2902.5)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="arrow-up-outline_1_"
        data-name="arrow-up-outline (1)"
        d="M112,104.5l4.5-4.5,4.5,4.5m-4.5-3.875V115.75"
        transform="translate(5914.75 2845.5)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);
