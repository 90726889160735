import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import { useTranslation } from "react-i18next";
import {
    ProductManagementImage,
    ProductManagementImageList,
    ProductManagementImageListResponse,
    SortOrder,
} from "../../types";
import Input from "../ui/forms/input";
import Button from "../ui/button";
import {
    useBusinessDetailCreate,
    useBusinessDetailUpdate,
    useGetBusinessDetailRowItem,
    useProductManagementPolicyCrete,
    useProductManagementPolicyTypeList,
} from "../../service/hook/business";
import { userStore } from "../../store";
import { useModalAction } from "../../provider/modal.context";
import { productPolicyValidationSchema } from "./policy-validation-schema";
import ImageList from "./image-list";
import { useProductManagementImageList } from "../../service/hook/sector_1";
import { ImageListPageSize } from "../../utils/constants";

type FormValues = {
    policyName: string;
    policyType: any;
    policyData: any;
};

const defaultValues = {
    policyName: "",
    policyType: "",
    policyData: {},
};

const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
        async (data: any) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors: any) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors: any, currentError: any) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

export default function CreateOrUpdateProductImageForm() {
    const { t } = useTranslation();
    const { businessItem } = userStore((state) => state);

    const { openModal } = useModalAction();

    const [imageList, setImageList] = useState<ProductManagementImage[]>([]);

    const [fileName, setFileName] = useState<string>("");

    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [orderBy, setOrder] = useState("created_at");
    const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);
    const [visible, setVisible] = useState(false);

    const {
        mutate: mutateProductManagementImage,
        isLoading: isLoadingProductMangementImage,
        serverError: serverErrorProductMangementImage,
        setServerError: setServerErrorProductMangementImage,
    } = useProductManagementImageList();

    const {
        mutate: mutateProductMangementPolicyType,
        isLoading: isLoadingProductMangementPolicyType,
        serverError: serverErrorProductMangementPolicyType,
        setServerError: setServerErrorProductMangementPolicyType,
    } = useProductManagementPolicyTypeList();

    const {
        mutate: mutateProductMangementPolicyCreate,
        isLoading: isLoadingProductMangementPolicyCreate,
        serverError: serverErrorProductMangementPolicyCreate,
        setServerError: setServerErrorProductMangementPolicyCreate,
    } = useProductManagementPolicyCrete();

    const {
        mutate: mutateBusinessGetRowItemDetail,
        isLoading: isLoadingBusinessGetRowItemDetail,
        serverError: serverErrorBusinessGetRowItemDetail,
        setServerError: setServerErrorBusinessGetRowItemDetail,
    } = useGetBusinessDetailRowItem();

    const {
        mutate: mutateBusinessDetailCreate,
        isLoading: isLoadingBusinessDetailCreate,
        serverError: serverErrorBusinessDetailCreate,
        setServerError: setServerErrorBusinessDetailCreate,
    } = useBusinessDetailCreate();

    const {
        mutate: mutateBusinessDetailUpdate,
        isLoading: isLoadingBusinessDetailUpdate,
        serverError: serverErrorBusinessDetailUpdate,
        setServerError: setServerErrorBusinessDetailUpdate,
    } = useBusinessDetailUpdate();

    function handlePagination(current: any) {
        setPage(current);
    }

    const resolver = useYupValidationResolver(productPolicyValidationSchema);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        resolver,
    });

    useEffect(() => {
        if (businessItem) getProductImageList();
    }, [businessItem, page]);

    const getProductImageList = (fileName?: string) => {
        const imageListReq: ProductManagementImageList = {
            businessdetail_id: `${businessItem?.business_id ?? 0}`,
            page: page,
            size: ImageListPageSize,
        };
        if (fileName) {
            imageListReq.product_image_name = fileName;
        }
        mutateProductManagementImage(imageListReq, {
            onSuccess: (successData: ProductManagementImageListResponse) => {
                if (successData.final_result_code === "000") {
                    setImageList(successData.captured_data);
                    setTotalCount(successData.total_count ?? 0);
                }
            },
        });
    };

    const deleteItem = async (item: any) => {
        openModal("ProductManagement_Delete_Image", {
            item: { ...item },
            callback: () => {
                getProductImageList(fileName ?? "");
            },
        });
    };

    const onSubmit = async () => {
        getProductImageList(fileName ?? "");
    };

    return (
        <>
            <div className="my-5 flex flex-wrap sm:my-8">
                <Description
                    details={[
                        t("form:form-description-product-management-image-01"),
                        t("form:form-description-product-management-image-02"),
                        t("form:form-description-product-management-image-03"),
                    ]}
                    className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                />

                <Card className="w-full sm:w-8/12 md:w-2/3">
                    <Input
                        label={t("form:input-label-file-name")}
                        name="searchKey"
                        value={fileName}
                        onChange={(e) => {
                            setFileName(e.target.value);
                        }}
                        error={t(errors.policyName?.message!)}
                        variant="outline"
                        className="mb-5"
                        maxLength={50}
                    />

                    <div
                        className="mb-4 text-start"
                        style={{
                            display: "flex",
                            justifyContent: "end",
                        }}
                    >
                        <div
                            className="mb-4 text-start"
                            style={{
                                display: "flex",
                                justifyContent: "start",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    onSubmit();
                                }}
                                loading={isLoadingProductMangementImage}
                                disabled={isLoadingProductMangementImage}
                            >
                                {"Search"}
                            </Button>
                            <Button
                                variant={"secondary"}
                                onClick={() => {
                                    openModal("ProductManagement_Add_Image", {
                                        item: null,
                                        callback: () => {
                                            getProductImageList();
                                        },
                                    });
                                }}
                                className={"ml-4"}
                                loading={false}
                                disabled={isLoadingProductMangementImage}
                            >
                                {"Add new image"}
                            </Button>
                        </div>
                    </div>

                    <ImageList
                        imageList={imageList}
                        paginatorInfo={{
                            total: totalCount,
                            currentPage: page,
                            pageSize: ImageListPageSize,
                        }}
                        onPagination={handlePagination}
                        onOrder={setOrder}
                        onSort={setColumn}
                        onDeleteItem={(item: ProductManagementImage) => {
                            deleteItem(item);
                        }}
                    />
                </Card>
            </div>
        </>
    );
}
