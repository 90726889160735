import { FC } from "react";
import { BusinessProfileResponseModel } from "../../../../../types";
import logo from "../logo.png";
import "./layout-style1.css";

interface IProps {
  businessProfileData: BusinessProfileResponseModel;
}
const SellerProfileLayout1: FC<IProps> = ({ businessProfileData }) => {
  return (
    <div className="seller-layout-1">
      <header>
        <h1>{businessProfileData.business_profile.dedicated_web_address}</h1>
        <div className="logo">
          <img src={logo} alt="Business Logo" />
        </div>
      </header>

      <main>
        <section id="business-history">
          <h2>Business History</h2>
          <p>
            Our business was founded in [year] with a vision to [describe
            vision]. Over the years, we've grown and expanded, serving customers
            with dedication and commitment.
          </p>
        </section>

        <section id="about">
          <h2>About Us</h2>
          <p>
            We are a company specializing in [business specialization]. Our team
            works diligently to provide high-quality services and products to
            our clients.
          </p>
        </section>

        <section id="experience">
          <h2>Our Experience</h2>
          <p>
            With over [number] years in the industry, we bring expertise and
            knowledge to every project, ensuring client satisfaction.
          </p>
        </section>

        <section id="partners">
          <h2>Our Partners</h2>
          <p>
            We collaborate with top-notch partners to deliver the best services
            and solutions to our clients.
          </p>
        </section>
        <section id="management-team">
          <h2>Meet the Management Team</h2>
          <div className="team-grid">
            <div className="team-member">
              <h3>Member 1</h3>
              <p>Position and short bio.</p>
            </div>
            <div className="team-member">
              <h3>Member 2</h3>
              <p>Position and short bio.</p>
            </div>
            <div className="team-member">
              <h3>Member 3</h3>
              <p>Position and short bio.</p>
            </div>
          </div>
        </section>

        <section id="in-person-services">
          <h2>In-Person Services</h2>
          <div className="services-grid">
            <div className="service-item">Service 1</div>
            <div className="service-item">Service 2</div>
            <div className="service-item">Service 3</div>
            <div className="service-item">Service 4</div>
            <div className="service-item">Service 5</div>
            <div className="service-item">Service 6</div>
            <div className="service-item">Service 7</div>
            <div className="service-item">Service 8</div>
            <div className="service-item">Service 9</div>
            <div className="service-item">Service 10</div>
          </div>
        </section>

        <section id="licence">
          <h2>Licence</h2>
          <p>
            We are a fully licensed and accredited business. Licence Number:
            [Licence Number].
          </p>
        </section>
      </main>

      <footer>
        <p>&copy; [Current Year] Business Name. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default SellerProfileLayout1;
