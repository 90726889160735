export const PoundIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612.002 612.002" {...props}>
            <g data-name="Group 31">
                <g data-name="Group 30">
                    <path
                        data-name="Path 26"
                        d="M212.287,454.668V330.02h224.348v-51.456H212.287V162.92c0-61.994,51.155-111.464,113.27-111.464c62.111,0,113.264,50.102,113.264,113.907h51.457C490.277,72.742,416.039,0,325.557,0c-90.487,0-164.726,72.553-164.726,162.92v115.646h-39.106v51.456h39.106v124.649c0,58.178-24.699,87.666-39.106,100.114v53.271l1.931,3.945H488.09v-51.456H185.769C200.915,535.846,212.287,501.685,212.287,454.668z"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth=".7"
                    />
                </g>
            </g>
        </svg>
    );
};
