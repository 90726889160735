import { useEffect, useState } from "react";
import { Control, FieldErrors, useForm } from "react-hook-form";
import Label from "../ui/label";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import ValidationError from "../ui/form-validation-error";
import { useTranslation } from "react-i18next";
import SelectInput from "../ui/select-input";
import {
    BusinessDetailByIdItem,
    ProductManagementInventoryBrandItem,
    ProductManagementInventoryItem,
    ProductManagementInventoryProductConditionItem,
    ProductManagementMarketPlaceListItem,
    ProductManagementMarketPlaceListResponse,
    ProductManagementMarketPlaceReq,
    ProductManagementPolicyTypeItem,
    SortOrder,
    ProductManagementOfferListItem,
    ProductListingItemsReq,
    ProductManagementListingResponse,
    ProductManagementListingListItem,
    ProductListingUnListReq,
    ProductListingUnListResponse,
} from "../../types";
import Input from "../ui/forms/input";
import Button from "../ui/button";
import {
    useProductManagementMarketPlaceList,
    useProductManagementProductConditionList,
} from "../../service/hook/business";
import { userStore } from "../../store";
import { useModalAction } from "../../provider/modal.context";
import {
    useProductListingUnList,
    useProductManagementBrandList,
    useProductManagementList,
    useProductManagementListingGridShow,
} from "../../service/hook/sector_1";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../config/routes";
import ListingList from "./listing-list";
import { toast } from "react-toastify";
import { ProductListingGridShowPageSize } from "../../utils/constants";

function MarketPlaceList({
    control,
    errors,
    items,
    isLoading,
    defaultValue,
    onChangeValue,
}: {
    control: Control<FormValues>;
    errors: FieldErrors<FormValues>;
    items: ProductManagementMarketPlaceListItem[];
    isLoading: boolean;
    defaultValue?: ProductManagementMarketPlaceListItem;
    onChangeValue: (item: ProductManagementMarketPlaceListItem) => void;
}) {
    const { t } = useTranslation();
    const errorMessage: string = errors.marketPlace?.message as string;

    return (
        <div className="mb-5 w-full">
            <Label>{"Marketplace"}</Label>
            <SelectInput
                name="marketPlace"
                control={control}
                value={defaultValue}
                onChange={(value: ProductManagementMarketPlaceListItem) => {
                    onChangeValue(value);
                }}
                getOptionLabel={(option: any) => `${option.marketplace_name}`}
                getOptionValue={(option: any) => option.marketplace_id}
                options={items!}
                isLoading={false}
            />
            <ValidationError message={t(errorMessage)} />
        </div>
    );
}

type FormValues = {
    sku: string;
    groupCode: string;
    offerId: string;
    marketPlace: any;
};

const defaultValues = {
    sku: "",
    groupCode: "",
    offerId: "",
};

export default function CreateOrUpdateProductListingForm() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { businessItem, user } = userStore((state) => state);

    const { openModal } = useModalAction();

    const [marketPlaceList, setMarketPlaceList] = useState<ProductManagementMarketPlaceListItem[]>(
        []
    );
    const [selectedMarketPlace, setSelectedMarketPlace] = useState<
        ProductManagementMarketPlaceListItem | undefined
    >(undefined);

    const [listingList, setListingList] = useState<ProductManagementListingListItem[]>([]);

    const [skuInput, setSkuInput] = useState<string>("");
    const [groupCodeInput, setGroupCodeInput] = useState<string>("");
    const [offerId, setOfferId] = useState<string>("");

    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [orderBy, setOrder] = useState("created_at");
    const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);
    const [visible, setVisible] = useState(false);

    const {
        mutate: mutateProductManagementMarketPlaceList,
        isLoading: isLoadingProductMangementMarketPlaceList,
        serverError: serverErrorProductMangementMarketPlaceList,
        setServerError: setServerErrorProductMangementMarketPlaceList,
    } = useProductManagementMarketPlaceList();

    const {
        mutate: mutateProductListingGridList,
        isLoading: isLoadingProducListingGridList,
        serverError: serverErrorProducListingGridList,
        setServerError: setServerErrorProducListingGridList,
    } = useProductManagementListingGridShow();

    function handlePagination(current: any) {
        setPage(current);
    }

    // const resolver = useYupValidationResolver(productPolicyValidationSchema);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        // resolver,
    });

    useEffect(() => {
        if (page === 1) {
            getMarketPlaceList();
            getProductListingItems();
        } else {
            getProductListingItems();
        }
    }, [businessItem, page]);

    const getMarketPlaceList = (id?: string) => {
        const input: ProductManagementMarketPlaceReq = {};

        if (id) {
            input.marketplace_id = id;
        }

        mutateProductManagementMarketPlaceList(input, {
            onSuccess: (successData: ProductManagementMarketPlaceListResponse) => {
                if (successData.final_result_code === "000") {
                    setMarketPlaceList(successData.captured_data ?? []);
                }
            },
        });
    };

    const getProductListingItems = () => {
        const input: ProductListingItemsReq = {
            businessdetail_id: `${businessItem?.business_id ?? 0}`,
            marketplaceId: "GBR",
            page: page,
            size: ProductListingGridShowPageSize,
        };

        if (skuInput.length > 0) {
            input.sku_code = skuInput;
        }

        if (offerId.length > 0) {
            input.offer_id = parseInt(offerId);
        }

        if (groupCodeInput.length > 0) {
            input.sku_group_code = groupCodeInput;
        }

        mutateProductListingGridList(input, {
            onSuccess: (successData: ProductManagementListingResponse) => {
                if (successData.final_result_code === "000") {
                    setListingList(successData.captured_data ?? []);
                    setTotalCount(successData.total_count ?? 0);
                }
            },
        });
    };

    const deleteItem = async (item: any) => {
        openModal("Listing_Un_List_Confirm", {
            item: { ...item },
            callback: () => {
                getProductListingItems();
            },
        });
    };

    const onSubmit = async () => {
        getProductListingItems();
    };

    return (
        <>
            <div className="my-5 flex flex-wrap sm:my-8">
                <Description
                    details={[
                        "Each offer which gets published(listing), it can be seen by customers.",
                        "So, all relevant information have to be introduced properly and you will be responsible for any incorrect product information.",
                        "If you want to cancel the listing, just you can unlist that product, so it won't be seen by customers.",
                    ]}
                    className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                />

                <Card className="w-full sm:w-8/12 md:w-2/3 flex-col space-y-4">
                    <Input
                        label={"Product code (SKU)"}
                        name="sku"
                        placeholder="SKU code"
                        value={skuInput}
                        onChange={(e) => {
                            setSkuInput(e.target.value);
                        }}
                        error={t(errors.sku?.message!)}
                        variant="outline"
                    />

                    <Input
                        label={"SKU group code"}
                        name="groupCode"
                        placeholder="SKU group code"
                        value={groupCodeInput}
                        onChange={(e) => {
                            setGroupCodeInput(e.target.value);
                        }}
                        error={t(errors.groupCode?.message!)}
                        variant="outline"
                    />

                    <Input
                        label={"Offer ID"}
                        name="offerId"
                        placeholder="Offer ID"
                        value={offerId}
                        onChange={(e) => {
                            setOfferId(e.target.value);
                        }}
                        error={t(errors.offerId?.message!)}
                        variant="outline"
                    />

                    <MarketPlaceList
                        control={control}
                        errors={errors}
                        items={marketPlaceList}
                        isLoading={isLoadingProductMangementMarketPlaceList}
                        defaultValue={selectedMarketPlace}
                        onChangeValue={(item) => {
                            setSelectedMarketPlace(item);
                        }}
                    />

                    <div
                        className="mb-4 text-start"
                        style={{
                            display: "flex",
                            justifyContent: "end",
                        }}
                    >
                        <div
                            className="mb-4 text-start space-x-4"
                            style={{
                                display: "flex",
                                justifyContent: "start",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    onSubmit();
                                }}
                                loading={isLoadingProducListingGridList}
                                disabled={isLoadingProducListingGridList}
                            >
                                {"Search"}
                            </Button>
                        </div>
                    </div>

                    <ListingList
                        offerList={listingList}
                        paginatorInfo={{
                            total: totalCount,
                            currentPage: page,
                            pageSize: ProductListingGridShowPageSize,
                        }}
                        onPagination={handlePagination}
                        onOrder={setOrder}
                        onSort={setColumn}
                        onDeleteItem={(item: ProductManagementListingListItem) => {
                            deleteItem(item);
                        }}
                    />
                </Card>
            </div>
        </>
    );
}
