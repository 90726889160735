import React from 'react';
export const BathOil: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.3"
      height="17.3"
      fill="currentColor"
      {...props}
    >
      <g
        data-name="Bath &amp; Oil"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth=".3"
      >
        <path
          data-name="Path 21779"
          d="M16.549 8.809h-14.6V1.562A1.039 1.039 0 012.987.524h2.508a1.039 1.039 0 011.038 1.038v.4h-.717a.187.187 0 100 .374h1.808a.187.187 0 000-.374h-.717v-.4A1.414 1.414 0 005.497.15H2.989a1.414 1.414 0 00-1.412 1.412v7.247H.749a.6.6 0 00-.6.6v.569a.6.6 0 00.6.6h.1l.82 3.766a2.166 2.166 0 002.091 1.72l-.35.82a.187.187 0 10.344.147l.412-.963h8.966l.412.963a.187.187 0 10.344-.147l-.351-.82a2.166 2.166 0 002.091-1.72l.82-3.766h.1a.6.6 0 00.6-.6v-.569a.6.6 0 00-.599-.6zm-1.284 5.462v.008a1.779 1.779 0 01-1.857 1.419H3.899a1.778 1.778 0 01-1.857-1.419v-.009l-.8-3.688h14.832zm1.513-4.291a.229.229 0 01-.229.229H.749A.229.229 0 01.52 9.98v-.569a.229.229 0 01.229-.229h15.8a.229.229 0 01.229.229z"
        />
        <path
          data-name="Path 21780"
          d="M5.487 4.927l.1-.319a.187.187 0 00-.359-.107l-.095.319a.187.187 0 10.358.107z"
        />
        <path
          data-name="Path 21781"
          d="M5.819 3.81l.1-.319a.187.187 0 10-.359-.107l-.095.319a.187.187 0 10.358.107z"
        />
        <path
          data-name="Path 21782"
          d="M5.155 6.042l.1-.319a.187.187 0 00-.359-.107l-.095.319a.187.187 0 10.358.107z"
        />
        <path
          data-name="Path 21783"
          d="M4.791 6.607a.187.187 0 00-.233.126l-.095.319a.187.187 0 10.359.107l.095-.319a.187.187 0 00-.126-.233z"
        />
        <path
          data-name="Path 21784"
          d="M8.527 6.84l.095.319a.187.187 0 10.359-.107l-.1-.319a.187.187 0 00-.358.107z"
        />
        <path
          data-name="Path 21785"
          d="M7.862 4.609l.1.319a.187.187 0 00.358-.107l-.095-.319a.187.187 0 00-.359.107z"
        />
        <path
          data-name="Path 21786"
          d="M7.656 3.261a.187.187 0 00-.126.233l.1.319a.187.187 0 10.358-.107l-.095-.319a.187.187 0 00-.237-.126z"
        />
        <path
          data-name="Path 21787"
          d="M8.195 5.724l.095.319a.187.187 0 00.359-.107l-.095-.319a.187.187 0 10-.358.107z"
        />
        <path
          data-name="Path 21788"
          d="M6.909 3.44a.187.187 0 00-.374 0v.333a.187.187 0 10.374 0z"
        />
        <path
          data-name="Path 21789"
          d="M6.909 5.771a.187.187 0 10-.374 0v.333a.187.187 0 00.374 0z"
        />
        <path
          data-name="Path 21790"
          d="M6.909 4.604a.187.187 0 10-.374 0v.332a.187.187 0 10.374 0z"
        />
        <path
          data-name="Path 21791"
          d="M6.722 6.744a.187.187 0 00-.187.187v.332a.187.187 0 10.374 0v-.332a.187.187 0 00-.187-.187z"
        />
      </g>
    </svg>
  );
};
