import { Routes, Route } from "react-router-dom";
import { Routes as RoutesPath } from "../config/routes";
import AuthorizedRoute from "../components/AuthorizedRoute";
import ProductManagementOfferView from "../views/productManagement/offer";
import ProductManagementOfferAddView from "../views/productManagement/offer/details";

const ProductManagementOfferRoutes = () => {
    return (
        <Routes>
            <Route
                path={"/"}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductManagementOfferView />
                    </AuthorizedRoute>
                }
            />

            <Route
                path={RoutesPath.ProductManagementOfferAddOrDetails}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductManagementOfferAddView />
                    </AuthorizedRoute>
                }
            />

            <Route
                path={RoutesPath.ProductManagementOfferDetails}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductManagementOfferAddView />
                    </AuthorizedRoute>
                }
            />

            <Route
                path={RoutesPath.ProductManagementOfferUpdate}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductManagementOfferAddView isEditMode={true} />
                    </AuthorizedRoute>
                }
            />
        </Routes>
    );
};

export default ProductManagementOfferRoutes;
