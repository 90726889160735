import * as yup from 'yup';

export const businessSetupValidationSchema = yup.object(
  {
    firstName: yup.string().required('form:error-first-name-required'),
    lastName: yup.string().required('form:error-last-name-required'),
    isReceiveUpdate: yup.boolean().required(),
    businessMobilePhone: yup.string().required('form:error-business-phone-number'),
    businessName: yup.string().required('form:error-business-name-required'),
    type: yup.string().required(),
    vatNumber: yup.string().required('form:error-vat-number-required').max(20, 'form:error-vat-number-max-length'),
    country: yup.object().required(),
  }
);
