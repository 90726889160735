import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import { useTranslation } from "react-i18next";
import {
    BankAccountCreate,
    BankAccountItem,
    BankAccountListResponse,
    Category,
    SortOrder,
} from "../../types";
import Input from "../ui/forms/input";
import Button from "../ui/button";
import BankAccountList from "./bank-account-list";
import {
    useBusinessFinanceBankAccountCreate,
    useBusinessFinanceBankAccountList,
} from "../../service/hook/businessFinance";
import { toast } from "react-toastify";
import { userStore } from "../../store";
import { useModalAction } from "../../provider/modal.context";
import { bankAccountValidationSchema } from "./bank-account-schema";

type FormValues = {
    bankAccountNumber: string;
    sortCode: string;
    bankName: string;
};

const defaultValues = {
    bankAccountNumber: "",
    sortCode: "",
    bankName: "",
};

const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
        async (data: any) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors: any) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors: any, currentError: any) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

type IProps = {
    initialValues?: Category | undefined;
};
export default function CreateOrUpdateBusinessAccountBankForm({ initialValues }: IProps) {
    const { t } = useTranslation();
    const { businessItem } = userStore((state) => state);

    const [bankAccount, setBankAccount] = useState<BankAccountItem[]>();
    const [page, setPage] = useState(1);
    const [orderBy, setOrder] = useState("created_at");
    const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);
    const [visible, setVisible] = useState(false);

    const { openModal } = useModalAction();

    const toggleVisible = () => {
        setVisible((v) => !v);
    };

    function handlePagination(current: any) {
        setPage(current);
    }

    const resolver = useYupValidationResolver(bankAccountValidationSchema);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        resolver,
    });

    const {
        mutate: mutateBusinessFinanceBankAccountCreate,
        isLoading: isLoadingBusinessFinanceBankAccountCreate,
        serverError: serverErrorBusinessFinanceBankAccountCreate,
        setServerError: setServerErrorErrorBusinessFinanceBankAccountCreate,
    } = useBusinessFinanceBankAccountCreate();

    const {
        mutate: mutateBusinessFinanceBankAccountList,
        isLoading: isLoadingBusinessFinanceBankAccountList,
        serverError: serverErrorBusinessFinanceBankAccountList,
        setServerError: setServerErrorErrorBusinessFinanceBankAccountList,
    } = useBusinessFinanceBankAccountList();

    useEffect(() => {
        getBankAccountList();
    }, [businessItem]);

    const getBankAccountList = () => {
        mutateBusinessFinanceBankAccountList(
            {
                businessdetail_id: `${businessItem?.business_id ?? 0}`,
            },
            {
                onSuccess: (successData: BankAccountListResponse) => {
                    if (successData.final_result_code === "000") {
                        try {
                            setBankAccount(successData.captured_data ?? []);
                        } catch (err) {
                            console.log("err => ", err);
                        }
                    } else {
                    }
                },
                onError: (error: Error) => {
                    const {
                        response: { data },
                    }: any = error ?? {};

                    toast.error(data?.general_result);
                    // closeModal();
                },
            }
        );
    };

    const bankAccountDeleteItem = (item: BankAccountItem) => {
        openModal("BankAccount_Delete_account", {
            item: { ...item },
            callback: () => {
                getBankAccountList();
            },
        });
    };

    const onSubmit = async (values: FormValues) => {
        const input: BankAccountCreate = {
            businessdetail_id: `${businessItem?.business_id ?? 0}`,
            account_number: values.bankAccountNumber,
            currency_type: "GBP",
        };

        mutateBusinessFinanceBankAccountCreate(input, {
            onSuccess: (successData: any) => {
                if (successData.final_result_code === "000") {
                    try {
                        getBankAccountList();
                    } catch (err) {
                        console.log("err => ", err);
                    }

                    reset(defaultValues);

                    toast.success(successData.general_result);
                } else {
                    toast.error(successData.general_result);
                }
            },
            onError: (error: Error) => {
                const {
                    response: { data },
                }: any = error ?? {};

                toast.error(data?.general_result);
            },
        });
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        title={t("form:form-title-business-account-bank")}
                        details={
                            [
                                // t("form:form-description-business-account-bank-01")
                            ]
                        }
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />

                    <Card className="w-full sm:w-8/12 md:w-2/3">
                        <Input
                            label={t("form:input-label-bank-account-number")}
                            {...register("bankAccountNumber")}
                            error={t(errors.bankAccountNumber?.message!)}
                            variant="outline"
                            className="mb-5"
                        />
                        <Input
                            label={t("form:input-label-sort-code")}
                            {...register("sortCode")}
                            error={t(errors.sortCode?.message!)}
                            variant="outline"
                            className="mb-5"
                        />
                        <Input
                            label={t("form:input-label-your-bank-name")}
                            {...register("bankName")}
                            error={t(errors.bankName?.message!)}
                            variant="outline"
                            className="mb-5"
                        />
                    </Card>
                </div>

                <div className="mb-4 text-end">
                    <Button loading={isLoadingBusinessFinanceBankAccountCreate}>
                        {t("form:button-label-submit")}
                    </Button>
                </div>
            </form>
            <BankAccountList
                accountNumbers={bankAccount}
                paginatorInfo={null}
                onPagination={handlePagination}
                onOrder={setOrder}
                onSort={setColumn}
                onDeleteItem={(item: BankAccountItem) => {
                    bankAccountDeleteItem(item);
                }}
            />
        </>
    );
}
