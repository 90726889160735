import Uploader from "./common/uploader";
import { Controller, FieldErrors } from "react-hook-form";
import ValidationError from "./form-validation-error";
import { useTranslation } from "react-i18next";

interface FileInputProps {
    control: any;
    name: string;
    multiple?: boolean;
    acceptFile?: boolean;
    helperText?: string;
    defaultValue?: any;
    maxSize?: number;
    onChange?: (file: any) => void;
    acceptExtensions?: any;
    errors?: FieldErrors<any>;
    errorItemField?: string;
}

const FileInput = ({
    control,
    name,
    multiple = true,
    acceptFile = false,
    helperText,
    defaultValue = [],
    maxSize,
    onChange,
    acceptExtensions,
    errors,
    errorItemField,
}: FileInputProps) => {
    const { t } = useTranslation();
    let errorMessage: string = "";
    if (errors) {
        errorMessage = errors[errorItemField ?? ""]?.message as string;
    }

    return (
        <div className="mb-5 w-full">
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                render={({ field: { ref, ...rest } }) => (
                    <Uploader
                        {...rest}
                        onChange={onChange}
                        multiple={multiple}
                        acceptFile={acceptFile}
                        helperText={helperText}
                        maxSize={maxSize}
                        acceptExtensions={acceptExtensions}
                    />
                )}
            />
            {errorMessage !== "" && <ValidationError message={t(errorMessage)} />}
        </div>
    );
};

export default FileInput;
