import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import { useModalAction } from '../../provider/modal.context';
import { useMutation, useQuery } from '@tanstack/react-query';
import client from "../client";

export function useBusinessFinanceBankCardCreate() {
    let [serverError, setServerError] = useState<string | null>(null);


    const { mutate, isLoading } = useMutation(client.businessFinance.bankCardCreate, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessFinanceBankCardList() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.businessFinance.bankCardList, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessFinanceBankCardDelete() {
    let [serverError, setServerError] = useState<string | null>(null);


    const { mutate, isLoading } = useMutation(client.businessFinance.bankCardDelete, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}


export function useBusinessFinanceBankAccountCreate() {
    let [serverError, setServerError] = useState<string | null>(null);


    const { mutate, isLoading } = useMutation(client.businessFinance.bankAccountCreate, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessFinanceBankAccountList() {
    let [serverError, setServerError] = useState<string | null>(null);

    const { mutate, isLoading } = useMutation(client.businessFinance.bankAccountList, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessFinanceBankAccountDelete() {
    let [serverError, setServerError] = useState<string | null>(null);


    const { mutate, isLoading } = useMutation(client.businessFinance.bankAccountDelete, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessFinanceCreatePaymentIntent() {
    let [serverError, setServerError] = useState<string | null>(null);


    const { mutate, isLoading } = useMutation(client.businessFinance.createPaymentIntent, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessFinanceConfirmPaymentIntent() {
    let [serverError, setServerError] = useState<string | null>(null);


    const { mutate, isLoading } = useMutation(client.businessFinance.confirmPaymentIntent, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessFinanceRetrievePaymentIntent() {
    let [serverError, setServerError] = useState<string | null>(null);


    const { mutate, isLoading } = useMutation(client.businessFinance.retrievePaymentIntent, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useBusinessFinancePaymentConfig() {
    let [serverError, setServerError] = useState<string | null>(null);


    const { mutate, isLoading } = useMutation(client.businessFinance.paymentConfig, {
        onSuccess: (data) => {
            // console.log('useBusinessUserCreate data => ', data);
            // if (!data.token) {
            //     setServerError('error-credential-wrong');
            //     return;
            // }
            // setToken(data.token);
            // closeModal();
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}