import { Table } from "../ui/table";
import { useTranslation } from "react-i18next";
import { MappedPaginatorInfo, InPersonService } from "../../types";
import Pagination from "../ui/pagination";

export interface IServiceSectorType extends InPersonService {}

export type IProps = {
  serviceServiceTypeList: IServiceSectorType[] | undefined;
  paginatorInfo: MappedPaginatorInfo | null;
  onPagination: (current: number) => void;
  onSort: (current: any) => void;
  onOrder: (current: string) => void;
};

const ServiceTypeInPersonServiceList = ({
  serviceServiceTypeList,
  paginatorInfo,
  onPagination,
  onSort,
  onOrder,
}: IProps) => {
  const { t } = useTranslation();

  let columns = [
    {
      // title: t("table:table-item-service-1"),
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 120,
      align: "center",
      ellipsis: true,
      render: (category: string) => {
        return <span className="truncate whitespace-nowrap">{category}</span>;
      },
    },
    {
      // title: t("table:table-item-service-1"),
      title: "In-Person-Service-Name",
      dataIndex: "in_person_service_name",
      key: "in_person_service_name",
      width: 120,
      align: "center",
      ellipsis: true,
      render: (serviceName: string) => {
        return (
          <span className="truncate whitespace-nowrap">{serviceName}</span>
        );
      },
    },
  ];

  return (
    <>
      <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
        <Table
          /* @ts-ignore */
          columns={columns}
          emptyText={t("table:empty-table-data")}
          data={serviceServiceTypeList}
          rowKey="id"
          scroll={{ x: 900 }}
        />
      </div>

      {!!paginatorInfo?.total && (
        <div className="flex items-center justify-end">
          <Pagination
            total={paginatorInfo.total}
            current={paginatorInfo.currentPage}
            pageSize={paginatorInfo.perPage}
            onChange={onPagination}
            showLessItems
          />
        </div>
      )}
    </>
  );
};

export default ServiceTypeInPersonServiceList;
