import React from "react";
import { useTranslation } from "react-i18next";
import CreateOrUpdateBusinessServiceTypeForm from "../../../components/businessSetup/service-type-form";

export default function BusinessSetupServiceTypeView({
  children,
  layout,
}: any) {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex border-b border-dashed border-border-base py-5 sm:py-8">
        <h1 className="text-lg font-semibold text-heading">
          {`${t("common:sidebar-nav-item-setup-business")} / ${t(
            "common:sidebar-nav-sub-item-business-type"
          )}`}
        </h1>
      </div>
      <CreateOrUpdateBusinessServiceTypeForm />
    </>
  );
}
