import { Table } from "../ui/table";
import { useTranslation } from "react-i18next";
import { MappedPaginatorInfo, SortOrder, ProductManagementOfferListItem } from "../../types";
import { useState } from "react";
import Pagination from "../ui/pagination";
import { Eye } from "../icons/eye-icon";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../config/routes";
import { ArrowUp } from "../icons/arrow-up";

export interface IProductOffer extends ProductManagementOfferListItem {}

export type IProps = {
    offerList: IProductOffer[] | undefined;
    paginatorInfo: MappedPaginatorInfo | null;
    onPagination: (current: number) => void;
    onSort: (current: any) => void;
    onOrder: (current: string) => void;
    onPublish: (item: IProductOffer) => void;
};

type SortingObjType = {
    sort: SortOrder;
    column: string | null;
};

const PublishByOfferList = ({
    offerList,
    paginatorInfo,
    onPagination,
    onSort,
    onOrder,
    onPublish,
}: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const alignLeft = "left";
    const alignRight = "right";

    const [sortingObj, setSortingObj] = useState<SortingObjType>({
        sort: SortOrder.Desc,
        column: null,
    });

    // offer_id: number;
    // sku_code: string;
    // marketplaceId: string;
    // listing_Duration: string;
    // listing_status: string;
    // listing_StartDate: string;
    // price_value: string;
    // price_currency: string;
    // category_1_name: string;
    // category_2_name: string;
    // category_3_name: string;
    // updated_at: string;

    let columns = [
        {
            title: "Offer ID",
            dataIndex: "offer_id",
            key: "offer_id",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (value: string) => {
                return <span className="truncate whitespace-nowrap">{value ?? "-"}</span>;
            },
        },
        {
            title: "SKU",
            dataIndex: "sku_code",
            key: "sku_code",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (value: string) => {
                return <span className="truncate whitespace-nowrap">{value ?? "-"}</span>;
            },
        },
        {
            title: "Market place",
            dataIndex: "marketplaceId",
            key: "marketplaceId",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (value: string) => {
                return <span className="truncate whitespace-nowrap">{value ?? "-"}</span>;
            },
        },
        {
            title: "Listing date",
            dataIndex: "listing_StartDate",
            key: "listing_StartDate",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (value: string) => {
                return <span className="truncate whitespace-nowrap">{value ?? "-"}</span>;
            },
        },
        {
            title: "Listing status",
            dataIndex: "listing_status",
            key: "listing_status",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (value: string) => {
                return <span className="truncate whitespace-nowrap">{value ?? "-"}</span>;
            },
        },
        {
            title: "Action",
            className: "cursor-pointer",
            dataIndex: "action",
            key: "action",
            align: alignRight,
            width: 130,
            render: function Render(value: number, record: IProductOffer) {
                return (
                    <div
                        className="space-x-8"
                        style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "end",
                        }}
                    >
                        <button
                            onClick={() => {
                                navigate(
                                    `${Routes.ProductListingPublishByOfferDetails}${record.offer_id}`
                                );
                            }}
                            className="text-body transition duration-200 hover:text-heading focus:outline-none"
                            title={t("common:text-view")}
                        >
                            <Eye width={16} />
                        </button>
                        {/* <button
                            onClick={() => {
                                navigate(
                                    `${Routes.ProductManagementOfferUpdateBase}${record.offer_id}`
                                );
                            }}
                            className="text-body transition duration-200 hover:text-heading focus:outline-none"
                            title={t("common:text-edit")}
                        >
                            <EditIcon width={16} />
                        </button> */}

                        <button
                            onClick={() => {
                                onPublish(record);
                            }}
                            className="text-green-500 transition duration-200 hover:text-green-600 focus:outline-none"
                            title={"Publish"}
                        >
                            <ArrowUp />
                        </button>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
                <Table
                    /* @ts-ignore */
                    columns={columns}
                    emptyText={t("table:empty-table-data")}
                    data={offerList}
                    rowKey="id"
                    // scroll={{ x: 900 }}
                />
            </div>

            {!!paginatorInfo?.total && (
                <div className="flex items-center justify-end">
                    <Pagination
                        total={paginatorInfo.total}
                        current={paginatorInfo.currentPage}
                        pageSize={paginatorInfo.perPage}
                        onChange={onPagination}
                        showLessItems
                    />
                </div>
            )}
        </>
    );
};

export default PublishByOfferList;
