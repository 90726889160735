import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import { useTranslation } from "react-i18next";
import {
    BusinessDetailByIdItem,
    ProductManagementImage,
    ProductManagementPolicyTypeItem,
    ProductManagementVideoItem,
    ProductManagementVideoList,
    ProductManagementVideoListResponse,
    SortOrder,
} from "../../types";
import Input from "../ui/forms/input";
import Button from "../ui/button";
import { userStore } from "../../store";
import { useModalAction } from "../../provider/modal.context";
import { productPolicyValidationSchema } from "./policy-validation-schema";
import { useProductManagementVideoList } from "../../service/hook/sector_1";
import VideoList from "./video-list";

type FormValues = {
    policyName: string;
    policyType: any;
    policyData: any;
};

const defaultValues = {
    policyName: "",
    policyType: "",
    policyData: {},
};

const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
        async (data: any) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors: any) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors: any, currentError: any) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

export default function CreateOrUpdateProductVideoForm() {
    const { t } = useTranslation();
    const { user, businessItem, setRegisteredBusinessId } = userStore((state) => state);

    const { openModal } = useModalAction();

    const [videoList, setVideoList] = useState<ProductManagementVideoItem[]>([]);
    const [policyTypeItemSelected, setPolicyTypeItemSelected] = useState<
        ProductManagementPolicyTypeItem | undefined
    >(undefined);

    const [addOrEditMode, setAddOrEditMode] = useState<boolean>(true);
    const [productPolicyList, setProductPolicyList] = useState<any[]>();
    const [document, setDocument] = useState<any>();

    const [editItem, setEditItem] = useState<BusinessDetailByIdItem | null>();
    const [fileName, setFileName] = useState<string>("");

    const [page, setPage] = useState(1);
    const [orderBy, setOrder] = useState("created_at");
    const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);
    const [visible, setVisible] = useState(false);

    const {
        mutate: mutateProductManagementVideo,
        isLoading: isLoadingProductMangementVideo,
        serverError: serverErrorProductMangementVideo,
        setServerError: setServerErrorProductMangementVideo,
    } = useProductManagementVideoList();

    function handlePagination(current: any) {
        setPage(current);
    }

    const resolver = useYupValidationResolver(productPolicyValidationSchema);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        resolver,
    });

    useEffect(() => {
        getProductVideoList();
    }, [businessItem]);

    const getProductVideoList = (fileName?: string) => {
        const imageListReq: ProductManagementVideoList = {
            businessdetail_id: `${businessItem?.business_id ?? 0}`,
            page: 1,
            size: 10,
        };
        if (fileName) {
            imageListReq.product_video_name = fileName;
        }
        mutateProductManagementVideo(imageListReq, {
            onSuccess: (successData: ProductManagementVideoListResponse) => {
                if (successData.final_result_code === "000") {
                    setVideoList(successData.captured_data);
                }
            },
        });
    };

    const formReset = (item?: BusinessDetailByIdItem) => {
        if (item) {
            reset({
                // isReceiveUpdate: item.accepted_to_get_updates_via_sms_email,
                // businessName: item.business_name,
                // type: item.business_type,
                // vatNumber: item.vat_registration_number_tax,
            });
            // getCountryItem(item.country ?? "", (country: CountryItem) => {
            //     setCountryItem(country);
            //     setValue("country", country);
            // });
        } else {
            reset(defaultValues);
            setPolicyTypeItemSelected(undefined);
        }
    };

    const deleteItem = async (item: any) => {
        openModal("ProductManagement_Delete_Video", {
            item: { ...item },
            callback: () => {
                getProductVideoList(fileName ?? "");
            },
        });
    };

    const onSubmit = async () => {
        getProductVideoList(fileName ?? "");
    };

    return (
        <>
            <div className="my-5 flex flex-wrap sm:my-8">
                <Description
                    details={[
                        t("form:form-description-product-management-video-01"),
                        t("form:form-description-product-management-video-02"),
                    ]}
                    className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                />

                <Card className="w-full sm:w-8/12 md:w-2/3">
                    <Input
                        label={t("form:input-label-file-name")}
                        name="searchKey"
                        value={fileName}
                        onChange={(e) => {
                            setFileName(e.target.value);
                        }}
                        error={t(errors.policyName?.message!)}
                        variant="outline"
                        className="mb-5"
                        maxLength={50}
                    />

                    <div
                        className="mb-4 text-start"
                        style={{
                            display: "flex",
                            justifyContent: "end",
                        }}
                    >
                        <div
                            className="mb-4 text-start"
                            style={{
                                display: "flex",
                                justifyContent: "start",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    onSubmit();
                                }}
                                loading={isLoadingProductMangementVideo}
                                disabled={isLoadingProductMangementVideo}
                            >
                                {"Search"}
                            </Button>
                            <Button
                                variant={"secondary"}
                                onClick={() => {
                                    openModal("ProductManagement_Add_Video", {
                                        item: null,
                                        callback: () => {
                                            getProductVideoList();
                                        },
                                    });
                                }}
                                className={"ml-4"}
                                loading={false}
                                disabled={isLoadingProductMangementVideo}
                            >
                                {"Add new video"}
                            </Button>
                        </div>
                    </div>

                    <VideoList
                        videoList={videoList}
                        paginatorInfo={null}
                        onPagination={handlePagination}
                        onOrder={setOrder}
                        onSort={setColumn}
                        onDeleteItem={(item: ProductManagementImage) => {
                            deleteItem(item);
                        }}
                    />
                </Card>
            </div>
        </>
    );
}
