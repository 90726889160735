import * as yup from 'yup';

export const inventoryPickUpAtLocationValidationSchema = yup.object(
  {
    branch: yup.object().required(),
    availability: yup.object().required(),
    quantity: yup.string().required(),
    fulfillmentValue: yup.string().required(),
    unit: yup.string().required()
  }
);