export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

export enum BusinessType {
  SOLE_TRADER = "sole_trader",
  OTHERS = "others",
}

export enum CouponType {
  FIXED = "fixed",
  PERCENTAGE = "percentage",
  FREE_SHIPPING = "free_shipping",
}

export enum ProductType {
  Simple = "simple",
  Variable = "variable",
}
export enum StoreNoticePriorityType {
  High = "high",
  Medium = "medium",
  Low = "low",
}
export enum StoreNoticeType {
  all_vendor = "all_vendor",
  specific_vendor = "specific_vendor",
  all_shop = "all_shop",
  specific_shop = "specific_shop",
}

export enum PaymentGateway {
  STRIPE = "STRIPE",
  COD = "CASH_ON_DELIVERY",
  CASH = "CASH",
  FULL_WALLET_PAYMENT = "FULL_WALLET_PAYMENT",
  PAYPAL = "PAYPAL",
  MOLLIE = "MOLLIE",
  RAZORPAY = "RAZORPAY",
  PAYMONGO = "PAYMONGO",
  PAYSTACK = "PAYSTACK",
  XENDIT = "XENDIT",
  SSLCOMMERZ = "SSLCOMMERZ",
  IYZICO = "IYZICO",
  BKASH = "BKASH",
  FLUTTERWAVE = "FLUTTERWAVE",
}

export enum ProductStatus {
  Publish = "publish",
  Draft = "draft",
  UnderReview = "under_review",
  Approved = "approved",
  UnPublish = "unpublish",
  Rejected = "rejected",
}
export enum WithdrawStatus {
  Approved = "APPROVED",
  Pending = "PENDING",
  OnHold = "ON_HOLD",
  Rejected = "REJECTED",
  Processing = "PROCESSING",
}

export enum ShippingType {
  Fixed = "fixed",
  Percentage = "percentage",
  Free = "free_shipping",
}

export enum AddressType {
  Billing = "billing",
  Shipping = "shipping",
}

export type QueryOptionsType = {
  page?: number;
  name?: string;
  shop_id?: number;
  limit?: number;
  orderBy?: string;
  sortedBy?: SortOrder;
};

export enum OrderStatus {
  PENDING = "order-pending",
  PROCESSING = "order-processing",
  COMPLETED = "order-completed",
  CANCELLED = "order-cancelled",
  REFUNDED = "order-refunded",
  FAILED = "order-failed",
  AT_LOCAL_FACILITY = "order-at-local-facility",
  OUT_FOR_DELIVERY = "order-out-for-delivery",
}

export enum PaymentStatus {
  PENDING = "payment-pending",
  PROCESSING = "payment-processing",
  SUCCESS = "payment-success",
  FAILED = "payment-failed",
  REVERSAL = "payment-reversal",
  COD = "payment-cash-on-delivery",
}

export interface NameAndValueType {
  name: string;
  value: string;
}
export enum Permission {
  SuperAdmin = "super_admin",
  StoreOwner = "store_owner",
  Staff = "staff",
  Customer = "customer",
}

export interface GetParams {
  slug: string;
  language: string;
}

export interface QueryOptions {
  language: string;
  limit?: number;
  page?: number;
  orderBy?: string;
  sortedBy?: SortOrder;
}

export interface ShopSocialInput {
  icon?: string;
  url?: string;
}

export interface PaginatorInfo<T> {
  current_page: number;
  data: T[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: any[];
  next_page_url: string | null;
  path: string;
  per_page: number;
  prev_page_url: string | null;
  to: number;
  total: number;
}

export interface LoginInput {
  email: string;
  password: string;
}

export interface Type {
  id: string;
  name: string;
  icon: string;
  slug: string;
  promotional_sliders?: AttachmentInput[];
  settings?: TypeSettings;
  products?: ProductPaginator;
  created_at: string;
  updated_at: string;
  translated_languages: string[];
}

export interface CreateTypeInput {
  name: string;
  slug?: string;
  language?: string;
  gallery?: AttachmentInput[];
  icon?: string;
  banner_text?: string;
}

export interface Category {
  id: string;
  name: string;
  slug: string;
  translated_languages: string[];
  parent?: number;
  children: Category[];
  details?: string;
  image?: Attachment;
  icon?: string;
  type: Type;
  products: Product[];
  created_at: string;
  updated_at: string;
}

export interface Attribute {
  id: string;
  name: string;
  slug: string;
  values: AttributeValue[];
  shop_id?: number;
  translated_languages: string[];
  language?: string;
}

export interface AttributeValueInput {
  id?: number;
  value: string;
  meta?: string;
}

export interface CreateAttributeInput {
  name: string;
  shop_id: number;
  language?: string;
  values: AttributeValueInput;
}

export interface AttributeValueCreateInput {
  value: string;
  meta: string;
  attribute_id?: number;
}

export interface VariationProductPivot {
  price?: number;
}

export interface AttributeValue {
  id: string;
  value?: string;
  attribute?: Attribute;
  products: Product[];
  pivot?: VariationProductPivot;
  meta?: string;
}

export interface Variation {
  id?: string;
  title?: string;
  image?: Attachment;
  digital_file?: DigitalFile;
  price?: number;
  sku?: string;
  is_disable?: boolean;
  sale_price?: number;
  quantity?: number;
  options?: VariationOption[];
}

export interface DigitalFile {
  created_at?: string;
  id: string;
  attachment_id: string;
  file_name: string;
  updated_at?: string;
  url: string;
}

export interface VariationOption {
  name?: string;
  value?: string;
}

export interface VariationOptionInput {
  name?: string;
  value?: string;
}

export interface Attachment {
  thumbnail: string;
  original: string;
  id?: string;
}

export interface AttachmentInput {
  thumbnail: string;
  original: string;
  id?: string;
}

export interface ConnectTypeBelongsTo {
  connect?: string;
}

export interface Shop {
  id?: string;
  owner_id?: number;
  owner?: User;
  staffs?: User[];
  is_active?: boolean;
  orders_count?: number;
  products_count?: number;
  balance?: Balance;
  name?: string;
  slug?: string;
  description?: string;
  cover_image?: Attachment;
  logo?: Attachment;
  address?: UserAddress;
  settings?: ShopSettings;
  created_at?: string;
  updated_at?: string;
}

export interface Balance {
  id?: string;
  admin_commission_rate?: number;
  shop?: Shop;
  total_earnings?: number;
  withdrawn_amount?: number;
  current_balance?: number;
  payment_info?: PaymentInfo;
}

export interface PaymentInfo {
  account?: string;
  name?: string;
  email?: string;
  bank?: string;
}

export interface PaymentInfoInput {
  account?: string;
  name?: string;
  email?: string;
  bank?: string;
}

export interface BalanceInput {
  id?: string;
  payment_info?: PaymentInfoInput;
}

export interface ShopSettings {
  socials?: ShopSocials[];
  contact?: string;
  location?: Location;
  website?: string;
  notifications: {
    email: string;
    enable: boolean;
  };
}

export interface Location {
  lat?: number;
  lng?: number;
  city?: string;
  state?: string;
  country?: string;
  zip?: string;
  formattedAddress?: string;
}

export interface ShopSocials {
  icon?: string;
  url?: string;
}

export interface UserAddress {
  country?: string;
  city?: string;
  state?: string;
  zip?: string;
  street_address?: string;
}

export interface MakeAdminInput {
  user_id: string;
}

export interface User {
  id: string;
  name: string;
  shops: Shop[];
  managed_shop: Shop;
  is_active: boolean;
  email: string;
  created_at: string;
  updated_at: string;
  profile?: Profile;
  address: Address[];
  orders?: OrderPaginator;
  email_verified: boolean;
}

export interface UpdateUser {
  name?: string;
  profile?: UserProfileInput;
  address?: UserAddressUpsertInput[];
}

export interface Profile {
  id: string;
  avatar?: Attachment;
  bio?: string;
  contact?: string;
  socials?: Social[];
  customer?: User;
}

export interface Social {
  type?: string;
  link?: string;
}

export interface Address {
  id: string;
  title?: string;
  default?: boolean;
  address?: UserAddress;
  type?: string;
  customer?: User;
  location: GoogleMapLocation;
}

export interface Coupon {
  id: string;
  code: string;
  description: string;
  translated_languages: string[];
  orders: Order[];
  type: string;
  image: string;
  amount: number;
  active_from: string;
  expire_at: string;
  created_at: string;
  updated_at: string;
}

export interface CouponInput {
  code: string;
  type: CouponType;
  amount: number;
  minimum_cart_amount: number;
  description?: string;
  image?: AttachmentInput;
  active_from: string;
  expire_at: string;
  language?: string;
}

export interface StoreNotice {
  id: string;
  translated_languages: string[];
  priority: StoreNoticePriorityType;
  notice: string;
  description?: string;
  effective_from?: string;
  expired_at: string;
  type?: string;
  is_read?: boolean;
  shops?: Shop[];
  users?: User[];
  received_by?: string;
  created_by: string;
  expire_at: string;
  created_at: string;
  creator_role: string;
  updated_at: string;
  deleted_at?: string;
  creator?: any;
}

export interface StoreNoticeInput {
  priority: string;
  notice: string;
  description?: string;
  effective_from?: string;
  expired_at: string;
  type: string;
  received_by?: string[];
}

export interface StoreNoticeUserToNotifyInput {
  type: string;
}

export interface Order {
  id: string;
  tracking_number: string;
  customer_contact: string;
  customer_name: string;
  customer_id: number;
  customer?: User;
  amount: number;
  sales_tax: number;
  total: number;
  paid_total: number;
  payment_id?: string;
  parent_id: string | null;
  payment_gateway?: string;
  coupon?: Coupon;
  discount?: number;
  delivery_fee?: number;
  delivery_time: string;
  products: Product[];
  created_at: string;
  updated_at: string;
  billing_address?: UserAddress;
  shipping_address?: UserAddress;
  translated_languages: string[];
  language: string;
  order_status: string;
  payment_status: string;
  shop_id?: string;
}

export interface OrderProductPivot {
  order_quantity?: number;
  unit_price?: number;
  subtotal?: number;
  variation_option_id?: string;
}

export interface VerifyCheckoutInputType {
  amount: number;
  customer_id: string;
  products: any;
  billing_address: Address;
  shipping_address: Address;
}

export type VerifyCouponInputType = {
  code: string;
  sub_total: number;
};
export interface VerifyCouponResponse {
  is_valid: boolean;
  coupon?: Coupon;
  message?: string;
}

export interface Product {
  id: string;
  translated_languages: string[];
  shop_id: string;
  name: string;
  slug: string;
  type: Type;
  product_type: ProductType;
  max_price?: number;
  min_price?: number;
  categories: Category[];
  variations?: AttributeValue[];
  variation_options?: Variation[];
  digital_file?: DigitalFile;
  pivot?: OrderProductPivot;
  orders: Order[];
  description?: string;
  in_stock?: boolean;
  is_digital?: boolean;
  is_external?: boolean;
  is_taxable?: boolean;
  sale_price?: number;
  video?: {
    url: string;
  }[];
  sku?: string;
  gallery?: Attachment[];
  image?: Attachment;
  status?: ProductStatus;
  height?: string;
  length?: string;
  width?: string;
  price: number;
  quantity?: number;
  unit?: string;
  external_product_url?: string;
  external_product_button_text?: string;
  created_at: string;
  updated_at: string;
  ratings: number;
}

export interface CreateProduct {
  name: string;
  slug: string;
  type_id: string;
  price: number;
  sale_price?: number;
  quantity?: number;
  unit: string;
  description?: string;
  categories?: string[];
  variations?: AttributeProductPivot[];
  in_stock?: boolean;
  is_taxable?: boolean;
  author_id?: string;
  digital_file?: DigitalFileInput;
  external_product_button_text?: string;
  external_product_url?: string;
  is_external?: boolean;
  manufacturer_id?: string;
  max_price?: number;
  min_price?: number;
  variation_options?: UpsertVariationsHasMany;
  video: {
    url: string;
  }[];
  sku?: string;
  gallery?: AttachmentInput[];
  image?: AttachmentInput;
  status?: ProductStatus;
  height?: string;
  length?: string;
  width?: string;
  shop_id?: string;
}

export interface AttributeProductPivot {
  id: string;
  price?: number;
}

export interface DigitalFileInput {
  file_name: string;
  attachment_id: string;
  id?: string;
  url: string;
}

export interface UpsertVariationsHasMany {
  delete?: string[];
  upsert?: VariationInput[];
}

export interface VariationInput {
  digital_file?: DigitalFileInput;
  id?: string;
  image?: AttachmentInput;
  is_digital?: boolean;
  is_disable?: boolean;
  options?: VariationOptionInput[];
  price: number;
  quantity: number;
  sale_price?: number;
  sku: number;
  title: number;
}

export interface Tag {
  id: string;
  name: string;
  slug: string;
  details?: string;
  image?: Attachment;
  translated_languages: string[];
  icon?: string;
  type: Type;
  products?: Product[];
  created_at?: string;
  updated_at?: string;
}

export interface CreateTagInput {
  name: string;
  type?: ConnectTypeBelongsTo;
  details?: string;
  image?: AttachmentInput;
  icon?: string;
}

export interface Author {
  bio?: string;
  born?: string;
  translated_languages: string[];
  cover_image?: Attachment;
  death?: string;
  id: string;
  image?: Attachment;
  is_approved?: boolean;
  language?: string;
  name: string;
  quote?: string;
  slug?: string;
  socials?: ShopSocials[];
  created_at?: string;
  updated_at?: string;
}

export interface CreateAuthorInput {
  bio?: string;
  born?: string;
  cover_image?: AttachmentInput;
  death?: string;
  image?: AttachmentInput;
  is_approved?: boolean;
  language?: string;
  name: string;
  quote?: string;
  shop_id?: string;
  socials?: ShopSocialInput[];
}

export interface CreateCategoryInput {
  name: string;
  type_id?: string;
  parent?: number;
  details?: string;
  image?: AttachmentInput;
  icon?: string;
}

export interface CreateWithdrawInput {
  amount: number;
  shop_id: number;
  payment_method?: string;
  details?: string;
  note?: string;
}

export interface ApproveWithdrawInput {
  id: string;
  status: WithdrawStatus;
}

// -> TODO: Simplify this
export interface MappedPaginatorInfo {
  currentPage: number;
  firstPageUrl: string;
  from: number;
  lastPage: number;
  lastPageUrl: string;
  links: any[];
  nextPageUrl: string | null;
  path: string;
  perPage: number;
  prevPageUrl: string | null;
  to: number;
  total: number;
  hasMorePages: boolean;
}

export interface PaginatorInfoProps {
  currentPage: number;
  total: number;
  pageSize: number;
}

export interface Manufacturer {
  cover_image?: Attachment;
  created_at?: string;
  description?: string;
  translated_languages: string[];
  id: string;
  image?: Attachment;
  is_approved?: boolean;
  name: string;
  slug?: string;
  socials?: ShopSocials[];
  type: Type;
  type_id?: string;
  updated_at?: string;
  website?: string;
}

export interface ConnectProductOrderPivot {
  product_id: string;
  order_quantity?: number;
  unit_price?: number;
  subtotal?: number;
}

export interface CardInput {
  number: string;
  expiryMonth: string;
  expiryYear: string;
  cvv: string;
  email?: string;
}

export declare type UserAddressInput = {
  country?: string;
  city?: string;
  state?: string;
  zip?: string;
  street_address?: string;
};

export interface CreateOrderStatusInput {
  name: string;
  color: string;
  serial: number;
  language?: string;
}

export interface CreateOrderInput {
  tracking_number?: string;
  customer_id: number;
  order_status?: string;
  products: ConnectProductOrderPivot[];
  amount: number;
  sales_tax?: number;
  total: number;
  paid_total: number;
  payment_id?: string;
  payment_gateway: string;
  coupon_id?: number;
  discount?: number;
  delivery_fee?: number;
  delivery_time?: string;
  card?: CardInput;
  billing_address?: UserAddressInput;
  shipping_address?: UserAddressInput;
}

export interface CreateManufacturerInput {
  cover_image?: AttachmentInput;
  description?: string;
  image?: AttachmentInput;
  is_approved?: boolean;
  name: string;
  shop_id?: string;
  language?: string;
  socials?: ShopSocialInput[];
  type_id: string;
  website?: string;
}

export interface Withdraw {
  id?: string;
  amount?: number;
  status?: WithdrawStatus;
  shop_id?: number;
  shop?: Shop;
  payment_method?: string;
  details?: string;
  note?: string;
  created_at?: string;
  updated_at?: string;
}

export interface Review {
  id: number;
  user_id: number;
  product_id: number;
  rating: number;
  comment?: string;
  photos?: Attachment[];
  created_at: string;
  updated_at: string;
  positive_feedbacks_count?: number;
  negative_feedbacks_count?: number;
  product: Product;
  user: User;
  abusive_reports: AbusiveReport[];
}

export interface AbusiveReport {
  id?: number;
  user_id?: number;
  user: User[];
  model_id: number;
  model_type: string;
  message: string;
  created_at: string;
  updated_at: string;
}

export interface CreateAbuseReportInput {
  model_id: string;
  model_type: string;
  message: string;
}

export interface CreateMessageInput {
  message: string;
  id: string;
  shop_id: string;
}
export interface CreateMessageSeenInput {
  id: string;
}

export interface Tax {
  id?: string;
  name?: string;
  rate?: number;
}

export interface SettingsOptions {
  siteTitle?: string;
  siteSubtitle?: string;
  currency?: string;
  defaultAi?: string;
  useOtp?: boolean;
  useAi?: boolean;
  useGoogleMap?: boolean;
  isProductReview?: boolean;
  freeShipping?: boolean;
  contactDetails?: ContactDetails;
  minimumOrderAmount?: number;
  freeShippingAmount?: number;
  currencyToWalletRatio?: number;
  signupPoints?: number;
  maxShopDistance?: number;
  maximumQuestionLimit?: number;
  deliveryTime?: DeliveryTime[];
  logo?: Attachment;
  taxClass?: string;
  shippingClass?: string;
  seo?: SeoSettings;
  google?: GoogleSettings;
  facebook?: FacebookSettings;
  paymentGateway?: any;
  defaultPaymentGateway?: string;
  guestCheckout: boolean;
  smsEvent?: SmsEvent;
  emailEvent?: EmailEvent;
  server_info?: ServerInfo;
}

export interface ContactDetails {
  socials?: ShopSocials[];
  contact?: string;
  location?: Location;
  website?: string;
}

export interface Location {
  lat?: number;
  lng?: number;
  city?: string;
  state?: string;
  country?: string;
  zip?: string;
  formattedAddress?: string;
}

export interface LatestMessage {
  body: string;
  conversation_id: string;
  created_at: string;
  updated_at: string;
  user_id: string;
  id: string;
}

export interface Conversations {
  id: string;
  created_at: string;
  updated_at: string;
  shop_id: number;
  unseen: boolean;
  user_id: string;
  user: User;
  shop: Shop;
  latest_message: LatestMessage;
}

export interface Message extends LatestMessage {
  conversation: Conversations;
}

export interface ShopSocials {
  icon?: string;
  url?: string;
}

export interface FacebookSettings {
  appId?: string;
  isEnable?: boolean;
  pageId?: string;
}

export interface GoogleSettings {
  isEnable?: boolean;
  tagManagerId?: string;
}

export type SeoSettings = {
  canonicalUrl?: string;
  metaDescription?: string;
  metaTags?: string;
  metaTitle?: string;
  ogDescription?: string;
  ogImage?: Attachment;
  ogTitle?: string;
  twitterCardType?: string;
  twitterHandle?: string;
};

export interface Settings {
  id: string;
  language: string;
  options: SettingsOptions;
}

export interface SettingsInput {
  language?: string;
  options?: SettingsOptionsInput;
}

export interface Tax {
  id?: string;
  name?: string;
  rate?: number;
  is_global?: boolean;
  country?: string;
  state?: string;
  zip?: string;
  city?: string;
  priority?: number;
  on_shipping?: boolean;
}

export interface TaxInput {
  name?: string;
  rate?: number;
  is_global?: boolean;
  country?: string;
  state?: string;
  zip?: string;
  city?: string;
  priority?: number;
  on_shipping?: boolean;
}

export interface Shipping {
  id?: string;
  name?: string;
  amount?: number;
  is_global?: boolean;
  type?: ShippingType;
}

export interface ShippingInput {
  name: string;
  amount: number;
  is_global?: boolean;
  type: ShippingType;
}

export interface ShippingUpdateInput {
  name?: string;
  amount?: number;
  is_global?: boolean;
  type?: ShippingType;
}

export interface SeoSettingsInput {
  metaTitle?: string;
  metaDescription?: string;
  metaTags?: string;
  canonicalUrl?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: AttachmentInput;
  twitterHandle?: string;
  twitterCardType?: string;
}

export interface GoogleSettingsInput {
  isEnable: boolean;
  tagManagerId: string;
}

export interface FacebookSettingsInput {
  isEnable?: boolean;
  appId?: string;
  pageId?: string;
}

export interface SettingsOptions {
  siteTitle?: string;
  siteSubtitle?: string;
  currency?: string;
  paymentGateway?: any;
  defaultPaymentGateway?: string;
  useOtp?: boolean;
  useAi?: boolean;
  contactDetails?: ContactDetails;
  minimumOrderAmount?: number;
  currencyToWalletRatio?: number;
  signupPoints?: number;
  maxShopDistance?: number;
  maximumQuestionLimit?: number;
  deliveryTime?: DeliveryTime[];
  logo?: Attachment;
  taxClass?: string;
  shippingClass?: string;
  seo?: SeoSettings;
  google?: GoogleSettings;
  facebook?: FacebookSettings;
  useEnableGateway?: boolean;
  currencyOptions?: SettingCurrencyOptions;
  guestCheckout: boolean;
  smsEvent?: SmsEvent;
  emailEvent?: EmailEvent;
  server_info?: ServerInfo;
}

export interface ServerInfo {
  max_execution_time?: string;
  max_input_time?: string;
  memory_limit?: string;
  post_max_size?: number;
  upload_max_filesize?: number;
}

export interface SettingsOptionsInput {
  siteTitle?: string;
  siteSubtitle?: string;
  currency?: string;
  useOtp?: boolean;
  useAi?: boolean;
  defaultAi?: any;
  freeShipping?: boolean;
  useCashOnDelivery?: boolean;
  paymentGateway?: any;
  defaultPaymentGateway?: string;
  contactDetails?: ContactDetailsInput;
  minimumOrderAmount?: number;
  freeShippingAmount?: number;
  currencyToWalletRatio?: number;
  signupPoints?: number;
  maxShopDistance?: number;
  maximumQuestionLimit?: number;
  deliveryTime?: DeliveryTimeInput[];
  logo?: AttachmentInput;
  taxClass?: string;
  shippingClass?: string;
  seo?: SeoSettingsInput;
  google?: GoogleSettingsInput;
  facebook?: FacebookSettingsInput;
  currencyOptions?: SettingCurrencyOptions;
  useEnableGateway?: boolean;
  guestCheckout: boolean;
  smsEvent?: SmsEvent;
  emailEvent?: EmailEvent;
  server_info?: ServerInfo;
}

export interface SmsEvent {
  admin?: SmsAdminEvent;
  vendor?: SmsVendorEvent;
  customer?: SmsCustomerEvent;
}

export interface SmsAdminEvent {
  createOrder?: boolean;
  deliverOrder?: boolean;
  cancelOrder?: boolean;
  statusChangeOrder?: boolean;
  refundOrder?: boolean;
}

export interface SmsCustomerEvent {
  createOrder?: boolean;
  deliverOrder?: boolean;
  cancelOrder?: boolean;
  statusChangeOrder?: boolean;
  refundOrder?: boolean;
}

export interface SmsVendorEvent {
  createOrder?: boolean;
  deliverOrder?: boolean;
  cancelOrder?: boolean;
  statusChangeOrder?: boolean;
  refundOrder?: boolean;
}

export interface EmailEvent {
  admin?: EmailAdminEvent;
  vendor?: EmailVendorEvent;
  customer?: EmailCustomerEvent;
}
export interface EmailAdminEvent {
  createOrder?: boolean;
  deliverOrder?: boolean;
  cancelOrder?: boolean;
  statusChangeOrder?: boolean;
  refundOrder?: boolean;
}
export interface EmailCustomerEvent {
  createOrder?: boolean;
  deliverOrder?: boolean;
  cancelOrder?: boolean;
  statusChangeOrder?: boolean;
  refundOrder?: boolean;
}
export interface EmailVendorEvent {
  createOrder?: boolean;
  deliverOrder?: boolean;
  cancelOrder?: boolean;
  statusChangeOrder?: boolean;
  refundOrder?: boolean;
}

export interface DeliveryTime {
  description?: string;
  title?: string;
}

export interface DeliveryTimeInput {
  title: string;
  description: string;
}

export interface ContactDetailsInput {
  socials?: ShopSocialInput[];
  contact?: string;
  location?: LocationInput;
  website?: string;
}

export interface RegisterInput {
  email: string;
  password: string;
  name: string;
  shop_id?: number;
  permission: Permission;
}

export interface ChangePasswordInput {
  oldPassword: string;
  newPassword: string;
}

export interface ForgetPasswordInput {
  email: string;
}

export interface VerifyForgetPasswordTokenInput {
  token: string;
  email: string;
}

export interface ResetPasswordInput {
  token: string;
  email: string;
  password: string;
}

export declare interface MakeAdminInput {
  user_id: string;
}

export interface BlockUserInput {
  id: number;
}

export interface WalletPointsInput {
  customer_id: string;
  points: number;
}

export declare type AddStaffInput = {
  email: string;
  password: string;
  name: string;
  shop_id: number;
};

export declare type ApproveShopInput = {
  id: string;
  admin_commission_rate: number;
};

export interface LocationInput {
  lat?: number;
  lng?: number;
  street_number?: string;
  route?: string;
  street_address?: string;
  city?: string;
  state?: string;
  country?: string;
  zip?: string;
  formattedAddress?: string;
}

export interface ShopSettingsInput {
  socials?: ShopSocialInput[];
  contact?: string;
  location?: LocationInput;
  website?: string;
}

export interface ShopInput {
  name: string;
  description?: string;
  cover_image?: AttachmentInput;
  logo?: AttachmentInput;
  address?: UserAddressInput;
  settings?: ShopSettingsInput;
  categories?: Category[];
  balance?: BalanceInput;
}

export declare type Question = {
  id: string;
  user_id: number;
  product_id: number;
  shop_id: number;
  question?: string;
  answer: string;
  created_at: string;
  updated_at: string;
  positive_feedbacks_count?: number;
  negative_feedbacks_count?: number;
  product: Product;
  user: User;
};

export interface TypeSettingsInput {
  isHome?: boolean;
  layoutType?: string;
  productCard?: string;
}

export interface ReplyQuestion {
  question?: string;
  answer: string;
}

export interface TypeSettings {
  isHome?: boolean;
  layoutType?: string;
  productCard?: string;
}

export interface UserAddressUpsertInput {
  title: string;
  default?: boolean;
  address: UserAddressInput;
  type: string;
}

export interface SocialInput {
  type?: string;
  link?: string;
}

export interface UserProfileInput {
  id: string;
  avatar?: AttachmentInput;
  bio?: string;
  socials?: SocialInput[];
  contact?: string;
}

export interface CategoryQueryOptions extends QueryOptions {
  type: string;
  name: string;
  parent: number | null;
}

export interface ConversationQueryOptions extends QueryOptions {
  search?: string;
}

export interface TagQueryOptions extends QueryOptions {
  type: string;
  name: string;
  parent: number | null;
}

export interface InvoiceTranslatedText {
  subtotal: string;
  discount: string;
  tax: string;
  delivery_fee: string;
  total: string;
  products: string;
  quantity: string;
  invoice_no: string;
  date: string;
}

export interface GenerateInvoiceDownloadUrlInput {
  order_id: string;
  translated_text?: InvoiceTranslatedText;
  is_rtl: boolean;
}

export interface AttributeQueryOptions extends QueryOptions {
  type: string;
  name: string;
  shop_id: string;
}

export interface AttributeValueQueryOptions extends QueryOptions {
  type: string;
  name: string;
}

export interface TaxQueryOptions extends QueryOptions {
  name: string;
}

export interface ShippingQueryOptions extends QueryOptions {
  name: string;
}

export interface AuthorQueryOptions extends QueryOptions {
  type: string;
  name: string;
  is_approved?: boolean;
}

export interface TypeQueryOptions extends QueryOptions {
  name: string;
}

export interface ProductQueryOptions extends QueryOptions {
  type: string;
  name: string;
  categories: string;
  tags: string;
  author: string;
  price: string;
  manufacturer: string;
  status: string;
  is_active: string;
  shop_id: string;
  min_price: string;
  max_price: string;
  rating: string;
  question: string;
}

export interface UserQueryOptions extends QueryOptions {
  name: string;
}

export interface ManufacturerQueryOptions extends QueryOptions {
  shop_id: string;
  name: string;
  is_approved: boolean;
  type: string;
}

export interface OrderStatusQueryOptions extends QueryOptions {
  name: string;
}

export interface StaffQueryOptions extends Omit<QueryOptions, "language"> {
  shop_id: string;
}

export interface WithdrawQueryOptions extends Omit<QueryOptions, "language"> {
  name: string;
  shop_id: string;
  parent: number | null;
}

export interface OrderQueryOptions extends QueryOptions {
  type: string;
  name: string;
  shop_id: string;
  tracking_number: string;
}

export interface CouponQueryOptions extends QueryOptions {
  code: string;
}
export interface StoreNoticeQueryOptions extends QueryOptions {
  notice: string;
  shop_id: string;
}

export interface MessageQueryOptions extends QueryOptions {
  slug: string;
}

export interface QuestionQueryOptions extends Omit<QueryOptions, "language"> {
  name: string;
  type: string;
  shop_id: string;
  product_id: number;
  answer: string;
}

export interface ReviewQueryOptions extends Omit<QueryOptions, "language"> {
  name: string;
  type: string;
  shop_id: string;
  product_id: number;
}

export interface ShopQueryOptions extends Omit<QueryOptions, "language"> {
  name: string;
  parent: number | null;
}

export interface GoogleMapLocation {
  lat?: number;
  lng?: number;
  street_number?: string;
  route?: string;
  street_address?: string;
  city?: string;
  state?: string;
  country?: string;
  zip?: string;
  formattedAddress?: string;
}
export interface SettingCurrencyOptions {
  formation?: string;
  fractions?: number;
}

export interface GenerateDescriptionInput {
  prompt: string;
}

export interface ItemProps {
  id: number;
  title: string;
}

export interface ShopPaginator extends PaginatorInfo<Shop> {}

export interface WithdrawPaginator extends PaginatorInfo<Withdraw> {}

export interface UserPaginator extends PaginatorInfo<User> {}

export interface QuestionPaginator extends PaginatorInfo<Question> {}

export interface StaffPaginator extends PaginatorInfo<User> {}

export interface OrderPaginator extends PaginatorInfo<Order> {}

export interface CouponPaginator extends PaginatorInfo<Coupon> {}

export interface StoreNoticePaginator extends PaginatorInfo<StoreNotice> {}

export interface ProductPaginator extends PaginatorInfo<Product> {}

export interface CategoryPaginator extends PaginatorInfo<Category> {}

export interface TaxPaginator extends PaginatorInfo<Tax> {}

export interface ReviewPaginator extends PaginatorInfo<Review> {}

export interface TagPaginator extends PaginatorInfo<Tag> {}

export interface AttributePaginator extends PaginatorInfo<Attribute> {}

export interface AttributeValuePaginator
  extends PaginatorInfo<AttributeValue> {}

export interface ShippingPaginator extends PaginatorInfo<Shipping> {}

export interface AuthorPaginator extends PaginatorInfo<Author> {}

export interface ManufacturerPaginator extends PaginatorInfo<Manufacturer> {}

export interface OrderStatusPaginator extends PaginatorInfo<OrderStatus> {}

export interface ConversionPaginator extends PaginatorInfo<Conversations> {}

export interface MessagePaginator extends PaginatorInfo<Message> {}

export interface MainResponse {
  final_result_code: string;
  general_result: string;
}

export interface LoginResponse {
  token: string;
}
export interface AuthResponse extends MainResponse {
  captured_data: {
    email: string;
    first_name: string;
    last_name: string;
    email_confirmed: boolean;
    mobile_confirmed: boolean;
  };
}

export interface UserShowResponse extends MainResponse {
  captured_data: {
    email: string;
    first_name: string;
    last_name: string;
    email_confirmed: boolean;
    mobile_confirmed: boolean;
  };
}
export interface VerifyEmailResponse extends MainResponse {
  captured_data: string;
  technical_result: string;
}

export interface ResetPasswordResponse extends MainResponse {
  captured_data: string;
  technical_result: string;
}

export interface VerifyEmailInput {
  email?: string;
  password_verification_code: string;
}

export interface ForgotPasswordUserInput {
  email: string;
}

export interface ResetPasswordUserInput {
  email: string;
  new_password: string;
  password_verification_code: string;
}

export interface VerifyEmailInput {
  email?: string;
  password_verification_code: string;
}

export interface ForgotPasswordUserInput {
  email: string;
}

export interface RegisterUserInput {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}

export interface LoginUserInput {
  username: string;
  password: string;
}
export interface UserMEInput {
  email: string;
}

export interface BusinessList {
  email: string;
}

export interface BusinessDetailList {
  businessdetail_id: string;
}

export interface BusinessDetailItem {
  business_id: number;
  business_name: string;
  is_busniess_details_update_by_admin_done: boolean;
  is_interview_done: boolean;
  is_interview_passed_or_failed: boolean;
  is_registry_process_completed: boolean;
  is_branch_info_completed: boolean;
  is_banking_info_confirmed: boolean;
}

export interface BusinessDetailListResponse extends MainResponse {
  captured_data: BusinessDetailItem;
}

export interface BusinessItem {
  business_id: number;
  business_name: string;
}

export interface BusinessListResponse extends MainResponse {
  captured_data: BusinessItem[];
}
export interface BusinessDetailByIdShow {
  businessdetail_id: number;
}

export interface BusinessDetailByIdItem {
  business_id: number;
  business_name: string;
  is_busniess_details_update_by_admin_done: boolean;
  is_sector_info_completed: boolean;
  country: string;
  business_type: string;
  vat_registration_number_tax: string;
  is_interview_done: boolean;
  is_interview_passed_or_failed: boolean;
  is_registry_process_completed: boolean;
  is_branch_info_completed: boolean;
  is_banking_info_confirmed: boolean;
  is_active: boolean;
  is_locked: boolean;
  business_logo_url?: string;
  accepted_to_get_updates_via_sms_email?: boolean;
}

export interface BusinessDetailByIdListResponse extends MainResponse {
  captured_data: BusinessDetailByIdItem;
}

export interface BusinessDetailCreate {
  business_name: string;
  country: string;
  business_initial_admin_user: string;
  accepted_to_get_updates_via_sms_email: boolean;
  vat_registration_number_tax: string;
  business_type: string;
  email: string;
  business_phone: string;
  first_name: string;
  last_name: string;
  group: string;
}

export interface BusinessDetailCreateResponse extends MainResponse {
  captured_data: string;
  Business_Id: number;
}

export interface BusinessDetailUpdate {
  businessdetail_id: number;
  business_name: string;
  country: string;
  business_initial_admin_user: string;
  accepted_to_get_updates_via_sms_email: string;
  vat_registration_number_tax: string;
  business_type: string;
  email: string;
  business_phone?: string;
  first_name?: string;
  last_name?: string;
  group: string;
}

export interface BusinessDetailUpdateResponse extends MainResponse {
  captured_data: any;
}

export interface BusinessUserCreate {
  email: string;
  business_phone: string;
  first_name: string;
  last_name: string;
  role: string;
  group: string;
  businessdetail_id: number;
}

export interface BusinessUserDelete {
  email: string;
  email_for_delete: string;
  businessdetail_id: number;
}

export interface BranchLocationFindAddress {
  postcode: string;
}

export interface BranchLocationCreate {
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  county: string;
  postcode: string;
  stateOrProvince: string;
  location_name: string;
  locationWebUrl: string;
  LocationStatus: string;
  open_time: string;
  close_time: string;
  phone: string;
  businessdetail_id: string;
}

export interface BranchLocationFindAddressResponse extends MainResponse {
  captured_data?: any;
}

export interface BranchLocationItem {
  location_id: number;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  county: string;
  postcode: string;
  location_name: string;
  LocationStatus: string;
  stateOrProvince: string;
  open_time: string;
  close_time: string;
  phone: string;
}

export interface BranchLocationListResponse extends MainResponse {
  captured_data?: BranchLocationItem[];
}

export interface BranchLocationDeleteResponse extends MainResponse {
  captured_data?: string;
}

export interface BusinessBranchLocationDeleteItem {
  location_id: number;
  businessdetail_id: number;
}

export interface BusinessUserShow {
  businessdetail_id: number;
}

export interface BusinessDocumentShow {
  businessdetail_id: number;
  file_name?: string;
}

export interface BusinessLogoShow {
  businessdetail_id: number;
}

export interface BusinessTeamMemberCreateUserResponse extends MainResponse {
  captured_data: string;
}

export interface BusinessTeamMemberDeleteUserResponse extends MainResponse {
  captured_data?: string;
}
// {
//     "email": "mail@m1.com",
//     "first_name": "-",
//     "last_name": "-",
//     "role": "buyer,admin",
//     "group": "seller",
//     "user_created_at": "2023-10-24T14:13:15.895978Z"
// },

export interface BusinessTeamMemberUserShowItem {
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  group: string;
  user_created_at: string;
}
export interface BusinessTeamMemberUserShowResponse extends MainResponse {
  captured_data: BusinessTeamMemberUserShowItem[];
}

export interface DocumentCreate {
  image: Blob;
  businessdetail_id: number;
  picture_name: string;
}

export interface fileUrl {
  url: string;
  title: string;
}

export interface BusinessDocumentItem {
  file_id: number;
  file_name: string;
  file_urls: fileUrl[];
  url_type: string;
  selected?: boolean;
}

export interface BusinessDocumentListResponse extends MainResponse {
  captured_data?: BusinessDocumentItem[];
}

export interface BusinessLogoListResponse extends MainResponse {
  captured_data?: any;
}

export interface BusinessDocumentDeleteItem {
  file_name: string;
  businessdetail_id: number;
}

export interface BusinessLogoDeleteItem {
  businessdetail_id: number;
}

export interface BankCardCreate {
  businessdetail_id: string;
  name_on_card: string;
  card_number: string;
  exprired_year: string;
  exprired_month: string;
  cvc_cvv: string;
  card_type: string;
  card_alias_name: string;
}

export interface BankCardCreateResponse extends MainResponse {
  captured_data?: any;
}

export interface BankCardList {
  businessdetail_id: string;
  card_alias_name?: string;
}

export interface BankCardListResponse extends MainResponse {
  captured_data?: any[];
}

export interface BankCardDelete {
  businessdetail_id: string;
  bank_card_id: string;
}

export interface BankCardDeleteResponse extends MainResponse {
  captured_data?: any;
}

export interface BankAccountCreate {
  businessdetail_id: string;
  account_number: string;
  currency_type: string;
}

export interface BankAccountCreateResponse extends MainResponse {
  captured_data?: any;
}

export interface BankAccountList {
  businessdetail_id: string;
}

export interface BankAccountItem {
  account_number: string;
  currency_type: string;
}

export interface BankAccountListResponse extends MainResponse {
  captured_data?: BankAccountItem[];
}

export interface BankAccountDelete {
  businessdetail_id: string;
  account_number: string;
}

export interface BankAccountDeleteResponse extends MainResponse {
  captured_data?: any;
}

export interface CreatePaymentIntentReq {
  email: string;
  amount: number;
  currency: string;
}

export interface CreatePaymentIntentResponse extends MainResponse {
  clientSecret: string;
  "intent.status": string;
  "intent.id": string;
  "intent.customer": string;
  "intent.amount": number;
  "intent.currency": string;
}

export interface ConfirmPaymentIntentReq {
  intent_id: string;
  paymentMethodType: string;
}

export interface ConfirmPaymentIntentResponse extends MainResponse {
  captured_data: string;
  "intent.status": string;
}

export interface RetrievePaymentIntentReq {
  intent_id: string;
}

export interface RetrievePaymentIntentResponse extends MainResponse {
  captured_data: any;
  "intent.id": string;
  "intent.client_secret": string;
  "intent.status": string;
  "intent.amount": number;
  currency: string;
}

export interface ConfigPaymentResponse {
  publishableKey: string;
}

export interface CountryItem {
  Alpha3Code: string;
  Alpha2Code: string;
  CountryName: string;
}

export interface CurrencyItem {
  CurrencyCode: string;
  Title: string;
  Symbol: string;
  CountryAlpha_3Code: string;
  CountryName: string;
}

export interface ServiceTypeForBusinessItem {
  service_id: number;
  service_title: string;
  service_description: string;
}

export interface ServiceTypeForBusinessListResponse extends MainResponse {
  captured_data?: ServiceTypeForBusinessItem[];
}

export interface SectorShowItem {
  sector_id: number;
  sector_title: string;
}

export interface SectorShowListResponse extends MainResponse {
  captured_data?: SectorShowItem[];
}

export interface JobCategoriesItem {
  category_id: string;
  category_name: string;
}

export interface JobCategoriesListResponse extends MainResponse {
  captured_data?: JobCategoriesItem[];
}

export interface JobTitleRequest {
  category_id: string;
}
export interface JobTitleItem {
  service_id: number;
  job_name: string;
}
export interface JobTitleItem {
  service_id: number;
  job_name: string;
}

export interface JobTitleListResponse extends MainResponse {
  captured_data?: JobTitleItem[];
}

export interface ServiceTypesResponse {
  businessdetail_id: number;
  service_1: boolean;
  service_2: boolean;
  service_3: boolean;
}
export interface InPersonService {
  category: string;
  in_person_service_name: string;
}

export interface OnlineProductSector {
  sector_id: number;
  sector_title: string;
}

export interface BusinessSectorShowResponse extends MainResponse {
  in_person_services: InPersonService[];
  online_products_sectors: OnlineProductSector[];
  service_types: ServiceTypesResponse[];
}

export interface BusinessSectorShowRequest {
  businessdetail_id: number;
}

export interface BusinessSectorCreateRequest {
  businessdetail_id: string;
  service_1: string;
  service_2: string;
  service_3: string;
  service_4: string;
  contract_id: string;
  sector_ids_str: string;
  service_id: string;
  job_name: string;
}

export interface ProductManagementPolicyList {
  businessdetail_id: string;
  page: number;
  size: number;
  policyName?: string;
  policyType?: string;
}

export interface ProductManagementPolicyItem {
  file_id: number;
  file_name: string;
  file_urls: FileUrlItem;
  url_type: string;
  policy_type: string;
  _url: string;
  selected?: boolean;
}
export interface ProductManagementPolicyListResponse extends MainResponse {
  captured_data: ProductManagementPolicyItem[];
}

export interface ProductManagementPolicyTypeList {}

export interface ProductManagementPolicyTypeItem {
  policy_id: string;
  policy_description: string;
}

export interface ProductManagementPolicyTypeListResponse extends MainResponse {
  captured_data: ProductManagementPolicyTypeItem[];
}

export interface ProductManagementPolicyCreateResponse extends MainResponse {
  captured_data: any;
}

export interface ProductManagementPolicyDelete {
  businessdetail_id: string;
  file_name: string;
}
export interface ProductManagementPolicyTextDelete {
  businessdetail_id: string;
  policy_details_id: string;
}
export interface ProductManagementPolicyDeleteResponse extends MainResponse {
  captured_data: any;
}

export interface ProductManagementImageList {
  businessdetail_id: string;
  page: number;
  size: number;
  product_image_name?: string;
}

export interface FileUrlItem {
  url: string;
  title: string;
}

export interface ProductManagementImage {
  file_id: number;
  file_name: string;
  file_urls: FileUrlItem[];
  url_type: string;
  _url: string;
  selected?: boolean;
}
export interface ProductManagementImageListResponse extends MainResponse {
  captured_data: ProductManagementImage[];
  total_count?: number;
}

export interface ProductManagementImageDelete {
  businessdetail_id: string;
  file_name: string;
}

export interface ProductManagementVideoList {
  businessdetail_id: string;
  page: number;
  size: number;
  product_video_name?: string;
}

export interface ProductManagementVideoItem {
  file_id: number;
  file_name: string;
  file_urls: FileUrlItem[];
  url_type: string;
  _url: string;
  selected?: boolean;
}
export interface ProductManagementVideoListResponse extends MainResponse {
  captured_data: ProductManagementVideoItem[];
  total_count?: number;
}

export interface ProductManagementVideoDelete {
  businessdetail_id: string;
  file_name: string;
}

export interface ProductManagementBrandListReq {
  brand_name: string;
}

export interface ProductManagementInventoryBrandItem {
  brand_id: number;
  category: string;
  brand_name: string;
}
export interface ProductManagementInventoryBranchListResponse
  extends MainResponse {
  captured_data: ProductManagementInventoryBrandItem[];
}

export interface ProductManagementInventoryProductConditionItem {
  condition_id: number;
  condition_title: string;
}
export interface ProductManagementInventoryProductConditionListResponse
  extends MainResponse {
  captured_data: ProductManagementInventoryProductConditionItem[];
}

export interface ProductManagementListReq {
  businessdetail_id: string;
  page: number;
  size: number;
  sku_code?: string;
  product_condition_id?: number;
  product_condition_description?: string;
  brand_id?: number;
  brand?: string;
}

export interface ProductManagementInventoryItem {
  product_inventory_id: number;
  sku_code: string;
  product_condition_id: number;
  product_condition_description: string;
  brand_id: number | null;
  brand: string;
  product_title: string;
  offer_status: string;
  _updated_at: string;
  selected?: boolean;
}

export interface ProductManagementInventoryListResponse extends MainResponse {
  captured_data: ProductManagementInventoryItem[];
}

export interface ProductManagementProductAspectItem {
  Size: string;
  Color: string;
  Size_Type: string;
}
export interface FulFillMentTimeModel {
  unit: string;
  value: number;
}
export interface ProductManagementPickupAtLocationAvailability {
  branchLocationid: number;
  availabilityType: string;
  quantity: number;
  fulfillmentTime: FulFillMentTimeModel;
}
export interface ProductManagementAvailabilityDistributions {
  branchLocationid: number;
  availabilityType: string;
  quantity: number;
  fulfillmentTime: FulFillMentTimeModel;
}

export interface ProductManagementShipToLocationAvailability {
  availabilityDistributions: ProductManagementAvailabilityDistributions[];
}

export interface ProductManagementInventoryAvailability {
  totalQuantity: number;
  pickupAtLocationAvailability: ProductManagementPickupAtLocationAvailability[];
  shipToLocationAvailability: ProductManagementShipToLocationAvailability;
}

export interface DimensionsModel {
  height: number;
  width: number;
  length: number;
  unit: string;
}
export interface WeightModel {
  value: number;
  unit: string;
}
export interface ProductManagementInventoryPackageWeightAndSize {
  dimensions: DimensionsModel;
  weight: WeightModel;
  packageType: string;
}
export interface ProductManagementInventoryCreateOrUpdateReq {
  product_inventory_id?: number;
  email: string;
  businessdetail_id: string;
  sku_code: string;
  product_condition_id: number;
  product_condition_description: string;
  //product_condition_additional_info: info new updated,
  brand_id: number;
  brand: string;
  product_description: string;
  product_title: string;
  product_subtitle: string;
  ean: string;
  isbn: string;
  mpn: string;
  upc: string;
  product_image_urls: string[];
  product_video_urls: string[];
  product_aspects: any;
  availability: ProductManagementInventoryAvailability;
  packageweightandsize: ProductManagementInventoryPackageWeightAndSize;
}

export interface ProductManagementInventoryCreateOrUpdateResponse
  extends MainResponse {
  captured_data: any;
}

export interface AvailabilityTypeModel {
  value: string;
  title: string;
  id?: number;
}

export interface PickUpAtLocationListItemModel {
  id: string;
  branch?: BranchLocationItem;
  availabilityType?: AvailabilityTypeModel;
  quantity: string;
  fulfillmentValue: string;
  unit?: AvailabilityTypeModel;
}

export interface PackageDeliveryTypeItem {
  Package_Type_id: number;
  Package_Type_name: string;
  Package_Type_description: string;
}

export interface ProductManagementInventoryPackageDeliveryTypeResponse
  extends MainResponse {
  captured_data: PackageDeliveryTypeItem[];
}

export interface ProductManagementInventoryDeleteReq {
  businessdetail_id: string;
  sku_code: string;
}

export interface ProductManagementInventoryDeleteResponse extends MainResponse {
  captured_data: any;
}

export interface ProductManagementMarketPlaceReq {
  marketplace_id?: string;
}

export interface ProductManagementMarketPlaceListItem {
  marketplace_id: string;
  marketplace_name: string;
}

export interface ProductManagementMarketPlaceListResponse extends MainResponse {
  captured_data?: ProductManagementMarketPlaceListItem[];
}

export interface ProductManagementOfferListReq {
  page: number;
  size: number;
  businessdetail_id: string;
  marketplaceId: string;
  sku_code?: string;
  offer_id?: number;
}

export interface ProductManagementOfferListItem {
  offer_id: number;
  sku_code: string;
  marketplaceId: string;
  listing_Duration: string;
  listing_status: string;
  listing_StartDate: string;
  price_value: string;
  price_currency: string;
  category_1_name: string;
  category_2_name: string;
  category_3_name: string;
  updated_at: string;
}

export interface ProductManagementOfferListResponse extends MainResponse {
  captured_data?: ProductManagementOfferListItem[];
  total_count?: number;
}

export interface ProductManagementCategoryReq {
  category_id?: string;
  category_name?: string;
  level?: number;
  parent?: string;
}

export interface ProductManagementCategoryItemBase {
  category_id: string;
  category_name: string;
}

export interface ProductManagementCategoryItem
  extends ProductManagementCategoryItemBase {
  level: number;
  parent: string | null;
}

export interface ProductManagementCategoryListResponse extends MainResponse {
  captured_data?: ProductManagementCategoryItem[];
}

export interface Price {
  currency: string;
  value: number;
}
export interface PricingSummary {
  price: Price;
}

export interface ProductManagementOfferCreateOrUpdateReq {
  offer_id?: number;
  email: string;
  businessdetail_id: string;
  sku_code: string;

  marketplaceId: string;
  offer_format: string;
  product_available_Quantity: number;

  categoryId_level1?: string;
  categoryId_level2?: string;
  categoryId_level3?: string;

  pricingSummary?: PricingSummary;
  listing_Description: string;
  listing_Duration: string;

  listing_StartDate?: string; //2023-12-15 YYYY-MM-DD  , optional
  listingPolicies?: any;
  quantityLimitPerBuyer?: string;

  regulatory?: string;
  tax: {
    applyTax: boolean;
    vatPercentage: number;
  };
}

export interface ProductManagementOfferCreateOrUpdateResponse
  extends MainResponse {
  captured_data: any;
}

export interface ProductManagementInventoryDetailsReq {
  businessdetail_id: string;
  sku_code?: string;
}

export interface ProductManagementInventoryDetails {
  product_inventory_id: number;
  sku_code: string;
  product_condition_description?: string;
  product_condition_additional_info?: any;
  product_description?: string;
  product_title?: string;
  product_subtitle?: string;
  ean?: string;
  isbn?: string;
  mpn?: string;
  upc?: string;
  brand_id?: number;
  brand?: string;
  product_image_urls?: string[];
  product_video_urls?: string[];
  product_aspects?: any;
  availability?: ProductManagementInventoryAvailability;
  packageweightandsize?: ProductManagementInventoryPackageWeightAndSize;
  offer_status?: string;
  _updated_at?: string;
}

export interface ProductManagementInventoryDetailsResponse
  extends MainResponse {
  captured_data: ProductManagementInventoryDetails;
}

export interface ProductManagementOfferDetailsReq {
  businessdetail_id: string;
  sku_code?: string;
  offer_id?: number;
}

// email: string;
//     businessdetail_id: string;
//     product_available_Quantity: number;

//     pricingSummary?: PricingSummary;
//     listing_Description: string;
//     listing_Duration: string;

//     listing_StartDate?: string; //2023-12-15 YYYY-MM-DD  , optional
//     listingPolicies?: any;
//     quantityLimitPerBuyer?: string;

//     regulatory?: string;
//     tax: {
//         applyTax: boolean;
//         vatPercentage: number;
//     }

export interface ProductManagementOfferDetails {
  offer_id: number;
  sku_code: string;
  marketplaceId: string;
  offer_format: string;
  categoryId_level1: string;
  categoryId_level2: string;
  categoryId_level3: string;
  listing_Duration: string;
  listing_StartDate: string;
  listingPolicies: any;
  quantityLimitPerBuyer: string;
  listing_Description: string;
  product_available_Quantity: number;
  regulatory: {
    statements?: [
      {
        statementId: string;
        statementDescription: string;
      }
    ];
    repairScore: string;
    signalWords?: [
      {
        signalWordId: string;
        signalWordDescription: string;
      }
    ];
    energyEfficiencyLabel?: {
      imageURL: string;
      imageDescription: string;
    };
  };

  tax: {
    applyTax: boolean;
    vatPercentage: number;
  };
  listing_status: string;
  price_value: string;
  price_currency: string;
  _updated_at: string;
}

export interface ProductManagementOfferDetailsResponse extends MainResponse {
  captured_data: any;
}

export interface ProductListingItemsReq {
  page: number;
  size: number;
  businessdetail_id: string;
  marketplaceId: string;
  sku_code?: string;
  sku_group_code?: string;
  offer_id?: number;
}

export interface ProductManagementListingListItem {
  listing_id: number;
  offer_id: number;
  sku_code: string;
  marketplaceId: string;
  sku_group_code: string | null;
  brand: string;
  product_title: string;
  price_value: string;
  currency: string;
  _updated_at: string;
}

export interface ProductManagementListingResponse extends MainResponse {
  captured_data?: ProductManagementListingListItem[];
  total_count?: number;
}

export interface DescriptionGeneratorReq {
  email: string;
  businessdetail_id: string;
  input: string;
}

export interface DescriptionGeneratorResponse extends MainResponse {
  captured_data?: any;
}

export interface ProductListingPublishByOfferCreateOrUpdateReq {
  email: string;
  businessdetail_id: string;
  sku_code: string;

  offer_format: string;
  marketplaceId?: string;
  offer_id?: number;
}

export interface ProductListingPublishByOfferCreateOrUpdateResponse
  extends MainResponse {
  captured_data: any;
}

export interface ProductListingUnListReq {
  email: string;
  businessdetail_id: string;
  sku_code: string;

  offer_format: string;
  marketplaceId?: string;
  listing_id?: number;
}

export interface ProductListingUnListResponse extends MainResponse {
  captured_data: any;
}

export interface ProductManagementInventoryGroupListReq {
  businessdetail_id: string;
  page: number;
  size: number;
  sku_group_code?: string;
}

export interface ProductManagementInventoryGroupItem {
  product_inventory_group_id: number;
  sku_group_code: string;
  brand_id: number | null;
  brand: string | null;
  product_title: string;
  _updated_at: string;
  selected?: boolean;
}

export interface ProductManagementInventoryGroupListResponse
  extends MainResponse {
  captured_data: ProductManagementInventoryGroupItem[];
}

export interface ProductManagementInventoryGroupSpecificationsItem {
  name: string;
  values: string[];
}
export interface ProductManagementInventoryGroupVariesBy {
  aspectsImageVariesBy: string[];
  specifications: ProductManagementInventoryGroupSpecificationsItem[];
}

export interface ProductManagementInventoryGroupCreateOrUpdateReq {
  email: string;
  businessdetail_id: string;
  product_inventory_group_id?: number;
  sku_group_code: string;
  brand_id?: number;
  brand?: string;
  product_title: string;
  product_subtitle: string;
  product_description: string;
  variant_sku: string[]; // selected sku codes
  varies_by: ProductManagementInventoryGroupVariesBy;
}

export interface ProductManagementInventoryGroupCreateOrUpdateResponse
  extends MainResponse {
  captured_data: any;
}

export interface ProductManagementInventoryGroupDetailsReq {
  businessdetail_id: string;
  sku_group_code?: string;
}

export interface ProductManagementInventoryGroupDetails {
  product_inventory_group_id: number;
  sku_group_code: string;
  brand?: string | null;
  brand_id?: number | null;
  product_description: string;
  product_title: string;
  product_subtitle: string;
  product_image_urls?: any;
  product_video_urls?: any;
  product_aspects?: any;
  variant_sku: string[];
  varies_by: ProductManagementInventoryGroupVariesBy;
  _updated_at?: string;
}

export interface ProductManagementInventoryGroupDetailsResponse
  extends MainResponse {
  captured_data: ProductManagementInventoryGroupDetails;
}

export interface ProductManagementInventoryGroupDeleteReq {
  businessdetail_id: string;
  sku_group_code: string;
}

export interface ProductManagementInventoryGroupDeleteResponse
  extends MainResponse {
  captured_data: any;
}

export interface ProductListingPublishByOfferGroupCreateOrUpdateReq {
  email: string;
  businessdetail_id: string;
  sku_group_code: string;
  marketplaceId: string;
  offer_format: string;
}

export interface ProductListingPublishByOfferGroupCreateOrUpdateResponse
  extends MainResponse {
  captured_data: any;
}

export interface ManagementTeamItem {
  full_name: string;
  role: string;
}

export interface PartnerItem {
  partner_name: string;
  details: string;
}

export interface LicenseOfCertificateItem {
  licence_name: string;
  date_of_achievement: string;
  details: string;
  url: string;
}
export interface BusinessSetupBusinessProfileCreateOrUpdateReq {
  email: string;
  businessdetail_id: string;
  dedicated_web_address: string;
  story_sliders_urls: string[];
  about_business: string;
  experience_of_business: string;
  business_history: string;
  management_team: ManagementTeamItem[];
  partners: PartnerItem[];
  licence_or_certificate: LicenseOfCertificateItem[];
  web_layout_code: string;
}

export interface BusinessSetupBusinessProfileCreateOrUpdateResponse
  extends MainResponse {
  captured_data: any;
}

export interface LicenseOfCertificateListItem {
  documentItem: BusinessDocumentItem;
  licence_name: string;
  date_of_achievement: string;
  details: string;
  url: string;
}

export interface BusinessSetupBusinessProfileUrlAvailabilityReq {
  businessdetail_id: string;
  web_url: string;
}

export interface BusinessSetupBusinessProfileUrlAvailabilityResponse
  extends MainResponse {
  captured_data: any;
}

export interface BusinessSetupBusinessProfileByIdShowReq {
  businessdetail_id: string;
}

export interface BusinessSetupBusinessProfileByIdShowItem {
  bp_id: number;
  about_business: string;
  experience_of_business: string;
  business_history: string;
  management_team: ManagementTeamItem[];
  partners: PartnerItem[];
  licence_or_certificate: LicenseOfCertificateItem[];
  story_sliders_urls: string[];
  dedicated_web_address: string;
  business_type: string;
  business_logo_url: string | null;
  web_layout_code: string;
  _updated_at: string;
}

export interface BusinessSetupBusinessProfileByIdShowResponse
  extends MainResponse {
  captured_data: BusinessSetupBusinessProfileByIdShowItem;
  web_layouts_data: WebLayout[];
}

export interface BusinessInPersonServiceCreateReq {
  email: string;
  businessdetail_id: string;
  service_title: string;
  service_description: string;
  price: number;
  currency: string;
}

export interface BusinessInPersonServicesCreateResponse extends MainResponse {
  captured_data: any;
}

export interface BusinessInPersonServiceGridShowReq {
  businessdetail_id: string;
}

export interface BusinessInPersonServicesGridItem {
  binp_srv_id: number;
  service_title: string;
  price: number;
  currency: string;
  price_comment: string | null;
  row: number;
  _updated_at: string;
}

export interface BusinessInPersonServicesGridShowResponse extends MainResponse {
  captured_data: BusinessInPersonServicesGridItem[];
}

export interface BusinessInPersonServiceDeleteItemReq {
  email: string;
  businessdetail_id: string;
  binp_srv_id: number;
}

export interface BusinessInPersonServicesDeleteItemResponse
  extends MainResponse {
  captured_data: any;
}

export interface BusinessInPersonServiceByIdShowReq {
  businessdetail_id: string;
  binp_srv_id?: number;
}

export interface BusinessInPersonServicesByIdShowItem {
  binp_srv_id: number;
  service_title: string;
  price: number;
  currency: string;
  price_comment: string | null;
  _service_description: string;
  _updated_at: string;
}

export interface BusinessInPersonServicesByIdShowResponse extends MainResponse {
  captured_data: BusinessInPersonServicesByIdShowItem;
}

export interface ProductManagementPolicyTextCreateReq {
  businessdetail_id: string;
  policy_name: string;
  policy_type_id: string;
  marketplaceId: string;
  policy_details_text: string;
}

export interface ProductManagementPolicyTextCreateResponse
  extends MainResponse {
  captured_data: any;
}

export interface ProductManagementPolicyTextGetByIdReq {
  businessdetail_id: string;
  policy_details_id: string;
}

export interface ProductManagementPolicyTextGetByIdResponse
  extends MainResponse {
  captured_data: {
    policy_details_id: number;
    policy_name: string;
    policy_type_id: string;
    policy_details_text: string;
  };
}

export interface BusinessProfileRequestModel {
  web_url: string;
}

export interface BusinessProfileResponseModel extends MainResponse {
  business_profile: BusinessProfile;
  web_layout: WebLayout;
}

export interface BusinessProfile {
  bp_id: number;
  businessdetail_id: number;
  web_layout_code: string;
  about_business: string;
  experience_of_business: string;
  business_history: string;
  management_team: ManagementTeam[];
  partners: Partner[];
  licence_or_certificate: LicenceOrCertificate[];
  story_sliders_urls: string[];
  dedicated_web_address: string;
  business_type: string;
  business_logo_url: any;
}

export interface ManagementTeam {
  role: string;
  full_name: string;
}

export interface Partner {
  details: string;
  partner_name: string;
}

export interface LicenceOrCertificate {
  url: string;
  details: string;
  licence_name: string;
  date_of_achievement: string;
}

export interface WebLayout {
  web_layout_details_id: number;
  web_layout_code: string;
  web_layout_name: string;
  web_layout_group: string;
  type: string;
  web_layout_address1: string;
  web_layout_address2: string;
  web_layout_thumb_image_address1: string;
  marketplaceId: string;
}
