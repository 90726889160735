import { create } from "zustand";
import { persist } from "zustand/middleware";
import { BusinessItem } from "../types";

export interface AuthorizationUser {
    firstName?: string;
    lastName?: string;
    email: string;
    isConfirmEmail: boolean;
}

export interface UserState {
    user: AuthorizationUser | null;
    setUser: (user: AuthorizationUser) => void;
    latestBusinessIdSelected: number | null;
    setLatestBusinessIdSelected: (businessIdSelected: number) => void;
    businessItem: BusinessItem | null;
    setBusinessItem: (businessItem: BusinessItem) => void;
    registeredBusinessId: number;
    setRegisteredBusinessId: (registeredBusinessId: number) => void;
};

// const userStore = create<UserState>((set?: any) => ({
//     user: null,
//     setUser: (user: any) => set({ user }),
// }));

const userStore = create<UserState>()(
    persist(
        (set) => ({
            user: null,
            setUser: (prvUser: AuthorizationUser) => set((state) => ({ user: { ...state.user, ...prvUser } })),
            latestBusinessIdSelected: null,
            setLatestBusinessIdSelected: (businessIdSelected: number) => set((state) => ({ latestBusinessIdSelected: businessIdSelected })),
            registeredBusinessId: 0,
            setRegisteredBusinessId: (prvNum: number) => set((state) => ({ registeredBusinessId: prvNum })),
            businessItem: null,
            setBusinessItem: (prvBusinessItem: BusinessItem) => set((state) => ({ businessItem: { ...state.businessItem, ...prvBusinessItem } })),
        }),
        {
            name: 'user-storage',
        }
    )

)

export default userStore;
