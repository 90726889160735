import { useCallback, useEffect, useState } from "react";
import { Control, Controller, FieldErrors, useForm } from "react-hook-form";
import Label from "../ui/label";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import ValidationError from "../ui/form-validation-error";
import { useTranslation } from "react-i18next";
import SelectInput from "../ui/select-input";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    BranchLocationCreate,
    BranchLocationDeleteResponse,
    BranchLocationFindAddress,
    BranchLocationFindAddressResponse,
    BranchLocationItem,
    BranchLocationListResponse,
    Category,
    CountryItem,
    SortOrder,
} from "../../types";
import Input from "../ui/forms/input";
import Button from "../ui/button";
import { userStore } from "../../store";
import CountryList from "../../utils/constants/country.json";
import {
    useBusinessBranchLocationCreate,
    useBusinessBranchLocationDeleteItem,
    useBusinessBranchLocationFindAddress,
    useBusinessBranchLocationList,
} from "../../service/hook/business";
import { toast } from "react-toastify";
import { branchLocationValidationSchema } from "./branch-location-validation-schema";
import BranchLocationList from "./branch-location-list";
import { getCountryItem } from "../../utils/helper";
import { DatePicker } from "../ui/date-picker";
import { useModalAction } from "../../provider/modal.context";

function CountryRegion({
    control,
    errors,
    defaultValue,
    onChangeValue,
}: {
    control: Control<FormValues>;
    errors: FieldErrors<FormValues>;
    defaultValue: CountryItem;
    onChangeValue: (item: CountryItem) => void;
}) {
    const errorMessage: string = errors.country?.message as string;

    const { t } = useTranslation();
    return (
        <div className="mb-5">
            <Label>{t("form:input-label-country-region")}</Label>
            <SelectInput
                name="country"
                control={control}
                value={defaultValue}
                onChange={(value: CountryItem) => {
                    onChangeValue(value);
                }}
                getOptionLabel={(option: any) => option.CountryName}
                getOptionValue={(option: any) => option.Alpha3Code}
                options={CountryList!}
                isLoading={false}
            />
            <ValidationError message={errorMessage} />
        </div>
    );
}

function LocationStatus({
    control,
    errors,
    defaultValue,
    onChangeValue,
}: {
    control: Control<FormValues>;
    errors: FieldErrors<any>;
    defaultValue: any;
    onChangeValue: any;
}) {
    const { t } = useTranslation();
    const types = [
        {
            id: 1,
            name: "Enable",
            slug: "enable",
        },
        {
            id: 2,
            name: "Disable",
            slug: "disable",
        },
    ];
    return (
        <div className="mb-5">
            <Label>{t("form:input-label-location-status")}</Label>
            <SelectInput
                name="locationStatus"
                control={control}
                value={defaultValue}
                onChange={(value: any) => {
                    onChangeValue(value);
                }}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.slug}
                options={types!}
                isLoading={false}
            />
            {/* <ValidationError message={t(errors.locationStatus?.message ?? "")} /> */}
        </div>
    );
}

type FormValues = {
    addressLine1: string;
    addressLine2?: string;
    country?:
        | {
              Alpha3Code?: string;
              Alpha2Code?: string;
              CountryName?: string;
          }
        | undefined;
    city: string;
    countyOpt: string;
    postalCode: string;
    stateOrProvince: string;
    branchTitle: string;
    locationType: string;
    locationStatus?: {
        id: number;
        name: string;
        slug: string;
    };
    openTime: string;
    closeTime: string;
    phone: string;
};

const defaultValues = {
    addressLine1: "",
    addressLine2: "",
    country: undefined,
    city: "",
    countyOpt: "",
    postalCode: "",
    stateOrProvince: "",
    branchTitle: "",

    locationType: "",
    locationStatus: undefined,
    openTime: "",
    closeTime: "",
    phone: "",
};

const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
        async (data: any) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors: any) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors: any, currentError: any) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

type IProps = {
    initialValues?: Category | undefined;
};
export default function CreateOrUpdateBusinessBranchLocationForm({ initialValues }: IProps) {
    const { t } = useTranslation();
    const { businessItem } = userStore((state) => state);
    const [branchLocationList, setBranchLocationList] = useState<BranchLocationItem[]>();

    const [additionalInfo, setAdditionalInfo] = useState<boolean>(false);
    const [page, setPage] = useState(1);
    const [orderBy, setOrder] = useState("created_at");
    const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);
    const [visible, setVisible] = useState(false);
    const [countryItem, setCountryItem] = useState<any>();
    const [locationStatus, setLocationStatus] = useState<any>();

    const [startTime, setStartTiem] = useState();
    const [toTime, setToTime] = useState();

    const { openModal } = useModalAction();

    const {
        mutate: mutateBusinessBranch,
        isLoading: isLoadingBusinessBranch,
        serverError: serverErrorBusinessBranch,
        setServerError: setServerErrorErrorBusinessBranch,
    } = useBusinessBranchLocationFindAddress();

    const {
        mutate: mutateBusinessLocationCreate,
        isLoading: isLoadingBusinessLocationCreate,
        serverError: serverErrorBusinessLocationCreate,
        setServerError: setServerErrorErrorBusinessLocationCreate,
    } = useBusinessBranchLocationCreate();

    const {
        mutate: mutateBusinessLocationList,
        isLoading: isLoadingBusinessLocationList,
        serverError: serverErrorBusinessLocationList,
        setServerError: setServerErrorErrorBusinessLocationList,
    } = useBusinessBranchLocationList();

    const resolver = useYupValidationResolver(branchLocationValidationSchema);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        resolver,
    });

    useEffect(() => {
        setValue("locationStatus", {
            id: 1,
            name: "Enable",
            slug: "enabled",
        });
        setLocationStatus({
            id: 1,
            name: "Enable",
            slug: "enabled",
        });
        getBranchLocationList();
    }, [businessItem]);

    const getBranchLocationList = () => {
        mutateBusinessLocationList(
            {
                businessdetail_id: businessItem?.business_id ?? 0,
            },
            {
                onSuccess: (successData: BranchLocationListResponse) => {
                    if (successData.final_result_code === "000") {
                        try {
                            setBranchLocationList(successData.captured_data ?? []);
                        } catch (err) {
                            console.log("err => ", err);
                        }
                    } else {
                    }
                },
                onError: (error: Error) => {
                    const {
                        response: { data },
                    }: any = error ?? {};

                    toast.error(data?.general_result);
                    // closeModal();
                },
            }
        );
    };

    const branchLocationDeleteItem = (locationId: number) => {
        openModal("BranchLocation_Delete_Branch", {
            item: { locationId },
            callback: () => {
                getBranchLocationList();
            },
        });
    };

    function handlePagination(current: any) {
        setPage(current);
    }

    const onSubmit = async (values: FormValues) => {
        const input: BranchLocationCreate = {
            addressLine1: values.addressLine1 ?? "",
            addressLine2: values.addressLine2 ?? "",
            city: values.city ?? "",
            country: countryItem?.Alpha3Code ?? "",
            county: values.countyOpt ?? "",
            postcode: values.postalCode ?? "",
            stateOrProvince: values?.stateOrProvince ?? "",
            location_name: values.branchTitle ?? "",
            locationWebUrl: "#",
            LocationStatus: locationStatus?.slug?.toUpperCase() ?? "",
            open_time: values.openTime ?? "",
            close_time: values.closeTime ?? "",
            phone: values.phone ?? "",
            businessdetail_id: `${businessItem?.business_id ?? 0}`,
        };

        mutateBusinessLocationCreate(input, {
            onSuccess: (successData: any) => {
                if (successData.final_result_code === "000") {
                    try {
                        reset(defaultValues);
                        setCountryItem(undefined);
                        setLocationStatus(undefined);
                        getBranchLocationList();
                    } catch (err) {
                        console.log("err => ", err);
                    }

                    toast.success(successData.general_result);
                } else {
                    toast.error(successData.general_result);
                }
            },
            onError: (error: Error) => {
                const {
                    response: { data },
                }: any = error ?? {};

                toast.error(data?.general_result);
                // closeModal();
            },
        });
    };

    const findPostCode = () => {
        const input: BranchLocationFindAddress = {
            postcode: getValues("postalCode"),
        };
        // return false;
        mutateBusinessBranch(input, {
            onSuccess: (successData: BranchLocationFindAddressResponse) => {
                if (successData.final_result_code === "000") {
                    try {
                        const address = successData.captured_data;

                        getCountryItem(address?.country ?? "", (country: CountryItem) => {
                            setCountryItem(country);
                            setValue("country", country);
                        });

                        reset({
                            addressLine1: address?.addressLine1 ?? "",
                            addressLine2: address?.addressLine2 ?? "",
                            city: address?.city ?? "",
                            countyOpt: address?.county ?? "",
                            postalCode: address?.postcode ?? "",
                            stateOrProvince: address?.stateOrProvince ?? "",
                        });
                    } catch (err) {
                        console.log("err => ", err);
                    }

                    // toast.success(successData.general_result);
                } else {
                    // toast.error(successData.general_result);
                }
            },
            onError: (error: Error) => {
                const {
                    response: { data },
                }: any = error ?? {};

                toast.error(data?.general_result);
                // closeModal();
            },
        });
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div id={"branch-form"} className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        // title={t("form:form-title-business-branch-location")}
                        details={[
                            t("form:form-description-business-branch-location-01"),
                            t("form:form-description-business-branch-location-02"),
                        ]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />

                    <Card className="w-full sm:w-8/12 md:w-2/3">
                        <Input
                            label={t("form:input-label-address-line-1")}
                            {...register("addressLine1")}
                            error={t(errors.addressLine1?.message!)}
                            variant="outline"
                            className="mb-5"
                        />
                        <Input
                            label={t("form:input-label-address-line-2")}
                            {...register("addressLine2")}
                            error={t(errors.addressLine2?.message!)}
                            variant="outline"
                            className="mb-5"
                        />

                        <CountryRegion
                            control={control}
                            errors={errors}
                            defaultValue={countryItem}
                            onChangeValue={setCountryItem}
                        />

                        <Input
                            label={t("form:input-label-city")}
                            {...register("city")}
                            error={t(errors.city?.message!)}
                            variant="outline"
                            className="mb-5"
                        />

                        <Input
                            label={t("form:input-label-county-optional")}
                            {...register("countyOpt")}
                            error={t(errors.countyOpt?.message!)}
                            variant="outline"
                            className="mb-5"
                        />

                        <div
                            // className="mb-4 text-end"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "center",
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    marginRight: "5px",
                                }}
                            >
                                <Input
                                    label={t("form:input-label-postal-code")}
                                    {...register("postalCode")}
                                    error={t(errors.postalCode?.message!)}
                                    variant="outline"
                                    className="mb-5"
                                />
                            </div>

                            <Button
                                // loading={creating || updating}
                                onClick={() => {
                                    findPostCode();
                                }}
                                form={"post-code-form"}
                                size={"small"}
                                loading={isLoadingBusinessBranch}
                            >
                                {t("form:button-label-find")}
                                {/* {initialValues
                        ? t("form:button-label-update-category")
                        : t("form:button-label-add-category")} */}
                            </Button>
                        </div>

                        <Input
                            label={t("form:input-label-state-or-province")}
                            {...register("stateOrProvince")}
                            error={t(errors.stateOrProvince?.message!)}
                            variant="outline"
                            className="mb-5"
                        />

                        <Input
                            label={t("form:input-label-branch-title")}
                            {...register("branchTitle")}
                            error={t(errors.branchTitle?.message!)}
                            variant="outline"
                            className="mb-5"
                        />

                        <Label
                            onClick={() => {
                                setAdditionalInfo(!additionalInfo);
                            }}
                            style={{
                                cursor: "pointer",
                                fontSize: "1.2rem",
                            }}
                            className="sm:py-8 mt-8"
                        >
                            {`${t("form:input-label-additional-info")} ...`}
                        </Label>

                        {additionalInfo && (
                            <>
                                <Input
                                    label={t("form:input-label-location-type-optional")}
                                    {...register("locationType")}
                                    error={t(errors.locationType?.message!)}
                                    variant="outline"
                                    className="mb-5 mt-8"
                                />

                                <LocationStatus
                                    control={control}
                                    errors={errors}
                                    defaultValue={locationStatus}
                                    onChangeValue={setLocationStatus}
                                />

                                <Label>{t("form:input-label-open-time-optional")}</Label>
                                <Controller
                                    control={control}
                                    name="openTime"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        //@ts-ignore
                                        <DatePicker
                                            selected={startTime}
                                            onChange={(date: any) => {
                                                const selectedTime: string = `${date
                                                    .getHours()
                                                    .toString()
                                                    .padStart(2, "0")}:${date
                                                    .getMinutes()
                                                    .toString()
                                                    .padStart(2, "0")}`;

                                                onChange(selectedTime);
                                                setStartTiem(date);
                                            }}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm"
                                            timeFormat="HH:mm"
                                        />
                                    )}
                                />

                                <Label className="mt-6">
                                    {t("form:input-label-close-time-optional")}
                                </Label>
                                <Controller
                                    control={control}
                                    name="closeTime"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        //@ts-ignore
                                        <DatePicker
                                            selected={toTime}
                                            onChange={(date: any) => {
                                                const selectedTime: string = `${date
                                                    .getHours()
                                                    .toString()
                                                    .padStart(2, "0")}:${date
                                                    .getMinutes()
                                                    .toString()
                                                    .padStart(2, "0")}`;

                                                onChange(selectedTime);
                                                setToTime(date);
                                            }}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm"
                                            timeFormat="HH:mm"
                                        />
                                    )}
                                />

                                <Input
                                    label={t("form:input-label-phone-optional")}
                                    {...register("phone")}
                                    error={t(errors.phone?.message!)}
                                    variant="outline"
                                    className="mb-5 mt-8"
                                />
                            </>
                        )}
                    </Card>
                </div>

                <div className="mb-4 text-end">
                    <Button loading={isLoadingBusinessLocationCreate}>
                        {t("form:button-label-submit")}
                    </Button>
                </div>
            </form>

            <BranchLocationList
                branchLocationList={branchLocationList}
                paginatorInfo={null}
                onPagination={handlePagination}
                onOrder={setOrder}
                onSort={setColumn}
                onDeleteItem={(item: BranchLocationItem) => {
                    console.log("call delete service => ", item);
                    branchLocationDeleteItem(item.location_id);
                }}
            />
        </>
    );
}
