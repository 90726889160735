import { FC } from "react";
import { BusinessProfileResponseModel } from "../../../../../types";
import logo from "../logo.png";
import "./layout-style2.css";

interface IProps {
  businessProfileData: BusinessProfileResponseModel;
}

const SellerProfileLayout2: FC<IProps> = ({ businessProfileData }) => {
  return (
    <div className="seller-layout-2">
      <header>
        <div className="header-container">
          <div className="logo">
            <img src={logo} alt="Business Logo" />
            <span>Professional Business</span>
          </div>
          <nav>
            <ul>
              <li>
                <a href="#services">Services</a>
              </li>
              <li>
                <a href="#team">Team</a>
              </li>
              <li>
                <a href="#testimonials">Testimonials</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="hero">
          <h1>{businessProfileData.business_profile.dedicated_web_address}</h1>
          <p>Your trusted partner in growth and innovation.</p>
          <a href="#contact" className="cta-button">
            Get in Touch
          </a>
        </div>
      </header>

      <section id="services">
        <h2>Our Services</h2>
        <div className="services-grid">
          <div className="service">
            <h3>Consulting</h3>
            <p>
              Expert advice to help your business grow and adapt to the
              ever-changing market.
            </p>
          </div>
          <div className="service">
            <h3>Strategy</h3>
            <p>
              Comprehensive strategies tailored to your business needs for
              long-term success.
            </p>
          </div>
          <div className="service">
            <h3>Technology Solutions</h3>
            <p>
              Modern tech solutions to streamline operations and enhance
              productivity.
            </p>
          </div>
        </div>
      </section>

      <section id="team">
        <h2>Meet the Team</h2>
        <div className="team-grid">
          <div className="team-member">
            <h3>Jane Doe</h3>
            <p>Chief Executive Officer</p>
          </div>
          <div className="team-member">
            <h3>John Smith</h3>
            <p>Chief Operations Officer</p>
          </div>
          <div className="team-member">
            <h3>Sarah Lee</h3>
            <p>Head of Technology</p>
          </div>
        </div>
      </section>

      <section id="testimonials">
        <h2>What Our Clients Say</h2>
        <div className="testimonials">
          <blockquote>
            <p>
              "Professional Business transformed our workflow and helped us
              achieve our goals with remarkable efficiency."
            </p>
            <cite>– Alex R., Client</cite>
          </blockquote>
          <blockquote>
            <p>
              "The team at Professional Business brings an unparalleled level of
              expertise and dedication to their work."
            </p>
            <cite>– Maria L., Client</cite>
          </blockquote>
        </div>
      </section>

      <section id="contact" className="w-3/5">
        <h2>Contact Us</h2>
        <form action="#" method="post">
          <label>Name</label>
          <input type="text" id="name" name="name" required />

          <label>Email</label>
          <input type="email" id="email" name="email" required />

          <label>Message</label>
          <textarea id="message" name="message" rows={4} required />

          <button type="submit" className="cta-button">
            Send Message
          </button>
        </form>
      </section>

      <footer>
        <p>&copy; 2024 Professional Business. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default SellerProfileLayout2;
