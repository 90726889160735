import { Routes, Route } from "react-router-dom";
import { Routes as RoutesPath } from "../config/routes";
import AuthorizedRoute from "../components/AuthorizedRoute";
import ProductManagementInventoryView from "../views/productManagement/inventory";
import ProductManagementInventoryAddView from "../views/productManagement/inventory/details";

const ProductManagementInventoryRoutes = () => {
    return (
        <Routes>
            <Route
                path={"/"}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductManagementInventoryView />
                    </AuthorizedRoute>
                }
            />

            <Route
                path={RoutesPath.ProductManagementInventoryAddOrDetails}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductManagementInventoryAddView />
                    </AuthorizedRoute>
                }
            />

            <Route
                path={RoutesPath.ProductManagementInventoryDetails}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductManagementInventoryAddView />
                    </AuthorizedRoute>
                }
            />

            <Route
                path={RoutesPath.ProductManagementInventoryUpdate}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductManagementInventoryAddView isEditMode={true} />
                    </AuthorizedRoute>
                }
            />
        </Routes>
    );
};

export default ProductManagementInventoryRoutes;
