import { Routes, Route } from "react-router-dom";
import { Routes as RoutesPath } from "../config/routes";
import AuthorizedRoute from "../components/AuthorizedRoute";
import ProductManagementInventoryGroupView from "../views/productManagement/inventoryGroup";
import ProductManagementInventoryGroupAddView from "../views/productManagement/inventoryGroup/details";

const ProductManagementInventoryGroupRoutes = () => {
    return (
        <Routes>
            <Route
                path={"/"}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductManagementInventoryGroupView />
                    </AuthorizedRoute>
                }
            />

            <Route
                path={RoutesPath.ProductManagementInventoryGroupAddOrDetails}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductManagementInventoryGroupAddView />
                    </AuthorizedRoute>
                }
            />

            <Route
                path={RoutesPath.ProductManagementInventoryGroupDetails}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductManagementInventoryGroupAddView />
                    </AuthorizedRoute>
                }
            />

            <Route
                path={RoutesPath.ProductManagementInventoryGroupUpdate}
                element={
                    <AuthorizedRoute userRole={""} route={null}>
                        <ProductManagementInventoryGroupAddView isEditMode={true} />
                    </AuthorizedRoute>
                }
            />
        </Routes>
    );
};

export default ProductManagementInventoryGroupRoutes;
