import React from 'react';
export const OuterWear: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.615"
      height="18"
      fill="currentColor"
      {...props}
    >
      <g data-name="Outer Wear">
        <g data-name="Group 12541">
          <path
            data-name="Path 21714"
            d="M8.307 3.461C5.362 3.461 4.854.43 4.85.4a.346.346 0 01.494-.364 7.016 7.016 0 005.926 0 .346.346 0 01.494.364c-.004.03-.512 3.061-3.457 3.061zM5.718.929a2.743 2.743 0 005.181 0 7.6 7.6 0 01-5.181 0z"
            fill="currentColor"
          />
        </g>
        <g data-name="Group 12542">
          <path
            data-name="Path 21715"
            d="M8.307 4.846a4.98 4.98 0 01-4.751-3.464.349.349 0 01.327-.465.344.344 0 01.338.266 4.3 4.3 0 008.181-.02.348.348 0 01.33-.245.343.343 0 01.346.343v.007a.348.348 0 01-.02.115 4.98 4.98 0 01-4.751 3.463z"
            fill="currentColor"
          />
        </g>
        <g data-name="Group 12543">
          <path
            data-name="Path 21716"
            d="M15.577 16.616h-.692a.346.346 0 110-.692h.692a.347.347 0 00.347-.347c0-9.425-1.11-12.163-1.306-12.572L11.226.631a.347.347 0 11.4-.567l3.462 2.422a.347.347 0 01.1.106c.058.1 1.434 2.51 1.434 12.986a1.04 1.04 0 01-1.045 1.038z"
            fill="currentColor"
          />
        </g>
        <g data-name="Group 12544">
          <path
            data-name="Path 21717"
            d="M1.731 16.616H1.04a1.04 1.04 0 01-1.039-1.039c0-10.475 1.375-12.888 1.434-12.986a.347.347 0 01.1-.106L4.997.063a.347.347 0 11.4.567L1.997 3C1.806 3.412.689 6.225.689 15.574a.347.347 0 00.347.347h.691a.346.346 0 110 .692z"
            fill="currentColor"
          />
        </g>
        <g data-name="Group 12545">
          <path
            data-name="Path 21718"
            d="M13.5 15.923H3.115a1.04 1.04 0 01-1.038-1.039c0-.612.047-1.416.106-2.434.1-1.748.24-4.141.24-7.258a.346.346 0 01.692 0c0 3.137-.14 5.542-.242 7.3-.058 1.007-.1 1.8-.1 2.394a.347.347 0 00.346.346h10.385a.346.346 0 00.346-.346c0-.592-.046-1.387-.1-2.394-.1-1.756-.241-4.161-.241-7.3a.346.346 0 01.692 0c0 3.117.139 5.511.24 7.258.059 1.018.106 1.822.106 2.434a1.04 1.04 0 01-1.047 1.039z"
            fill="currentColor"
          />
        </g>
        <g data-name="Group 12546">
          <path
            data-name="Path 21719"
            d="M12.807 17.308h-9a1.04 1.04 0 01-1.038-1.038v-.693a.346.346 0 11.692 0v.693a.346.346 0 00.346.346h9a.346.346 0 00.346-.346v-.693a.346.346 0 11.692 0v.693a1.04 1.04 0 01-1.038 1.038z"
            fill="currentColor"
          />
        </g>
        <g data-name="Group 12547">
          <path
            data-name="Path 21720"
            d="M15.576 18h-1.384a.346.346 0 010-.692h1.038V16.27a.346.346 0 01.692 0v1.385a.346.346 0 01-.346.345z"
            fill="currentColor"
          />
        </g>
        <g data-name="Group 12548">
          <path
            data-name="Path 21721"
            d="M2.423 18H1.039a.346.346 0 01-.346-.346v-1.385a.346.346 0 11.692 0v1.038h1.038a.346.346 0 110 .692z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
