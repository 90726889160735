import { useEffect, useState } from "react";
import {
    useBusinessFinanceCreatePaymentIntent,
    useBusinessFinancePaymentConfig,
    useBusinessFinanceRetrievePaymentIntent,
} from "../../../service/hook/businessFinance";
import { userStore } from "../../../store";
import { toast } from "react-toastify";
import Button from "../../../components/ui/button";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "../../../config/routes";
import { PaymentElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ConfigPaymentResponse } from "../../../types";

const SuccessPageView = () => {
    // const stripe = useStripe();

    // const elements = useElements();
    const navigate = useNavigate();

    const { user, businessItem, setRegisteredBusinessId } = userStore((state) => state);

    const [publishKey, setPublishKey] = useState<string | undefined>();
    const [clientSecret, setClientSecret] = useState<string | undefined>();
    const [loadedStripe, setLoadedStripe] = useState<any>();

    const {
        mutate: mutateBusinessFinanceRetrievePaymentIntent,
        isLoading: isLoadingBusinessFinanceRetrievePaymentIntent,
        serverError: serverErrorBusinessFinanceRetrievePaymentIntent,
        setServerError: setServerErrorBusinessFinanceRetrievePaymentIntent,
    } = useBusinessFinanceRetrievePaymentIntent();

    const [retrieveResult, setRetrieveResult] = useState<any>();

    const {
        mutate: mutateBusinessFinancePaymentConfig,
        isLoading: isLoadingBusinessFinancePaymentConfig,
        serverError: serverErrorBusinessFinancePaymentConfig,
        setServerError: setServerErrorBusinessFinancePaymentConfig,
    } = useBusinessFinancePaymentConfig();

    const {
        mutate: mutateBusinessFinanceCreatePaymentIntent,
        isLoading: isLoadingBusinessFinanceCreatePaymentIntent,
        serverError: serverErrorBusinessFinanceCreatePaymentIntent,
        setServerError: setServerErrorBusinessFinanceCreatePaymentIntent,
    } = useBusinessFinanceCreatePaymentIntent();

    useEffect(() => {
        getPaymentConfig();
    }, []);

    useEffect(() => {
        if (publishKey && loadedStripe) retrievePaymentIntent();
    }, [publishKey, loadedStripe]);

    // useEffect(() => {
    //     if (clientSecret) retrievePaymentIntent();
    // }, [clientSecret]);

    const getPaymentConfig = async () => {
        await mutateBusinessFinancePaymentConfig(undefined, {
            onSuccess: async (successData: ConfigPaymentResponse) => {
                setPublishKey(successData.publishableKey);

                const stripe = await loadStripe(successData.publishableKey ?? "");
                setLoadedStripe(stripe);
            },
            onError: async (error: Error) => {
                const {
                    response: { data },
                }: any = error ?? {};

                toast.error(data?.general_result);
            },
        });
    };

    const retrievePaymentIntent = async () => {
        const searchParams = new URLSearchParams(window.location.href);
        console.log("searchParams => ", searchParams);
        const clientSecret = searchParams.get("payment_intent_client_secret");

        setClientSecret(clientSecret ?? "");

        // return false;

        if (loadedStripe) {
            console.log("retrievePaymentIntent");

            const { paymentIntent } = await loadedStripe?.retrievePaymentIntent(clientSecret ?? "");

            setRetrieveResult(paymentIntent);
            toast.success(`status: ${paymentIntent?.status}`);
            console.log(paymentIntent);
        }

        // const retrieveInput: RetrievePaymentIntentReq = {
        //     intent_id: state.intentId ?? "",
        // };
        // await mutateBusinessFinanceRetrievePaymentIntent(retrieveInput, {
        //     onSuccess: async (successDataRetrieve: RetrievePaymentIntentResponse) => {
        //         if (successDataRetrieve.final_result_code === "000") {
        //             console.log(
        //                 "successDataRetrieve.captured_data => ",
        //                 successDataRetrieve.captured_data
        //             );

        //             setRetrieveResult(successDataRetrieve);
        //             toast.success(successDataRetrieve?.general_result ?? "");
        //         } else {
        //             toast.error(successDataRetrieve.general_result);
        //         }
        //     },
        //     onError: async (error: Error) => {
        //         const {
        //             response: { data },
        //         }: any = error ?? {};
        //         toast.error(data?.general_result);
        //     },
        // });
    };

    return (
        <div className="w-full bg-slate-300 align-center justify-center text-center py-20">
            <span className="">Payment result : </span>
            {
                <span className="">
                    {isLoadingBusinessFinanceRetrievePaymentIntent
                        ? "Loading"
                        : retrieveResult?.status ?? "unknown"}
                </span>
            }
            <br />
            <Button
                onClick={() => {
                    navigate(Routes.dashboard, {
                        replace: true,
                    });
                }}
            >{`Home page`}</Button>
        </div>
        // <Elements stripe={getStripe()} options={options}>
        // </Elements>
    );
};

export default SuccessPageView;
