import { useEffect, useState } from "react";
import { useBusinessProfile } from "../../service/hook/business";
import { useNavigate, useParams } from "react-router-dom";
import { BusinessProfileResponseModel, WebLayout } from "../../types";
import SellerProfileLayout1 from "../businessSetup/profile/seller/layout-1/SellerProfileLayout1";
import SellerProfileLayout2 from "../businessSetup/profile/seller/layout-2/SellerProfileLayout2";

const BusinessView = () => {
  const navigate = useNavigate();

  const { webUrl } = useParams();
  const { mutate: businessProfileMutate } = useBusinessProfile();
  const [businessProfile, setBusinessProfile] =
    useState<BusinessProfileResponseModel>();

  useEffect(() => {
    if (webUrl) {
      getBusinessProfile();
    } else {
      navigate("/");
    }
  }, [webUrl]);

  const getBusinessProfile = () => {
    businessProfileMutate(
      {
        web_url: webUrl ?? "",
      },
      {
        onSuccess: (successData: BusinessProfileResponseModel) => {
          if (successData.final_result_code === "000") {
            setBusinessProfile(successData);
          }
        },
      }
    );
  };

  const showComponentLayout = () => {
    if (businessProfile?.web_layout.web_layout_address1) {
      switch (businessProfile.web_layout.web_layout_address1) {
        case "SellerProfileLayout1":
          return <SellerProfileLayout1 businessProfileData={businessProfile} />;
        case "SellerProfileLayout2":
          return <SellerProfileLayout2 businessProfileData={businessProfile} />;
        default:
          return;
      }
    }
  };

  return <>{showComponentLayout()}</>;
};

export default BusinessView;
