import { Table } from "../ui/table";
import { useTranslation } from "react-i18next";
import { MappedPaginatorInfo, SortOrder, ProductManagementPolicyItem } from "../../types";
import { useState } from "react";
import Pagination from "../ui/pagination";
import { TrashIcon } from "../icons/trash";
import { DownloadIcon } from "../icons/download-icon";
import { Eye } from "../icons/eye-icon";
import { useModalAction } from "../../provider/modal.context";

export interface IProductPolicy extends ProductManagementPolicyItem {}

export type IProps = {
    policyList: IProductPolicy[] | undefined;
    paginatorInfo: MappedPaginatorInfo | null;
    onPagination: (current: number) => void;
    onSort: (current: any) => void;
    onOrder: (current: string) => void;
    onDeleteItem: (item: IProductPolicy) => void;
};

type SortingObjType = {
    sort: SortOrder;
    column: string | null;
};

const PolicyList = ({
    policyList,
    paginatorInfo,
    onPagination,
    onSort,
    onOrder,
    onDeleteItem,
}: IProps) => {
    const { t } = useTranslation();
    const { openModal } = useModalAction();

    const alignLeft = "left";
    const alignRight = "right";

    const [sortingObj, setSortingObj] = useState<SortingObjType>({
        sort: SortOrder.Desc,
        column: null,
    });

    let columns = [
        {
            title: "Policy name",
            dataIndex: "policy_name",
            key: "file_name",
            width: 150,
            align: "center",
            ellipsis: true,
            render: (name: string) => {
                return <span className="truncate whitespace-nowrap">{name ?? "-"}</span>;
            },
        },
        {
            title: "Policy type",
            dataIndex: "policy_type_id",
            key: "policy_type",
            width: 80,
            align: "center",
            ellipsis: true,
            render: (name: string) => {
                return <span className="truncate whitespace-nowrap">{name ?? "-"}</span>;
            },
        },
        {
            title: "MarketPlace",
            // dataIndex: "policy_type_id2",
            key: "marketPlace",
            width: 80,
            align: "center",
            ellipsis: true,
            render: (name: string) => {
                return <span className="truncate whitespace-nowrap">{"GBR"}</span>;
            },
        },
        {
            title: t("table:table-item-action"),
            className: "cursor-pointer",
            dataIndex: "action",
            key: "action",
            align: alignRight,
            width: 50,
            render: function Render(value: number, record: IProductPolicy) {
                return (
                    <div
                        className="space-x-4"
                        style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "end",
                        }}
                    >
                        <button
                            onClick={() => {
                                openModal("ProductManagement_Add_Policy", {
                                    item: record,
                                    callback: () => {
                                        // getProductPolicyList();
                                    },
                                });
                            }}
                            className="text-body transition duration-200 hover:text-heading focus:outline-none"
                            title={t("common:text-delete")}
                        >
                            <Eye width={16} />
                        </button>
                        <button
                            onClick={() => {
                                onDeleteItem(record);
                            }}
                            className="text-red-500 transition duration-200 hover:text-red-600 focus:outline-none"
                            title={t("common:text-delete")}
                        >
                            <TrashIcon width={16} />
                        </button>
                        {/* <div
                            style={{
                                width: "20px",
                            }}
                        />
                        <button
                            onClick={() => {
                                onDeleteItem(record);
                            }}
                            className="text-body transition duration-200 hover:text-heading focus:outline-none"
                            title={t("common:text-edit")}
                        >
                            <EditIcon width={16} />
                        </button> */}
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
                <Table
                    /* @ts-ignore */
                    columns={columns}
                    emptyText={t("table:empty-table-data")}
                    data={policyList}
                    rowKey="id"
                    // scroll={{ x: 900 }}
                />
            </div>

            {!!paginatorInfo?.total && (
                <div className="flex items-center justify-end">
                    <Pagination
                        total={paginatorInfo.total}
                        current={paginatorInfo.currentPage}
                        pageSize={paginatorInfo.perPage}
                        onChange={onPagination}
                        showLessItems
                    />
                </div>
            )}
        </>
    );
};

export default PolicyList;
