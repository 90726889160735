import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import { useTranslation } from "react-i18next";
import FileInput from "../ui/file-input";
import { useModalAction } from "../../provider/modal.context";
import {
    BusinessDocumentItem,
    BusinessDocumentListResponse,
    Category,
    SortOrder,
} from "../../types";
import Input from "../ui/forms/input";
import Button from "../ui/button";
import DocumentList, { IDocuments } from "./document-list";
import {
    useBusinessDocumentDelete,
    useBusinessDocumentsCreate,
    useBusinessDocumentsList,
} from "../../service/hook/business";
import { toast } from "react-toastify";
import { userStore } from "../../store";

type FormValues = {
    files: string;
    name: string;
};

const defaultValues = {
    files: "",
    name: "",
};

type IProps = {
    initialValues?: Category | undefined;
};
export default function CreateOrUpdateBusinessDocumentsForm({ initialValues }: IProps) {
    const { t } = useTranslation();
    const { businessItem } = userStore((state) => state);
    const [documentList, setDocumentList] = useState<BusinessDocumentItem[]>();
    const [page, setPage] = useState(1);
    const [orderBy, setOrder] = useState("created_at");
    const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);
    const [visible, setVisible] = useState(false);
    const [document, setDocument] = useState<any>();
    const { openModal } = useModalAction();

    const {
        mutate: mutateBusinessDocumentCreate,
        isLoading: isLoadingBusinessDocumentCreate,
        serverError: serverErrorBusinessDocumentCreate,
        setServerError: setServerErrorBusinessDocumentCreate,
    } = useBusinessDocumentsCreate();

    const {
        mutate: mutateBusinessDocumentList,
        isLoading: isLoadingBusinessDocumentList,
        serverError: serverErrorBusinessDocumentList,
        setServerError: setServerErrorBusinessDocumentList,
    } = useBusinessDocumentsList();

    useEffect(() => {
        getBusinessDocumentList();
    }, [businessItem]);

    const getBusinessDocumentList = () => {
        mutateBusinessDocumentList(
            {
                businessdetail_id: businessItem?.business_id ?? 0,
            },
            {
                onSuccess: (successData: BusinessDocumentListResponse) => {
                    if (successData.final_result_code === "000") {
                        try {
                            setDocumentList(successData.captured_data ?? []);
                        } catch (err) {
                            console.log("err => ", err);
                        }
                    } else {
                    }
                },
                onError: (error: Error) => {
                    const {
                        response: { data },
                    }: any = error ?? {};

                    toast.error(data?.general_result);
                    // closeModal();
                },
            }
        );
    };

    function handlePagination(current: any) {
        setPage(current);
    }

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: initialValues
            ? {
                  ...initialValues,
              }
            : defaultValues,
        // resolver: yupResolver(businessSetupValidationSchema),
    });

    const deleteDocumentItem = (item: IDocuments) => {
        openModal("BusinessDocument_Delete_Document", {
            item: { ...item },
            callback: () => {
                getBusinessDocumentList();
            },
        });
    };

    const onSubmit = async (values: FormValues) => {
        const formData = new FormData();

        formData.append("image", document);
        formData.append("businessdetail_id", `${businessItem?.business_id ?? 0}`);
        formData.append("picture_name", values.name ?? "");

        mutateBusinessDocumentCreate(formData, {
            onSuccess: (successData: any) => {
                if (successData.final_result_code === "000") {
                    getBusinessDocumentList();
                    setDocument(undefined);
                    reset(defaultValues);
                    toast.success(successData.general_result);
                } else {
                }
            },
            onError: (error: Error) => {
                const {
                    response: { data },
                }: any = error ?? {};

                toast.error(data?.general_result);
                // closeModal();
            },
        });
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        // title={t("form:form-title-business-documents")}
                        details={[t("form:form-description-business-documents-01")]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />

                    <Card className="w-full sm:w-8/12 md:w-2/3">
                        <Input
                            label={t("form:input-label-file-name")}
                            {...register("name")}
                            error={t(errors.name?.message!)}
                            variant="outline"
                            className="mb-5"
                            maxLength={30}
                        />
                    </Card>
                </div>

                <div className="my-5 flex flex-wrap border-b border-dashed border-border-base pb-8 sm:my-8">
                    <Description
                        title={t("form:input-label-documents-file")}
                        details={[]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5"
                    />

                    <Card className="w-full sm:w-8/12 md:w-2/3">
                        <FileInput
                            name="files"
                            onChange={(file: any) => {
                                if (file.length) {
                                    setDocument(file[0]);
                                }
                            }}
                            control={control}
                            multiple={false}
                            acceptFile={true}
                            helperText={
                                document
                                    ? "The file is selected. ready to upload"
                                    : "Please select documents file in format JPG, PDF and maximum size 10 MB"
                            }
                        />
                    </Card>
                </div>

                <div className="mb-4 text-end">
                    <Button loading={isLoadingBusinessDocumentCreate}>
                        {t("form:button-label-submit")}
                    </Button>
                </div>
            </form>
            <DocumentList
                documents={documentList}
                paginatorInfo={null}
                onPagination={handlePagination}
                onOrder={setOrder}
                onSort={setColumn}
                onDeleteItem={(item: IDocuments) => {
                    deleteDocumentItem(item);
                }}
            />
        </>
    );
}
