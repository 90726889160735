import { Table } from "../ui/table";
import { useTranslation } from "react-i18next";
import { MappedPaginatorInfo, SortOrder, BranchLocationItem } from "../../types";
import { useState } from "react";
import Pagination from "../ui/pagination";
import { TrashIcon } from "../icons/trash";

export interface IBranchLocation extends BranchLocationItem {}

export type IProps = {
    branchLocationList: IBranchLocation[] | undefined;
    paginatorInfo: MappedPaginatorInfo | null;
    onPagination: (current: number) => void;
    onSort: (current: any) => void;
    onOrder: (current: string) => void;
    onDeleteItem: (item: IBranchLocation) => void;
};

type SortingObjType = {
    sort: SortOrder;
    column: string | null;
};

const BranchLocationList = ({
    branchLocationList,
    paginatorInfo,
    onPagination,
    onSort,
    onOrder,
    onDeleteItem,
}: IProps) => {
    // const { data, paginatorInfo } = products! ?? {};
    // const router = useRouter();
    const { t } = useTranslation();
    // const { alignLeft, alignRight } = useIsRTL();

    const alignLeft = "left";
    const alignRight = "right";

    const [sortingObj, setSortingObj] = useState<SortingObjType>({
        sort: SortOrder.Desc,
        column: null,
    });

    const onHeaderClick = (column: string | null) => ({
        onClick: () => {
            onSort((currentSortDirection: SortOrder) =>
                currentSortDirection === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc
            );
            onOrder(column!);

            setSortingObj({
                sort: sortingObj.sort === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc,
                column: column,
            });
        },
    });

    let columns = [
        {
            title: t("table:table-item-branch-id"),
            dataIndex: "location_id",
            key: "location_id",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (id: string) => {
                return <span className="truncate whitespace-nowrap">{id ?? "-"}</span>;
            },
        },

        {
            title: t("table:table-item-title"),
            dataIndex: "location_name",
            key: "location_name",
            width: 120,
            align: "center",
            render: (name: string) => {
                return <span className="truncate whitespace-nowrap">{name ?? "-"}</span>;
            },
        },
        {
            title: t("table:table-item-country"),
            dataIndex: "country",
            key: "country",
            width: 120,
            align: "center",
            render: (country: string) => {
                return <span className="truncate whitespace-nowrap">{country ?? "-"}</span>;
            },
        },
        {
            title: t("table:table-item-city"),
            dataIndex: "city",
            key: "city",
            width: 120,
            align: "center",
            render: (city: string) => {
                return <span className="truncate whitespace-nowrap">{city ?? "-"}</span>;
            },
        },
        {
            title: t("table:table-item-action"),
            className: "cursor-pointer",
            dataIndex: "action",
            key: "action",
            align: alignRight,
            width: 130,
            render: function Render(value: number, record: IBranchLocation) {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "end",
                        }}
                    >
                        <button
                            onClick={() => {
                                onDeleteItem(record);
                            }}
                            className="text-red-500 transition duration-200 hover:text-red-600 focus:outline-none"
                            title={t("common:text-delete")}
                        >
                            <TrashIcon width={16} />
                        </button>
                        {/* <div
                            style={{
                                width: "20px",
                            }}
                        />
                        <button
                            onClick={() => {
                                // onEditItem()
                            }}
                            className="text-body transition duration-200 hover:text-heading focus:outline-none"
                            title={t("common:text-edit")}
                        >
                            <EditIcon width={16} />
                        </button> */}
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
                <Table
                    /* @ts-ignore */
                    columns={columns}
                    emptyText={t("table:empty-table-data")}
                    data={branchLocationList}
                    rowKey="id"
                    // scroll={{ x: 900 }}
                />
            </div>

            {!!paginatorInfo?.total && (
                <div className="flex items-center justify-end">
                    <Pagination
                        total={paginatorInfo.total}
                        current={paginatorInfo.currentPage}
                        pageSize={paginatorInfo.perPage}
                        onChange={onPagination}
                        showLessItems
                    />
                </div>
            )}
        </>
    );
};

export default BranchLocationList;
