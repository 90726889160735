import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js/pure";
import { PaymentElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import {
    useBusinessFinanceConfirmPaymentIntent,
    useBusinessFinanceCreatePaymentIntent,
    useBusinessFinancePaymentConfig,
} from "../../../service/hook/businessFinance";
import {
    ConfigPaymentResponse,
    ConfirmPaymentIntentReq,
    ConfirmPaymentIntentResponse,
    CreatePaymentIntentReq,
    CreatePaymentIntentResponse,
} from "../../../types";
import { userStore } from "../../../store";
import { toast } from "react-toastify";
import Button from "../../../components/ui/button";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../config/routes";

const CheckoutForm = ({ intentId, clientSecret }: { intentId?: string; clientSecret?: string }) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const { user, businessItem, setRegisteredBusinessId } = userStore((state) => state);

    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const {
        mutate: mutateBusinessFinanceConfirmPaymentIntent,
        isLoading: isLoadingBusinessFinanceConfirmPaymentIntent,
        serverError: serverErrorBusinessFinanceConfirmPaymentIntent,
        setServerError: setServerErrorBusinessFinanceConfirmPaymentIntent,
    } = useBusinessFinanceConfirmPaymentIntent();

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        console.log("event => ", event);

        if (elements == null) {
            return;
        }

        // Trigger form validation and wallet collection
        // const { error: submitError } = await elements.submit();
        const result = await elements.submit();

        console.log("submitError => ", result);

        if (result.error) {
            // Show error to your customer
            setErrorMessage(result.error?.message);
            return;
        }

        const confirmInput: ConfirmPaymentIntentReq = {
            intent_id: intentId ?? "",
            paymentMethodType: "pm_card_visa",
        };

        if (stripe) {
            // const { error } = await stripe.confirmPayment({
            const confirmResponse = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    // return_url: "http://localhost:3000/success",
                    return_url: "https://business.milborak.co.uk/success",
                },
            });

            console.log("confirmResponse => ", confirmResponse);

            // if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            // setErrorMessage(error.message);
            // } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            // }
        }

        // await mutateBusinessFinanceConfirmPaymentIntent(confirmInput, {
        //     onSuccess: async (successDataConfirm: ConfirmPaymentIntentResponse) => {
        //         if (successDataConfirm.final_result_code === "000") {
        //             toast.success(successDataConfirm.general_result);

        //             navigate(Routes.success, {
        //                 state: { intentId },
        //             });

        //             // navigate(-1);
        //         } else {
        //             toast.error(successDataConfirm.general_result);
        //         }
        //     },
        //     onError: async (error: Error) => {
        //         const {
        //             response: { data },
        //         }: any = error ?? {};

        //         toast.error(data?.general_result);
        //     },
        // });

        // Create the PaymentIntent and obtain clientSecret from your server endpoint
        // const res = await fetch("/create-intent", {
        //     method: "POST",
        // });

        // const { client_secret: clientSecret } = await res.json();

        // account=account.id,
        //     refresh_url="https://milborak.co.uk/reauth",
        //     return_url="https://milborak.co.uk/return",
        //     type="account_onboarding",
        // )
    };

    return (
        <form id="inventory-add-form" onSubmit={handleSubmit}>
            <PaymentElement />
            <Button
                form={"inventory-add-form"}
                loading={isLoadingBusinessFinanceConfirmPaymentIntent}
                disabled={isLoadingBusinessFinanceConfirmPaymentIntent || !stripe || !elements}
                type="submit"
            >
                {`Pay`}
            </Button>
            {/* <button type="submit" disabled={!stripe || !elements}>
                Pay
            </button> */}
            {/* Show error message to your customers */}
            {errorMessage && <div>{errorMessage}</div>}
        </form>
    );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe("pk_test_oKhSR5nslBRnBZpjO6KuzZeX");

// const options = {
//     // passing the client secret obtained from the server
//     clientSecret: "{{CLIENT_SECRET}}",
// };

// const stripe = await loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");

// https://connect.stripe.com/setup/s/acct_1OSHv7Rn3h95dAUU/0TJvbE7Q4OLF

const PrePayment = () => {
    const { user, businessItem, setRegisteredBusinessId } = userStore((state) => state);

    const [publishKey, setPublishKey] = useState<string | undefined>();
    const [clientSecret, setClientSecret] = useState<string | undefined>();
    const [intentId, setIntentId] = useState<string | undefined>();

    const {
        mutate: mutateBusinessFinancePaymentConfig,
        isLoading: isLoadingBusinessFinancePaymentConfig,
        serverError: serverErrorBusinessFinancePaymentConfig,
        setServerError: setServerErrorBusinessFinancePaymentConfig,
    } = useBusinessFinancePaymentConfig();

    const {
        mutate: mutateBusinessFinanceCreatePaymentIntent,
        isLoading: isLoadingBusinessFinanceCreatePaymentIntent,
        serverError: serverErrorBusinessFinanceCreatePaymentIntent,
        setServerError: setServerErrorBusinessFinanceCreatePaymentIntent,
    } = useBusinessFinanceCreatePaymentIntent();

    const options = {
        // mode: "payment",
        // amount: 1099,
        // currency: "usd",
        // clientSecret: "pi_3OUIS7DDLEB97SSt0qIBMBKn_secret_p3xT1ZGrjbYL5tPrwzoj4BNHr",
        clientSecret: clientSecret,
        // clientSecret:
        //     "sk_test_51NrAA9DDLEB97SStRyK9kDNtRX9aj1RFJOT6Rhe3J6CgUhW0tV9FJBZYwAt1HKtYooRDhpiC2VBUPX7PONqjXrUD00pDELhVr0",
        // Fully customizable with appearance API.
        // appearance: {
        //     /*...*/
        // },

        // appearance: {}, // appearance options
        // mode: "payment",
        // currency: "GBP",
        // amount: 4500,
    };

    useEffect(() => {
        getPaymentConfig();
    }, []);

    useEffect(() => {
        if (publishKey) if (!clientSecret) createPaymentIntent();
    }, [publishKey]);

    const getPaymentConfig = async () => {
        await mutateBusinessFinancePaymentConfig(undefined, {
            onSuccess: async (successData: ConfigPaymentResponse) => {
                setPublishKey(successData.publishableKey);
            },
            onError: async (error: Error) => {
                const {
                    response: { data },
                }: any = error ?? {};

                toast.error(data?.general_result);
            },
        });
    };

    const createPaymentIntent = async () => {
        const input: CreatePaymentIntentReq = {
            email: user?.email ?? "",
            amount: 110,
            currency: "GBP",
        };

        await mutateBusinessFinanceCreatePaymentIntent(input, {
            onSuccess: async (successDataCreate: CreatePaymentIntentResponse) => {
                if (successDataCreate.final_result_code === "000") {
                    setClientSecret(successDataCreate.clientSecret);
                    setIntentId(successDataCreate["intent.id"] ?? "");
                } else {
                    toast.error(successDataCreate.general_result);
                }
            },
            onError: async (error: Error) => {
                const {
                    response: { data },
                }: any = error ?? {};

                toast.error(data?.general_result);
            },
        });
    };

    let stripePromise: any;
    const getStripe = () => {
        if (!stripePromise) {
            stripePromise = loadStripe(publishKey ?? "");
        }
        return stripePromise;
    };

    // {
    //     "listing_id": 3,
    //     "offer_id": 2,
    //     "marketplaceId": "GBR",
    //     "sku_code": "T_Shirt_Big_Red_01",
    //     "sku_group_code": null,
    //     "brand": "Adidas",
    //     "product_title": "TShirt Adidas Big Red",
    //     "price_value": 450.99,
    //     "currency": "GBP",
    //     "_updated_at": "2024-01-03"
    // }

    return (
        <div className="px-20 py-20">
            {publishKey && clientSecret && intentId && (
                <Elements stripe={getStripe()} options={options}>
                    <CheckoutForm intentId={intentId} clientSecret={clientSecret} />
                </Elements>
            )}
        </div>
    );
};

export default PrePayment;
