import React from "react";

import StickerCard from "../../components/widgets/sticker-card";
import { DollarIcon } from "../../components/icons/shops/dollar";
import { usePrice } from "../../utils/use-price";
import { CartIconBig } from "../../components/icons/cart-icon-bag";
import { CoinIcon } from "../../components/icons/coin-icon";
import { ShopIcon } from "../../components/icons/sidebar";
import ColumnChart from "../../components/widgets/column-chart";
import { useTranslation } from "react-i18next";
import { PoundIcon } from "../../components/icons/shops/pound";
import { CoinPoundIcon } from "../../components/icons/coin-pound-icon";

const analyticsData = {
    totalRevenue: 100,
    totalRefunds: 0,
    totalShops: 10,
    todaysRevenue: 10,
    totalOrders: 0,
    newCustomers: 1,
    totalYearSaleByMonth: [
        {
            total: 4,
            month: "January",
        },
        {
            total: 5,
            month: "February",
        },
        {
            total: 7,
            month: "March",
        },
        {
            total: 5,
            month: "April",
        },
        {
            total: 7,
            month: "May",
        },
        {
            total: 9,
            month: "June",
        },
        {
            total: 8,
            month: "July",
        },
        {
            total: 9,
            month: "August",
        },
        {
            total: 0,
            month: "September",
        },
        {
            total: 0,
            month: "October",
        },
        {
            total: 0,
            month: "November",
        },
        {
            total: 0,
            month: "December",
        },
    ],
};

export default function HomeView({ children, layout }: any) {
    const { t } = useTranslation();

    const { price: total_revenue } = usePrice(
        analyticsData && {
            amount: analyticsData?.totalRevenue!,
        }
    );

    const { price: todays_revenue } = usePrice(
        analyticsData && {
            amount: analyticsData?.todaysRevenue!,
        }
    );

    let salesByYear: number[] = Array.from({ length: 12 }, (_) => 0);
    if (!!analyticsData?.totalYearSaleByMonth?.length) {
        salesByYear = analyticsData.totalYearSaleByMonth.map((item: any) => item.total.toFixed(2));
    }

    return (
        <>
            <div className="mb-6 grid w-full grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-4">
                <div className="w-full ">
                    <StickerCard
                        titleTransKey="sticker-card-title-rev"
                        subtitleTransKey="sticker-card-subtitle-rev"
                        icon={<PoundIcon className="h-7 w-7" color="#047857" />}
                        iconBgStyle={{ backgroundColor: "#A7F3D0" }}
                        price={total_revenue}
                    />
                </div>
                <div className="w-full ">
                    <StickerCard
                        titleTransKey="sticker-card-title-order"
                        subtitleTransKey="sticker-card-subtitle-order"
                        icon={<CartIconBig />}
                        price={0}
                    />
                </div>
                <div className="w-full ">
                    <StickerCard
                        titleTransKey="sticker-card-title-today-rev"
                        icon={<CoinPoundIcon />}
                        price={todays_revenue}
                    />
                </div>
                <div className="w-full ">
                    <StickerCard
                        titleTransKey="sticker-card-title-total-shops"
                        icon={<ShopIcon className="w-6" color="#1D4ED8" />}
                        iconBgStyle={{ backgroundColor: "#93C5FD" }}
                        price={10}
                    />
                </div>
            </div>

            <div className="mb-6 flex w-full flex-wrap md:flex-nowrap">
                <ColumnChart
                    widgetTitle={t("common:sale-history")}
                    colors={["#03D3B5"]}
                    series={salesByYear}
                    categories={[
                        t("common:january"),
                        t("common:february"),
                        t("common:march"),
                        t("common:april"),
                        t("common:may"),
                        t("common:june"),
                        t("common:july"),
                        t("common:august"),
                        t("common:september"),
                        t("common:october"),
                        t("common:november"),
                        t("common:december"),
                    ]}
                />
            </div>

            <div className="mb-6 flex w-full flex-wrap space-y-6 rtl:space-x-reverse xl:flex-nowrap xl:space-y-0 xl:space-x-5">
                {/* <div className="w-full xl:w-1/2">
                    <RecentOrders orders={orderData} title={t("table:recent-order-table-title")} />
                </div> */}

                {/* <div className="w-full xl:w-1/2">
                    <WithdrawTable withdraws={withdraws} title={t("table:withdraw-table-title")} />
                </div> */}
            </div>
        </>
        // <div className="mb-6 grid w-full grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-4">
        //     <div className="w-full ">
        //         <StickerCard
        //             titleTransKey="sticker-card-title-rev"
        //             subtitleTransKey="sticker-card-subtitle-rev"
        //             icon={<DollarIcon className="h-7 w-7" color="#047857" />}
        //             iconBgStyle={{ backgroundColor: "#A7F3D0" }}
        //             price={total_revenue}
        //         />
        //     </div>
        // </div>
    );
}
