import * as yup from 'yup';

export const inventoryAddValidationSchema = yup.object(
  {
    skuGroup: yup.string()
      // .required('form:error-input-label-sku-required')
      .max(70, 'form:error-input-label-sku-max-char'),
    productTitle: yup.string()
      // .required('form:error-input-label-product-title-required')
      .max(70, 'form:error-input-label-product-title-max-char'),
    productSubtitle: yup.string()
      // .required('form:error-input-label-product-subtitle-required')
      .max(65, 'form:error-input-label-product-subtitle-max-char'),
    description: yup.string()
      // .required('form:error-input-label-product-description-required')
      .max(4000, 'form:error-input-label-product-description-max-char'),
  }
);
