import { CheckedIcon } from "../icons/checked";

type Props = {
    className?: string;
    classNameItems?: string;
    title?: string;
    details: string[] | undefined;
    listStyleType?: string;
    titleStyle?: string;
    [key: string]: unknown;
};

const Description: React.FC<Props> = ({
    title,
    details,
    className,
    classNameItems,
    listStyleType = "list-disc",
    titleStyle,
    ...props
}) => {
    return (
        <div className={className} {...props}>
            {title && (
                <h4 className={`text-lg font-semibold text-body-dark mb-2 ${titleStyle}`}>
                    {title}
                </h4>
            )}

            <div className={classNameItems}>
                {details &&
                    details.length > 0 &&
                    details?.map((item) => {
                        return (
                            <ul className={listStyleType}>
                                <li className="mb-6">
                                    <p className="text-base text-body text-slate-900">{item}</p>
                                </li>
                            </ul>
                        );
                    })}
            </div>
        </div>
    );
};

export default Description;
