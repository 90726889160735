import { useCallback, useEffect, useState } from "react";
import { Control, FieldErrors, useForm } from "react-hook-form";
import Label from "../ui/label";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import ValidationError from "../ui/form-validation-error";
import { useTranslation } from "react-i18next";
import SelectInput from "../ui/select-input";
import {
    ProductManagementBrandListReq,
    ProductManagementInventoryBrandItem,
    ProductManagementInventoryBranchListResponse,
    ProductManagementInventoryItem,
    ProductManagementInventoryProductConditionItem,
    ProductManagementMarketPlaceListItem,
    ProductManagementMarketPlaceListResponse,
    ProductManagementMarketPlaceReq,
    ProductManagementOfferListReq,
    ProductManagementOfferListResponse,
    ProductManagementPolicyTypeItem,
    SortOrder,
    ProductManagementOfferListItem,
    ProductListingPublishByOfferCreateOrUpdateReq,
    ProductListingPublishByOfferCreateOrUpdateResponse,
} from "../../types";
import Input from "../ui/forms/input";
import Button from "../ui/button";
import {
    useProductManagementMarketPlaceList,
    useProductManagementProductConditionList,
} from "../../service/hook/business";
import { userStore } from "../../store";
import { useModalAction } from "../../provider/modal.context";
import {
    useProductListingPublishByOfferCreateOrUpdate,
    useProductManagementBrandList,
    useProductManagementList,
    useProductManagementOfferGridShow,
} from "../../service/hook/sector_1";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../config/routes";
import PublishByOfferList from "./publishByOffer-list";
import { toast } from "react-toastify";

function MarketPlaceList({
    control,
    errors,
    items,
    isLoading,
    defaultValue,
    onChangeValue,
}: {
    control: Control<FormValues>;
    errors: FieldErrors<FormValues>;
    items: ProductManagementMarketPlaceListItem[];
    isLoading: boolean;
    defaultValue?: ProductManagementMarketPlaceListItem;
    onChangeValue: (item: ProductManagementMarketPlaceListItem) => void;
}) {
    const { t } = useTranslation();
    const errorMessage: string = errors.marketPlace?.message as string;

    return (
        <div className="mb-5 w-full">
            <Label>{"Marketplace"}</Label>
            <SelectInput
                name="marketPlace"
                control={control}
                value={defaultValue}
                onChange={(value: ProductManagementMarketPlaceListItem) => {
                    onChangeValue(value);
                }}
                getOptionLabel={(option: any) => `${option.marketplace_name}`}
                getOptionValue={(option: any) => option.marketplace_id}
                options={items!}
                isLoading={false}
            />
            <ValidationError message={t(errorMessage)} />
        </div>
    );
}

type FormValues = {
    sku: string;
    offerId: string;
    marketPlace: any;
};

const defaultValues = {
    sku: "",
    offerId: "",
};

const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
        async (data: any) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors: any) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors: any, currentError: any) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

export default function PublishByOfferForm() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { user, businessItem, setRegisteredBusinessId } = userStore((state) => state);

    const { openModal } = useModalAction();

    const [marketPlaceList, setMarketPlaceList] = useState<ProductManagementMarketPlaceListItem[]>(
        []
    );
    const [selectedMarketPlace, setSelectedMarketPlace] = useState<
        ProductManagementMarketPlaceListItem | undefined
    >(undefined);

    const [brandList, setBrandList] = useState<ProductManagementInventoryBrandItem[]>([]);
    const [selectedBrand, setSelectedBrand] = useState<
        ProductManagementInventoryBrandItem | undefined
    >(undefined);

    const [productConditionList, setProductConditionList] = useState<
        ProductManagementInventoryProductConditionItem[]
    >([]);
    const [selectedProductCondition, setSelectedProductCondition] = useState<
        ProductManagementInventoryProductConditionItem | undefined
    >(undefined);

    const [inventoryList, setInventoryList] = useState<ProductManagementInventoryItem[]>([]);
    const [policyTypeItemSelected, setPolicyTypeItemSelected] = useState<
        ProductManagementPolicyTypeItem | undefined
    >(undefined);

    const [offerList, setOfferList] = useState<ProductManagementOfferListItem[]>([]);

    const [brandName, setBrandName] = useState<string>("");
    const [skuInput, setSkuInput] = useState<string>("");
    const [offerId, setOfferId] = useState<string>("");

    const [page, setPage] = useState(1);
    const [orderBy, setOrder] = useState("created_at");
    const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);
    const [visible, setVisible] = useState(false);

    const {
        mutate: mutateProductListingPublishByOfferCreateOrUpdate,
        isLoading: isLoadingProductListingPublishByOfferCreateOrUpdate,
        serverError: serverErrorProductListingPublishByOfferCreateOrUpdate,
        setServerError: setServerErrorProductListingPublishByOfferCreateOrUpdate,
    } = useProductListingPublishByOfferCreateOrUpdate();

    const {
        mutate: mutateProductManagementMarketPlaceList,
        isLoading: isLoadingProductMangementMarketPlaceList,
        serverError: serverErrorProductMangementMarketPlaceList,
        setServerError: setServerErrorProductMangementMarketPlaceList,
    } = useProductManagementMarketPlaceList();

    const {
        mutate: mutateProductManagementOfferGridList,
        isLoading: isLoadingProductMangementOfferGridList,
        serverError: serverErrorProductMangementOfferGridList,
        setServerError: setServerErrorProductMangementOfferGridList,
    } = useProductManagementOfferGridShow();

    const {
        mutate: mutateProductManagementInventoryBrand,
        isLoading: isLoadingProductMangementInventoryBrand,
        serverError: serverErrorProductMangementInventoryBrand,
        setServerError: setServerErrorProductMangementInventoryBrand,
    } = useProductManagementBrandList();

    function handlePagination(current: any) {
        setPage(current);
    }

    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        // resolver,
    });

    useEffect(() => {
        getMarketPlaceList();
        getProductOfferList();
    }, [businessItem]);

    useEffect(() => {
        if (brandName) {
            const timer = setTimeout(() => {
                if (brandName.length > 1) getProductInventoryBrandList(brandName);
                if (brandName.length === 0) {
                    getProductInventoryBrandList("", true);
                }
            }, 1000);
            return () => clearTimeout(timer);
        } else if (brandName.length === 0) {
            getProductInventoryBrandList("", true);
        }
    }, [brandName]);

    const getMarketPlaceList = (id?: string) => {
        const input: ProductManagementMarketPlaceReq = {};

        if (id) {
            input.marketplace_id = id;
        }

        mutateProductManagementMarketPlaceList(input, {
            onSuccess: (successData: ProductManagementMarketPlaceListResponse) => {
                if (successData.final_result_code === "000") {
                    setMarketPlaceList(successData.captured_data ?? []);
                }
            },
        });
    };

    const getProductOfferList = () => {
        const input: ProductManagementOfferListReq = {
            businessdetail_id: `${businessItem?.business_id ?? 0}`,
            marketplaceId: "GBR",
            page: 1,
            size: 100,
        };

        if (skuInput.length > 0) {
            input.sku_code = skuInput;
        }

        if (offerId.length > 0) {
            input.offer_id = parseInt(offerId);
        }

        mutateProductManagementOfferGridList(input, {
            onSuccess: (successData: ProductManagementOfferListResponse) => {
                if (successData.final_result_code === "000") {
                    setOfferList(successData.captured_data ?? []);
                }
            },
        });
    };

    const getProductInventoryBrandList = (brand?: string, clear?: boolean) => {
        const brandReq: ProductManagementBrandListReq = {
            brand_name: clear ? "" : brand ? brand : brandName,
        };

        mutateProductManagementInventoryBrand(brandReq, {
            onSuccess: (successData: ProductManagementInventoryBranchListResponse) => {
                if (successData.final_result_code === "000") {
                    setBrandList(successData.captured_data);
                }
            },
        });
    };

    const publishItem = async (item: any) => {
        const brandReq: ProductListingPublishByOfferCreateOrUpdateReq = {
            email: user?.email ?? "",
            businessdetail_id: `${businessItem?.business_id ?? 0}`,
            sku_code: item.sku_code ?? "",
            offer_format: "FIXED_PRICE",
            marketplaceId: "GBR",
        };

        mutateProductListingPublishByOfferCreateOrUpdate(brandReq, {
            onSuccess: (successData: ProductListingPublishByOfferCreateOrUpdateResponse) => {
                if (successData.final_result_code === "000") {
                    toast.success(successData.general_result);
                    getProductOfferList();
                }
            },
            onError: (error: Error) => {
                const {
                    response: { data },
                }: any = error ?? {};

                toast.error(data?.general_result);
            },
        });
    };

    const onSubmit = async () => {
        getProductOfferList();
    };

    return (
        <>
            <div className="my-5 flex flex-wrap sm:my-8">
                <Description
                    details={[
                        "You can publish an offer (listing), so that product can be seen and purchase by customers.",
                    ]}
                    className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                />

                <Card className="w-full sm:w-8/12 md:w-2/3 flex-col space-y-4">
                    <Input
                        label={"Product code (SKU)"}
                        name="sku"
                        placeholder="SKU code"
                        value={skuInput}
                        onChange={(e) => {
                            setSkuInput(e.target.value);
                        }}
                        error={t(errors.sku?.message!)}
                        variant="outline"
                    />

                    <Input
                        label={"Offer ID"}
                        name="offerId"
                        placeholder="Offer ID"
                        value={offerId}
                        onChange={(e) => {
                            setOfferId(e.target.value);
                        }}
                        error={t(errors.offerId?.message!)}
                        variant="outline"
                    />

                    <MarketPlaceList
                        control={control}
                        errors={errors}
                        items={marketPlaceList}
                        isLoading={isLoadingProductMangementMarketPlaceList}
                        defaultValue={selectedMarketPlace}
                        onChangeValue={(item) => {
                            setSelectedMarketPlace(item);
                        }}
                    />

                    <div
                        className="mb-4 text-start"
                        style={{
                            display: "flex",
                            justifyContent: "end",
                        }}
                    >
                        <div
                            className="mb-4 text-start space-x-4"
                            style={{
                                display: "flex",
                                justifyContent: "start",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    onSubmit();
                                }}
                                loading={false}
                                disabled={false}
                            >
                                {"Search"}
                            </Button>
                        </div>
                    </div>

                    <PublishByOfferList
                        offerList={offerList}
                        paginatorInfo={null}
                        onPagination={handlePagination}
                        onOrder={setOrder}
                        onSort={setColumn}
                        onPublish={(item: ProductManagementOfferListItem) => {
                            publishItem(item);
                        }}
                    />
                </Card>
            </div>
        </>
    );
}
