import { create } from "zustand";

export interface PubState {
    menuItemIndexLastSelected: number | undefined;
    subMenuItemIndexLastSelected: string | undefined;
    setMenuItemIndexLastSelected: (menuItemIndexLastSelected: number) => void;
    setSubMenuItemIndexLastSelected: (subMenuItemIndexLastSelected: string) => void;
};


const pubStore = create<PubState>()(
    (set) => ({
        menuItemIndexLastSelected: undefined,
        subMenuItemIndexLastSelected: undefined,
        setMenuItemIndexLastSelected: (menuItemIndexLastSelected: number) => set((state) => ({ menuItemIndexLastSelected: menuItemIndexLastSelected })),
        setSubMenuItemIndexLastSelected: (subMenuItemIndexLastSelected: string) => set((state) => ({ subMenuItemIndexLastSelected: subMenuItemIndexLastSelected }))
    })

)

export default pubStore;
