import {
  AuthResponse,
  BankAccountCreate,
  BankAccountCreateResponse,
  BankAccountDelete,
  BankAccountDeleteResponse,
  BankAccountList,
  BankAccountListResponse,
  BankCardCreate,
  BankCardCreateResponse,
  BankCardDelete,
  BankCardDeleteResponse,
  BankCardList,
  BankCardListResponse,
  BranchLocationCreate,
  BranchLocationDeleteResponse,
  BranchLocationFindAddress,
  BranchLocationFindAddressResponse,
  BranchLocationListResponse,
  BusinessBranchLocationDeleteItem,
  BusinessDetailByIdListResponse,
  BusinessDetailByIdShow,
  BusinessDetailCreate,
  BusinessDetailCreateResponse,
  BusinessDetailList,
  BusinessDetailListResponse,
  BusinessDetailUpdate,
  BusinessDetailUpdateResponse,
  BusinessDocumentDeleteItem,
  BusinessDocumentListResponse,
  BusinessDocumentShow,
  BusinessInPersonServiceByIdShowReq,
  BusinessInPersonServiceCreateReq,
  BusinessInPersonServiceDeleteItemReq,
  BusinessInPersonServiceGridShowReq,
  BusinessInPersonServicesByIdShowResponse,
  BusinessInPersonServicesCreateResponse,
  BusinessInPersonServicesDeleteItemResponse,
  BusinessInPersonServicesGridShowResponse,
  BusinessList,
  BusinessListResponse,
  BusinessLogoDeleteItem,
  BusinessLogoListResponse,
  BusinessLogoShow,
  BusinessProfileRequestModel,
  BusinessProfileResponseModel,
  BusinessSectorCreateRequest,
  BusinessSectorShowRequest,
  BusinessSectorShowResponse,
  BusinessSetupBusinessProfileByIdShowReq,
  BusinessSetupBusinessProfileByIdShowResponse,
  BusinessSetupBusinessProfileCreateOrUpdateReq,
  BusinessSetupBusinessProfileCreateOrUpdateResponse,
  BusinessSetupBusinessProfileUrlAvailabilityReq,
  BusinessSetupBusinessProfileUrlAvailabilityResponse,
  BusinessTeamMemberCreateUserResponse,
  BusinessTeamMemberDeleteUserResponse,
  BusinessTeamMemberUserShowResponse,
  BusinessUserCreate,
  BusinessUserDelete,
  BusinessUserShow,
  ConfigPaymentResponse,
  ConfirmPaymentIntentReq,
  ConfirmPaymentIntentResponse,
  CreatePaymentIntentReq,
  CreatePaymentIntentResponse,
  DescriptionGeneratorReq,
  DescriptionGeneratorResponse,
  JobCategoriesListResponse,
  JobTitleListResponse,
  JobTitleRequest,
  LoginUserInput,
  ProductListingItemsReq,
  ProductListingPublishByOfferCreateOrUpdateReq,
  ProductListingPublishByOfferCreateOrUpdateResponse,
  ProductListingPublishByOfferGroupCreateOrUpdateReq,
  ProductListingPublishByOfferGroupCreateOrUpdateResponse,
  ProductListingUnListReq,
  ProductListingUnListResponse,
  ProductManagementBrandListReq,
  ProductManagementCategoryListResponse,
  ProductManagementCategoryReq,
  ProductManagementImageDelete,
  ProductManagementImageList,
  ProductManagementImageListResponse,
  ProductManagementInventoryBranchListResponse,
  ProductManagementInventoryCreateOrUpdateReq,
  ProductManagementInventoryCreateOrUpdateResponse,
  ProductManagementInventoryDeleteReq,
  ProductManagementInventoryDeleteResponse,
  ProductManagementInventoryDetailsReq,
  ProductManagementInventoryDetailsResponse,
  ProductManagementInventoryGroupCreateOrUpdateReq,
  ProductManagementInventoryGroupCreateOrUpdateResponse,
  ProductManagementInventoryGroupDeleteReq,
  ProductManagementInventoryGroupDeleteResponse,
  ProductManagementInventoryGroupDetailsReq,
  ProductManagementInventoryGroupDetailsResponse,
  ProductManagementInventoryGroupListReq,
  ProductManagementInventoryGroupListResponse,
  ProductManagementInventoryListResponse,
  ProductManagementInventoryPackageDeliveryTypeResponse,
  ProductManagementInventoryProductConditionListResponse,
  ProductManagementListReq,
  ProductManagementListingResponse,
  ProductManagementMarketPlaceReq,
  ProductManagementOfferCreateOrUpdateReq,
  ProductManagementOfferCreateOrUpdateResponse,
  ProductManagementOfferDetailsReq,
  ProductManagementOfferDetailsResponse,
  ProductManagementOfferListReq,
  ProductManagementOfferListResponse,
  ProductManagementPolicyCreateResponse,
  ProductManagementPolicyDelete,
  ProductManagementPolicyDeleteResponse,
  ProductManagementPolicyList,
  ProductManagementPolicyListResponse,
  ProductManagementPolicyTextCreateReq,
  ProductManagementPolicyTextCreateResponse,
  ProductManagementPolicyTextDelete,
  ProductManagementPolicyTextGetByIdReq,
  ProductManagementPolicyTextGetByIdResponse,
  ProductManagementPolicyTypeList,
  ProductManagementPolicyTypeListResponse,
  ProductManagementVideoDelete,
  ProductManagementVideoList,
  ProductManagementVideoListResponse,
  RegisterUserInput,
  RetrievePaymentIntentReq,
  RetrievePaymentIntentResponse,
  SectorShowListResponse,
  ServiceTypeForBusinessListResponse,
  UserMEInput,
  VerifyEmailInput,
  VerifyEmailResponse,
} from "../../types";
import { API_ENDPOINTS } from "./api-endpoints";
import { HttpClient } from "./http-client";

//@ts-ignore
// import { OTPVerifyResponse } from '@/types';
// import { PasswordChangeResponse } from '../../types/index';

class Client {
  users = {
    // me: () => HttpClient.get<User>(API_ENDPOINTS.USERS_ME),
    me_v2: (input: UserMEInput) =>
      HttpClient.post<any>(API_ENDPOINTS.USERS_ME, input),
    verifyEmail: (input: VerifyEmailInput) =>
      HttpClient.patch<VerifyEmailResponse>(
        API_ENDPOINTS.VERIFY_OTP_CODE,
        input
      ),
    // update: (user: UpdateUserInput) =>
    //   HttpClient.put<User>(`${API_ENDPOINTS.USERS}/${user.id}`, user),
    login: (input: LoginUserInput) =>
      HttpClient.post<any>(API_ENDPOINTS.USERS_LOGIN, input),
    // socialLogin: (input: SocialLoginInputType) =>
    //   HttpClient.post<AuthResponse>(API_ENDPOINTS.SOCIAL_LOGIN, input),
    // sendOtpCode: (input: SendOtpCodeInputType) =>
    //   HttpClient.post<OTPResponse>(API_ENDPOINTS.SEND_OTP_CODE, input),
    // verifyOtpCode: (input: VerifyOtpInputType) =>
    //   HttpClient.post<OTPVerifyResponse>(API_ENDPOINTS.VERIFY_OTP_CODE, input),
    // OtpLogin: (input: OtpLoginInputType) =>
    //   HttpClient.post<AuthResponse>(API_ENDPOINTS.OTP_LOGIN, input),
    register: (input: RegisterUserInput) =>
      HttpClient.post<AuthResponse>(API_ENDPOINTS.USERS_REGISTER, input),
    // forgotPassword: (input: ForgotPasswordUserInput) =>
    //   HttpClient.post<PasswordChangeResponse>(
    //     API_ENDPOINTS.USERS_FORGOT_PASSWORD,
    //     input
    //   ),
    // verifyForgotPasswordToken: (input: VerifyForgotPasswordUserInput) =>
    //   HttpClient.post<PasswordChangeResponse>(
    //     API_ENDPOINTS.USERS_VERIFY_FORGOT_PASSWORD_TOKEN,
    //     input
    //   ),
    // resetPassword: (input: ResetPasswordUserInput) =>
    //   HttpClient.post<PasswordChangeResponse>(
    //     API_ENDPOINTS.USERS_RESET_PASSWORD,
    //     input
    //   ),
    // changePassword: (input: ChangePasswordUserInput) =>
    //   HttpClient.post<PasswordChangeResponse>(
    //     API_ENDPOINTS.USERS_CHANGE_PASSWORD,
    //     input
    //   ),
    // updateEmail: (input: UpdateEmailUserInput) =>
    //   HttpClient.post<EmailChangeResponse>(
    //     API_ENDPOINTS.USERS_UPDATE_EMAIL,
    //     input
    //   ),
    // logout: () => HttpClient.post<boolean>(API_ENDPOINTS.USERS_LOGOUT, {}),
    // deleteAddress: ({ id }: { id: string }) =>
    //   HttpClient.delete<boolean>(`${API_ENDPOINTS.USERS_ADDRESS}/${id}`),
    // subscribe: (input: { email: string }) =>
    //   HttpClient.post<any>(API_ENDPOINTS.USERS_SUBSCRIBE_TO_NEWSLETTER, input),
    // contactUs: (input: CreateContactUsInput) =>
    //   HttpClient.post<any>(API_ENDPOINTS.USERS_CONTACT_US, input),
    // resendVerificationEmail: () => {
    //   return HttpClient.post<VerificationEmailUserInput>(
    //     API_ENDPOINTS.SEND_VERIFICATION_EMAIL,
    //     {}
    //   );
    // },
  };
  business = {
    getBusinessProfileByWebUrl: (input: BusinessProfileRequestModel) =>
      HttpClient.post<BusinessProfileResponseModel>(
        API_ENDPOINTS.BUSINESS_PROFILE_BY_WEB_URL,
        input
      ),
    getDetailList: (input: BusinessList) =>
      HttpClient.post<BusinessListResponse>(
        API_ENDPOINTS.BUSINESS_DETAIL_LIST,
        input
      ),
    getUserDetailList: (input: BusinessDetailList) =>
      HttpClient.post<BusinessDetailListResponse>(
        API_ENDPOINTS.BUSINESS_DETAIL_LIMITED_SHOW,
        input
      ),
    getUserDetailByIdShow: (input: BusinessDetailByIdShow) =>
      HttpClient.post<BusinessDetailByIdListResponse>(
        API_ENDPOINTS.BUSINESS_DETAIL_BY_ID_SHOW,
        input
      ),
    detailCreate: (input: BusinessDetailCreate) =>
      HttpClient.post<BusinessDetailCreateResponse>(
        API_ENDPOINTS.BUSINESS_DETAIL_DETAIL_CREATE,
        input
      ),
    detailUpdate: (input: BusinessDetailUpdate) =>
      HttpClient.patch<BusinessDetailUpdateResponse>(
        API_ENDPOINTS.BUSINESS_DETAIL_DETAIL_UPDATE,
        input
      ),

    getProductManagementPolicyList: (input: ProductManagementPolicyList) => {
      return HttpClient.post<ProductManagementPolicyListResponse>(
        `${API_ENDPOINTS.PRODUCT_MANAGEMENT_POLICY_LIST}?page=${input.page}&items_per_page=${input.size}`,
        {
          businessdetail_id: input.businessdetail_id,
          file_name: input.policyName,
          policy_type: input.policyType,
        }
      );
    },
    getProductManagementPolicyTypeList: (
      input: ProductManagementPolicyTypeList
    ) =>
      HttpClient.post<ProductManagementPolicyTypeListResponse>(
        API_ENDPOINTS.PRODUCT_MANAGEMENT_POLICY_TYPE_LIST,
        input
      ),
    createProductManagementPolicy: (input: any) =>
      HttpClient.post<ProductManagementPolicyCreateResponse>(
        API_ENDPOINTS.PRODUCT_MANAGEMENT_POLICY_CREATE,
        input,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),
    createProductManagementPolicyText: (
      input: ProductManagementPolicyTextCreateReq
    ) =>
      HttpClient.post<ProductManagementPolicyTextCreateResponse>(
        API_ENDPOINTS.PRODUCT_MANAGEMENT_POLICY_TEXT_CREATE,
        input
      ),
    getProductManagementPolicyTextById: (
      input: ProductManagementPolicyTextGetByIdReq
    ) =>
      HttpClient.post<ProductManagementPolicyTextGetByIdResponse>(
        API_ENDPOINTS.PRODUCT_MANAGEMENT_POLICY_TEXT_GET_BY_ID,
        input
      ),

    deleteProductManagementPolicy: (input: ProductManagementPolicyTextDelete) =>
      HttpClient.post<ProductManagementPolicyDeleteResponse>(
        API_ENDPOINTS.PRODUCT_MANAGEMENT_POLICY_DELETE,
        input
      ),

    userShow: (input: BusinessUserShow) =>
      HttpClient.post<BusinessTeamMemberUserShowResponse>(
        API_ENDPOINTS.BUSINESS_USER_SHOW,
        input
      ),
    userCreate: (input: BusinessUserCreate) =>
      HttpClient.post<BusinessTeamMemberCreateUserResponse>(
        API_ENDPOINTS.BUSINESS_DETAIL_USER_CREATE,
        input
      ),
    userDelete: (input: BusinessUserDelete) =>
      HttpClient.post<BusinessTeamMemberDeleteUserResponse>(
        API_ENDPOINTS.BUSINESS_DETAIL_USER_DELETE,
        input
      ),

    branchLocationFindAddress: (input: BranchLocationFindAddress) =>
      HttpClient.post<BranchLocationFindAddressResponse>(
        API_ENDPOINTS.BUSINESS_BRANCH_LOCATION_FIND_ADDRESS,
        input
      ),
    branchLocationCreate: (input: BranchLocationCreate) =>
      HttpClient.post<any>(
        API_ENDPOINTS.BUSINESS_BRANCH_LOCATION_CREATE,
        input
      ),
    branchLocationList: (input: BusinessUserShow) =>
      HttpClient.post<BranchLocationListResponse>(
        API_ENDPOINTS.BUSINESS_BRANCH_LOCATION_LIST,
        input
      ),
    branchLocationDeleteItem: (input: BusinessBranchLocationDeleteItem) =>
      HttpClient.post<BranchLocationDeleteResponse>(
        API_ENDPOINTS.BUSINESS_BRANCH_LOCATION_DELETE_ITEM,
        input
      ),

    documentCreate: (input: any) =>
      HttpClient.post<any>(API_ENDPOINTS.BUSINESS_DOCUMENT_CREATE, input, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    documentList: (input: BusinessDocumentShow) =>
      HttpClient.post<BusinessDocumentListResponse>(
        API_ENDPOINTS.BUSINESS_DOCUMENT_LIST,
        input
      ),
    documentDelete: (input: BusinessDocumentDeleteItem) =>
      HttpClient.post<any>(API_ENDPOINTS.BUSINESS_DOCUMENT_DELETE, input),

    logoCreate: (input: any) =>
      HttpClient.post<any>(API_ENDPOINTS.LOGO_DOCUMENT_CREATE, input, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    logoList: (input: BusinessLogoShow) =>
      HttpClient.post<BusinessLogoListResponse>(
        API_ENDPOINTS.LOGO_DOCUMENT_LIST,
        input
      ),
    logoDelete: (input: BusinessLogoDeleteItem) =>
      HttpClient.post<any>(API_ENDPOINTS.LOGO_DOCUMENT_DELETE, input),

    BusinessTypeForBusiness: () =>
      HttpClient.post<ServiceTypeForBusinessListResponse>(
        API_ENDPOINTS.BUSINESS_SERVICE_TYPE_FOR_BUSINESS,
        {}
      ),
    sectorShow: () =>
      HttpClient.post<SectorShowListResponse>(
        API_ENDPOINTS.BUSINESS_SECTOR_SHOW,
        {}
      ),
    jobCategories: () =>
      HttpClient.post<JobCategoriesListResponse>(
        API_ENDPOINTS.BUSINESS_JOB_CATEGORIES,
        {}
      ),
    jobTitle: (input: JobTitleRequest) =>
      HttpClient.post<JobTitleListResponse>(
        API_ENDPOINTS.BUSINESS_JOB_TITLE,
        input
      ),
    businessSectorShow: (input: BusinessSectorShowRequest) =>
      HttpClient.post<BusinessSectorShowResponse>(
        API_ENDPOINTS.BUSINESS_BUSINESS_SECTOR_SHOW,
        input
      ),
    businessSectorCreate: (input: BusinessSectorCreateRequest) =>
      HttpClient.post<any>(
        API_ENDPOINTS.BUSINESS_BUSINESS_SECTOR_CREATE,
        input
      ),

    productCondition: () =>
      HttpClient.post<ProductManagementInventoryProductConditionListResponse>(
        `${API_ENDPOINTS.PRODUCT_MANAGEMENT_INVENTORY_PRODUCT_CONDITION_LIST}`,
        {}
      ),
    deliveryTypeList: () =>
      HttpClient.post<ProductManagementInventoryPackageDeliveryTypeResponse>(
        API_ENDPOINTS.PACKAGE_TYPE_OF_DELIVERY_LIST,
        {}
      ),

    marketplaceShow: (input: ProductManagementMarketPlaceReq) =>
      HttpClient.post<any>(
        API_ENDPOINTS.PRODUCT_MANAGEMENT_MARKET_PLACE_LIST,
        input
      ),

    category: (input: ProductManagementCategoryReq) =>
      HttpClient.post<ProductManagementCategoryListResponse>(
        API_ENDPOINTS.PRODUCT_MANAGEMENT_CATEGORY_LIST,
        input
      ),

    businessProfileByIdShow: (input: BusinessSetupBusinessProfileByIdShowReq) =>
      HttpClient.post<BusinessSetupBusinessProfileByIdShowResponse>(
        API_ENDPOINTS.BUSINESS_PROFILE_BY_ID_SHOW,
        input
      ),

    businessProfileCreateOrUpdate: (
      input: BusinessSetupBusinessProfileCreateOrUpdateReq
    ) =>
      HttpClient.post<BusinessSetupBusinessProfileCreateOrUpdateResponse>(
        API_ENDPOINTS.BUSINESS_PROFILE_CREATE_OR_UPDATE,
        input
      ),

    profileUrlAvailability: (
      input: BusinessSetupBusinessProfileUrlAvailabilityReq
    ) =>
      HttpClient.post<BusinessSetupBusinessProfileUrlAvailabilityResponse>(
        API_ENDPOINTS.PRODUCT_MANAGEMENT_URL_AVAILABILITY,
        input
      ),

    businessInPersonServiceCreate: (input: BusinessInPersonServiceCreateReq) =>
      HttpClient.post<BusinessInPersonServicesCreateResponse>(
        API_ENDPOINTS.BUSINESS_IN_PERSON_SERVICES_CREATE,
        input
      ),
    businessInPersonServiceGridShow: (
      input: BusinessInPersonServiceGridShowReq
    ) =>
      HttpClient.post<BusinessInPersonServicesGridShowResponse>(
        API_ENDPOINTS.BUSINESS_IN_PERSON_GRID_SHOW,
        input
      ),
    businessInPersonServiceDeleteItem: (
      input: BusinessInPersonServiceDeleteItemReq
    ) =>
      HttpClient.post<BusinessInPersonServicesDeleteItemResponse>(
        API_ENDPOINTS.BUSINESS_IN_PERSON_DELETE_ITEM,
        input
      ),
    businessInPersonServiceByIdShow: (
      input: BusinessInPersonServiceByIdShowReq
    ) =>
      HttpClient.post<BusinessInPersonServicesByIdShowResponse>(
        API_ENDPOINTS.BUSINESS_IN_PERSON_BY_ID_SHOW,
        input
      ),
  };
  businessFinance = {
    bankCardCreate: (input: BankCardCreate) =>
      HttpClient.post<BankCardCreateResponse>(
        API_ENDPOINTS.BUSINESS_FINANCE_BANK_CARD_CREATE,
        input
      ),
    bankCardList: (input: BankCardList) =>
      HttpClient.post<BankCardListResponse>(
        API_ENDPOINTS.BUSINESS_FINANCE_BANK_CARD_LIST,
        input
      ),
    bankCardDelete: (input: BankCardDelete) =>
      HttpClient.post<BankCardDeleteResponse>(
        API_ENDPOINTS.BUSINESS_FINANCE_BANK_CARD_DELETE,
        input
      ),

    bankAccountCreate: (input: BankAccountCreate) =>
      HttpClient.post<BankAccountCreateResponse>(
        API_ENDPOINTS.BUSINESS_FINANCE_BANK_ACCOUNT_CREATE,
        input
      ),
    bankAccountList: (input: BankAccountList) =>
      HttpClient.post<BankAccountListResponse>(
        API_ENDPOINTS.BUSINESS_FINANCE_BANK_ACCOUNT_LIST,
        input
      ),
    bankAccountDelete: (input: BankAccountDelete) =>
      HttpClient.post<BankAccountDeleteResponse>(
        API_ENDPOINTS.BUSINESS_FINANCE_BANK_ACCOUNT_DELETE,
        input
      ),

    createPaymentIntent: (input: CreatePaymentIntentReq) =>
      HttpClient.post<CreatePaymentIntentResponse>(
        API_ENDPOINTS.BUSINESS_FINANCE_CREATE_PAYMENT_INTENT,
        input
      ),
    confirmPaymentIntent: (input: ConfirmPaymentIntentReq) =>
      HttpClient.post<ConfirmPaymentIntentResponse>(
        API_ENDPOINTS.BUSINESS_FINANCE_CONFIRM_PAYMENT_INTENT,
        input
      ),
    retrievePaymentIntent: (input: RetrievePaymentIntentReq) =>
      HttpClient.post<RetrievePaymentIntentResponse>(
        API_ENDPOINTS.BUSINESS_FINANCE_RETRIEVE_PAYMENT_INTENT,
        input
      ),
    paymentConfig: () =>
      HttpClient.post<ConfigPaymentResponse>(
        API_ENDPOINTS.BUSINESS_FINANCE_PAYMENT_CONFIG,
        {}
      ),
  };
  sector_1 = {
    uploadImage: (input: any) =>
      HttpClient.post<any>(
        API_ENDPOINTS.PRODUCT_MANAGEMENT_IMAGE_UPLOAD,
        input,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),
    imageList: (input: ProductManagementImageList) => {
      let inputReq: any = {
        businessdetail_id: input.businessdetail_id,
      };
      if (input.product_image_name) {
        inputReq["product_image_name"] = input.product_image_name;
      }
      return HttpClient.post<ProductManagementImageListResponse>(
        `${API_ENDPOINTS.PRODUCT_MANAGEMENT_IMAGE_LIST}?page=${input.page}&items_per_page=${input.size}`,
        inputReq
      );
    },
    imageDelete: (input: ProductManagementImageDelete) =>
      HttpClient.post<any>(
        API_ENDPOINTS.PRODUCT_MANAGEMENT_IMAGE_DELETE,
        input
      ),

    videoList: (input: ProductManagementVideoList) => {
      let inputReq: any = {
        businessdetail_id: input.businessdetail_id,
      };
      if (input.product_video_name) {
        inputReq["product_video_name"] = input.product_video_name;
      }
      return HttpClient.post<ProductManagementVideoListResponse>(
        `${API_ENDPOINTS.PRODUCT_MANAGEMENT_VIDEO_LIST}?page=${input.page}&items_per_page=${input.size}`,
        inputReq
      );
    },
    videoDelete: (input: ProductManagementVideoDelete) =>
      HttpClient.post<any>(
        API_ENDPOINTS.PRODUCT_MANAGEMENT_VIDEO_DELETE,
        input
      ),
    uploadVideo: (input: any) =>
      HttpClient.post<any>(
        API_ENDPOINTS.PRODUCT_MANAGEMENT_VIDEO_UPLOAD,
        input,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),

    brandList: (input: ProductManagementBrandListReq) => {
      return HttpClient.post<ProductManagementInventoryBranchListResponse>(
        `${API_ENDPOINTS.PRODUCT_MANAGEMENT_INVENTORY_BRAND_LIST}`,
        input
      );
    },
    inventoryList: (input: ProductManagementListReq) => {
      return HttpClient.post<ProductManagementInventoryListResponse>(
        `${API_ENDPOINTS.PRODUCT_MANAGEMENT_INVENTORY_LIST}?page=${input.page}&items_per_page=${input.size}`,
        input
      );
    },
    inventoryDelete: (input: ProductManagementInventoryDeleteReq) => {
      return HttpClient.post<ProductManagementInventoryDeleteResponse>(
        `${API_ENDPOINTS.PRODUCT_MANAGEMENT_INVENTORY_DELETE}`,
        input
      );
    },
    inventoryCreateOrUpdate: (
      input: ProductManagementInventoryCreateOrUpdateReq
    ) => {
      return HttpClient.post<ProductManagementInventoryCreateOrUpdateResponse>(
        `${API_ENDPOINTS.PRODUCT_MANAGEMENT_INVENTORY_CREATE_OR_UPDATE}`,
        input
      );
    },
    inventoryDetails: (input: ProductManagementInventoryDetailsReq) => {
      return HttpClient.post<ProductManagementInventoryDetailsResponse>(
        `${API_ENDPOINTS.PRODUCT_MANAGEMENT_INVENTORY_DETAILS}`,
        input
      );
    },
    offerGridShow: (input: ProductManagementOfferListReq) => {
      return HttpClient.post<ProductManagementOfferListResponse>(
        `${API_ENDPOINTS.PRODUCT_MANAGEMENT_OFFER_GRID_LIST}?page=${input.page}&items_per_page=${input.size}`,
        input
      );
    },
    offerCreateOrUpdate: (input: ProductManagementOfferCreateOrUpdateReq) => {
      return HttpClient.post<ProductManagementOfferCreateOrUpdateResponse>(
        `${API_ENDPOINTS.PRODUCT_MANAGEMENT_OFFER_CREATE_OR_UPDATE}`,
        input
      );
    },
    offerDetails: (input: ProductManagementOfferDetailsReq) => {
      return HttpClient.post<ProductManagementOfferDetailsResponse>(
        `${API_ENDPOINTS.PRODUCT_MANAGEMENT_OFFER_DETAILS}`,
        input
      );
    },
    listingGridShow: (input: ProductListingItemsReq) => {
      return HttpClient.post<ProductManagementListingResponse>(
        `${API_ENDPOINTS.PRODUCT_MANAGEMENT_LISTING_GRID_LIST}?page=${input.page}&items_per_page=${input.size}`,
        input
      );
    },

    publishByOfferCreateOrUpdate: (
      input: ProductListingPublishByOfferCreateOrUpdateReq
    ) => {
      return HttpClient.post<ProductListingPublishByOfferCreateOrUpdateResponse>(
        `${API_ENDPOINTS.PRODUCT_LISTING_PUBLISH_BY_OFFER_CREATE_OR_UPDATE}`,
        input
      );
    },

    productUnList: (input: ProductListingUnListReq) => {
      return HttpClient.post<ProductListingUnListResponse>(
        `${API_ENDPOINTS.PRODUCT_LISTING_UN_LIST}`,
        input
      );
    },

    inventoryGroupList: (input: ProductManagementInventoryGroupListReq) => {
      return HttpClient.post<ProductManagementInventoryGroupListResponse>(
        `${API_ENDPOINTS.PRODUCT_MANAGEMENT_INVENTORY_GROUP_LIST}?page=${input.page}&items_per_page=${input.size}`,
        input
      );
    },
    inventoryGroupDelete: (input: ProductManagementInventoryGroupDeleteReq) => {
      return HttpClient.post<ProductManagementInventoryGroupDeleteResponse>(
        `${API_ENDPOINTS.PRODUCT_MANAGEMENT_INVENTORY_GROUP_DELETE}`,
        input
      );
    },
    inventoryGroupCreateOrUpdate: (
      input: ProductManagementInventoryGroupCreateOrUpdateReq
    ) => {
      return HttpClient.post<ProductManagementInventoryGroupCreateOrUpdateResponse>(
        `${API_ENDPOINTS.PRODUCT_MANAGEMENT_INVENTORY_GROUP_CREATE_OR_UPDATE}`,
        input
      );
    },
    inventoryGroupDetails: (
      input: ProductManagementInventoryGroupDetailsReq
    ) => {
      return HttpClient.post<ProductManagementInventoryGroupDetailsResponse>(
        `${API_ENDPOINTS.PRODUCT_MANAGEMENT_INVENTORY_GROUP_DETAILS}`,
        input
      );
    },

    publishByOfferGroupCreateOrUpdate: (
      input: ProductListingPublishByOfferGroupCreateOrUpdateReq
    ) => {
      return HttpClient.post<ProductListingPublishByOfferGroupCreateOrUpdateResponse>(
        `${API_ENDPOINTS.PRODUCT_LISTING_PUBLISH_BY_OFFER_GROUP_CREATE_OR_UPDATE}`,
        input
      );
    },
  };
  analysis = {
    DescriptionGenerator: (input: DescriptionGeneratorReq) => {
      return HttpClient.post<DescriptionGeneratorResponse>(
        `${API_ENDPOINTS.AI_ANALYSIS_DESCRIPTION_GENERATOR}`,
        input
      );
    },
  };
}

export default new Client();
