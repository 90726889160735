import React, { InputHTMLAttributes } from "react";

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    label?: string;
    name: string;
    description?: string;
    error?: string;
}

const Checkbox = React.forwardRef<HTMLInputElement, Props>(
    ({ className, label, name, description, error, ...rest }, ref) => {
        return (
            <div className={className}>
                <div className="flex items-center">
                    <input
                        id={name}
                        name={name}
                        type="checkbox"
                        ref={ref}
                        className="pb-checkbox"
                        {...rest}
                    />

                    <label htmlFor={name} className="text-sm text-body">
                        {label}
                    </label>
                </div>

                {description && (
                    <label
                        htmlFor={name}
                        className="text-sm text-body"
                        style={{
                            fontSize: "0.8rem",
                            paddingLeft: "35px",
                            maxLines: 2,
                            width: "40%",
                        }}
                    >
                        {description}
                    </label>
                )}

                {error && (
                    <p
                        className="my-2 text-xs text-red-500 text-end"
                        style={{
                            textAlign: "left",
                        }}
                    >
                        {error}
                    </p>
                )}
            </div>
        );
    }
);

Checkbox.displayName = "Checkbox";

export default Checkbox;
