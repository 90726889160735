import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import Description from "../ui/description";
import Card from "../ui/cards/card";
import { useTranslation } from "react-i18next";
import { DescriptionGeneratorReq, DescriptionGeneratorResponse } from "../../types";
import Button from "../ui/button";
import { userStore } from "../../store";
import TextArea from "../ui/text-area";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useAiAnalysisDescriptionGenerator } from "../../service/hook/analysis";
import Label from "../ui/label";

type FormValues = {
    input: string;
};

const defaultValues = {
    input: "",
};

const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
        async (data: any) => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors: any) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors: any, currentError: any) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

export default function DescriptionGeneratorForm() {
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation();
    const { user, businessItem, setRegisteredBusinessId } = userStore((state) => state);

    const [aiResponse, setAiResponse] = useState<string>("");

    const {
        mutate: mutateDescripitonGenerator,
        isLoading: isLoadingDescripitonGenerator,
        serverError: serverErrorDescripitonGenerator,
        setServerError: setServerErrorDescripitonGenerator,
    } = useAiAnalysisDescriptionGenerator();

    // const resolver = useYupValidationResolver(inventoryAddValidationSchema);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        reset,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm<FormValues>({
        // shouldUnregister: true,
        //@ts-ignore
        defaultValues: defaultValues,
        // resolver,
    });

    const onSubmit = async (values: FormValues) => {
        try {
            console.log("values => ", values);

            const input: DescriptionGeneratorReq = {
                email: user?.email ?? "",
                businessdetail_id: `${businessItem?.business_id ?? 0}`,
                input: values.input,
            };

            mutateDescripitonGenerator(input, {
                onSuccess: (successData: DescriptionGeneratorResponse) => {
                    console.log("successData mutateDescripitonGenerator => ", successData);

                    if (successData.final_result_code === "000") {
                        toast.success(successData.general_result);
                        setAiResponse(successData.captured_data);
                    } else {
                        toast.error(successData.general_result);
                    }
                },
                onError: (error: Error) => {
                    const {
                        response: { data },
                    }: any = error ?? {};

                    toast.error(data?.general_result);
                },
            });
        } catch (error) {
            console.log("error => ", error);
        }
    };

    console.log("aiResponse => ", aiResponse);

    const responseTemp = ` **Product Name:** Blue 2XL Men's Shirt

    **Description:**
    
    * Color: Blue
    * Size: 2XL
    * Gender: Men's
    * Material: 100% cotton
    * Style: Button-down shirt
    * Features: Two chest pockets, long sleeves, button-down collar
    
    **Benefits:**
    
    * Comfortable and breathable fabric
    * Classic and versatile style
    * Perfect for casual or dressy occasions
    * Machine-washable and easy to care for
    
    **Call to Action:**
    
    Order your Blue 2XL Men's Shirt today and enjoy the perfect combination of comfort, style, and versatility!`;

    return (
        <>
            <form id={"inventory-add-form"} onSubmit={handleSubmit(onSubmit)} className="">
                <div className="my-5 flex flex-wrap sm:my-8">
                    <Description
                        title={"Enter product details"}
                        details={[
                            "Leverage the power of our Al-based tailored model description service, designed to elevate your products and services. Simply provide us with your initial definations or keywords, and watch as our platform generates creative and customized product descriptions tailored to your unique offering.",
                        ]}
                        className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5 "
                    />
                    <Card className="w-full sm:w-8/12 md:w-2/3 flex-col space-y-4">
                        <TextArea
                            label={"Input / Prompt"}
                            {...register("input")}
                            error={t(errors.input?.message!)}
                            variant="outline"
                            placeholder="Input / Prompt"
                            className="mb-5"
                            maxLength={250}
                        />

                        {aiResponse && (
                            <>
                                <Label>{"Response:"}</Label>
                                <br />
                                <br />
                                {aiResponse.split("\n").map(function (item, idx) {
                                    return (
                                        <span key={idx}>
                                            {item}
                                            <br />
                                        </span>
                                    );
                                })}
                            </>
                        )}

                        <div className="mb-2 text-center space-x-4 mt-8">
                            <Button
                                form={"inventory-add-form"}
                                loading={isLoadingDescripitonGenerator}
                                disabled={isLoadingDescripitonGenerator}
                            >
                                {"Generate"}
                            </Button>
                        </div>
                    </Card>
                </div>
            </form>
        </>
    );
}
