import React from "react";
export const CoinPoundIcon = ({ width = "64px", height = "64px" }) => {
    return (
        // <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 48">
        //     <g data-name="Group 2993" transform="translate(-608 -807)">
        //         <path
        //             data-name="Path 3646"
        //             d="M20,0h8A20,20,0,0,1,48,20v8A20,20,0,0,1,28,48H20A20,20,0,0,1,0,28V20A20,20,0,0,1,20,0Z"
        //             transform="translate(608 807)"
        //             fill="#ffe8b2"
        //         />
        //         <g
        //             data-name="Group 2984"
        //             transform="translate(-7 -7)"
        //             width={"28px"}
        //             height={"28px"}
        //         >
        //             <g id="SVGRepo_iconCarrier">
        //                 {/* <circle cx="32" cy="32" r="25.29" /> */}

        //                 <path
        //                     d="M41.72,45.18H23.3a.14.14,0,0,1-.11-.23c1.26-1.35,7.28-8.25,5-14.46-2.57-6.92-.91-17.91,11.52-12.8"
        //                     stroke-linecap="round"
        //                 />

        //                 <line x1="20.39" y1="30.67" x2="37.58" y2="30.67" stroke-linecap="round" />
        //             </g>
        //         </g>
        //     </g>
        // </svg>
        <svg
            width="64px"
            height="64px"
            viewBox="-11.52 -11.52 87.04 87.04"
            xmlns="http://www.w3.org/2000/svg"
            stroke-width="1.8559999999999999"
            stroke="#a29471"
            fill="none"
        >
            <g id="SVGRepo_bgCarrier" stroke-width="0" />

            <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke="#ffe8b2"
                stroke-width="0.128"
            />

            <g id="SVGRepo_iconCarrier">
                <circle cx="32" cy="32" r="25.29" />

                <path
                    d="M41.72,45.18H23.3a.14.14,0,0,1-.11-.23c1.26-1.35,7.28-8.25,5-14.46-2.57-6.92-.91-17.91,11.52-12.8"
                    stroke-linecap="round"
                />

                <line x1="20.39" y1="30.67" x2="37.58" y2="30.67" stroke-linecap="round" />
            </g>
        </svg>
    );
};
